export const checkCommaPosForCluster = (str: string, count: number) => {
  if (count > 9999) {
    const commaPos = str.indexOf(',');
    return commaPos !== -1 ? str.substring(0, commaPos) : str;
  }

  return str.substring(0, 3);
};

export const processBigNumber = (count: number, checkCommaPosFn: (str: string, count: number) => string) => {
  let labelStr = count.toString();
  const labelLength = labelStr.length;
  labelStr = labelStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const labelSubstr = labelStr.substr(0, 4);

  // Substr up to 3 digits + k/M/B if needed
  if (labelLength < 4) {
    return count;
  }

  if (labelLength > 3 && labelLength <= 6) {
    return checkCommaPosFn(labelSubstr, count) + 'k';
  }

  if (labelLength > 6 && labelLength <= 9) {
    return checkCommaPosFn(labelSubstr, count) + 'M';
  }

  if (labelLength > 9 && labelLength <= 12) {
    return checkCommaPosFn(labelSubstr, count) + 'B';
  }
  return count;
};
