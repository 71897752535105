import { AsyncStatus } from '@va/dashboard/util-hooks';
import { ButtonLoader, Checkmark, LineGraphIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { hasTrackingCodeError } from '@va/standalone/shared/selectors';
import { Button, IconWithBackground } from '@va/ui/design-system';
import { isMobileDevice } from '@va/util/helpers';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useTrackingCodeStatusCard = ({
  status,
  handleCodeTrackerClick,
  onSuccess,
}: {
  status: AsyncStatus;
  handleCodeTrackerClick: () => void;
  onSuccess?: () => void;
}) => {
  const translate = useTranslate();

  const isLoading = useMemo(() => status === AsyncStatus.IN_PROGRESS, [status]);
  const isMobile = useSelector(isMobileDevice);
  const trackingCodeError = useSelector(hasTrackingCodeError);

  const icon = useMemo(() => {
    if (status === AsyncStatus.INITIAL && !trackingCodeError)
      return <IconWithBackground className='!bg-positive' icon={() => <Checkmark color={'#fff'} />} />;
    if (status === AsyncStatus.ERROR)
      return <IconWithBackground className='!bg-negative' icon={() => <LineGraphIcon color={'#fff'} />} />;
    if (status === AsyncStatus.SUCCESS)
      return <IconWithBackground className='!bg-positive' icon={() => <Checkmark color={'#fff'} />} />;
    return <IconWithBackground className='!bg-gray-charcoal' icon={() => <LineGraphIcon color={'#fff'} />} />;
  }, [status, trackingCodeError]);

  const title = useMemo(() => {
    if (status === AsyncStatus.INITIAL && !trackingCodeError)
      return translate('trackingCode.installation.successInstalled');
    if (status === AsyncStatus.ERROR) return translate('trackingCode.installation.installError');
    if (status === AsyncStatus.SUCCESS) return translate('trackingCode.installation.successInstalled');
    return translate('trackingCode.installation.unInstalled');
  }, [status, trackingCodeError, translate]);

  const actionButton = useMemo(() => {
    if (status === AsyncStatus.ERROR)
      return (
        <Button
          disabled={isLoading}
          icon={() => (isLoading ? <ButtonLoader color={'var(--color-primary)'} /> : undefined)}
          onClick={handleCodeTrackerClick}
          text={isMobile ? translate('button.retry') : translate('trackingCode.installation.retryVerification')}
          color='quinary'
        />
      );
    if (status === AsyncStatus.SUCCESS && onSuccess)
      return (
        <Button
          onClick={() => {
            onSuccess();
          }}
          text={translate('button.continue')}
          color='primary'
        />
      );
    return (
      <Button
        disabled={isLoading}
        icon={() => (isLoading ? <ButtonLoader color={'var(--color-primary)'} /> : undefined)}
        onClick={handleCodeTrackerClick}
        text={translate('trackingCode.installation.verifyInstallation')}
        color='quinary'
      />
    );
  }, [handleCodeTrackerClick, isLoading, isMobile, onSuccess, status, translate]);

  return { icon, title, actionButton };
};
