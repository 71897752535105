import { NotesIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { IconWithBackground, ToggleActionCard } from '@va/ui/design-system';
import { useCallback } from 'react';
import { NewsletterSettingsFormFields, useNewsletterSettings } from './useNewsletterSettings';

export const NewsletterToggle = () => {
  const translate = useTranslate();
  const { isNLSubscribed, values, onSubscriptionChange, isSubscriptionLoading, handleSubmit } = useNewsletterSettings();

  const onChange = useCallback(
    async (value: any) => {
      await onSubscriptionChange(value);
      handleSubmit();
    },
    [handleSubmit, onSubscriptionChange],
  );

  if (isNLSubscribed || isSubscriptionLoading) return null;

  return (
    <ToggleActionCard
      title={translate('onboarding.newsletterStep.newsletter')}
      name={NewsletterSettingsFormFields.newsletter}
      value={values.newsletter}
      handleChange={onChange}
      icon={() => <IconWithBackground className='bg-white' icon={() => <NotesIcon color='#696969' />} />}
    />
  );
};
