import { useLocalizationContext } from '@va/localization';
import { fontWeights, Paragraph, paragraphSizes } from '@va/ui/design-system';
import { addNumberSeparator } from '@va/util/helpers';
import classNames from 'classnames';

type PricingPlanTileProps = {
  selected?: boolean;
  viewsAmount: number;
  price: number;
  title: string;
  recommended?: boolean;
  onClick?: () => void;
  className?: string;
};

export const PricingPlanTile = ({
  selected,
  viewsAmount,
  title,
  price,
  recommended,
  onClick,
  className,
}: PricingPlanTileProps) => {
  const { locale, translate } = useLocalizationContext();

  return (
    <div
      className={classNames(
        'px-18px py-3 rounded-18 flex justify-between items-center cursor-pointer overflow-hidden',
        {
          'bg-gray-wild-sand border-gray-wild-sand border-3': !selected,
          'border-primary border-3': selected,
        },
        className,
      )}
      onClick={onClick}
    >
      <div className='overflow-hidden'>
        {recommended && (
          <Paragraph
            colorClassName={classNames({ 'text-primary': selected, 'text-gray-dustySolid': !selected })}
            size={paragraphSizes.tiny}
            weight={fontWeights.medium}
          >
            {translate('modals.freeTrialEnding.pricingPlanTile.recommended')}
          </Paragraph>
        )}
        <Paragraph
          className='truncate'
          colorClassName={classNames({ 'text-primary': selected })}
          size={paragraphSizes.large}
          weight={fontWeights.semibold}
        >
          {title}
        </Paragraph>
        <Paragraph className='text-wrap' size={paragraphSizes.tiny} weight={fontWeights.medium}>
          {translate('modals.freeTrialEnding.pricingPlanTile.viewsIncluded', { count: viewsAmount })}
        </Paragraph>
      </div>
      <div className='text-right'>
        <Paragraph
          colorClassName={classNames({ 'text-primary': selected })}
          weight={fontWeights.semibold}
          className='text-3xl leading-36'
        >
          ${addNumberSeparator(price, locale, { maximumFractionDigits: 2 })}
        </Paragraph>
        <Paragraph
          colorClassName={classNames({ 'text-gray-dustySolid': !selected })}
          size={paragraphSizes.extraTiny}
          weight={fontWeights.medium}
        >
          {translate('modals.freeTrialEnding.exclVat')}
        </Paragraph>
      </div>
    </div>
  );
};
