import { Website } from '@visitor-analytics/3as-sdk';
import { SupportedCurrency } from '@va/constants';

export type StpCounterResponse = {
  id: string;
  intpId: string;
  packageId: string;
  subscriptionId: string;
  websiteId: string;
  stpLimit: number;
  stpConsumed: number;
  stpRateVisit: number;
  stpConsumedVisit: number;
  stpRateVisitEvent: number;
  stpConsumedVisitEvent: number;
  stpRateSessionRecording: number;
  stpConsumedSessionRecording: number;
  stpRateHeatmapIncrement: number;
  stpConsumedHeatmapIncrement: number;
  stpRatePollAnswer: number;
  stpConsumedPollAnswer: number;
  stpRateSurveyAnswer: number;
  stpConsumedSurveyAnswer: number;
  stpConsumedFunnelMatch: number;
  stpRateFunnelMatch: number;
  stpRateEcomEvent: number;
  stpConsumedEcomEvent: number;
};

export enum FeatureName {
  ip2company = 'FEATURE_IP_2_COMPANY',
  customReports = 'FEATURE_CUSTOM_REPORTS',
  visitorSegments = 'FEATURE_VISITOR_SEGMENTS',
  customDashboards = 'FEATURE_CUSTOM_DASHBOARDS',
  countryBasedPrivacy = 'FEATURE_COUNTRY_BASED_PRIVACY_MODE',
  fullDataAccess = 'FEATURE_FULL_DATA_ACCESS',
}

export type AaasFeaturePermission = {
  feature: FeatureName;
  minStps: number;
  enabled: boolean;
};

export type IntpUpgradeMessage = Pick<
  Website,
  'intpWebsiteId' | 'packageName' | 'packageId' | 'intpCustomerId' | 'inTrial' | 'expiresAt' | 'billingInterval'
>;

export type AaasPackage = {
  id: string;
  createdAt: string;
  excludedFeatures: Array<unknown>;
  externalId: string;
  intpId: string;
  name: string;
  period: 'monthly' | 'yearly';
  price: number;
  recommended: boolean;
  touchpoints: number;
  currency: SupportedCurrency;
};

export type GroupedPackages = {
  monthly: AaasPackage[];
  yearly: AaasPackage[];
};
