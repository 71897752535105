import { PaginationState, RowSelectionState, SortingState } from '@tanstack/react-table';
import { Moment } from 'moment';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { Filter, Where } from '../filters';
import { EventTypeEnum } from '../recordings';
import { Direction, Pagination } from '../table';

export enum AlarmingEventTrigger {
  'rageClicks' = 'rageClicks',
  'deadClicks' = 'deadClicks',
  'scrolls' = 'scrolls',
  'mouseMoves' = 'mouseMoves',
  'uTurns' = 'uTurns',
  'refreshes' = 'refreshes',
}
export type AlarmingEvents = {
  [key in AlarmingEventTrigger]?: number;
};

export type Events = Pick<EventsData, 'clickInteractions' | 'moveInteractions' | 'scrollInteractions'>;

export type EventsData = {
  clickInteractions: number;
  events: number;
  incSnapshots: number;
  moveInteractions: number;
  scrollInteractions: number;
};

export type AlarmingBehavior = {
  trigger: AlarmingEventTrigger;
  count: number;
  previousCount: number;
  changeInPercentage: number;
  noOfReferrers: number;
  percentOfTotal: number;
  firstAppearance: number;
  lastAppearance: number;
  color: string;
  page: string;
  pageTitle: string;
  referrers: ABEReferrer[];
  tooltipValue: string;
};

export type ABEReferrer = {
  referrer: string;
  referredSessions: number;
};

export type AlarmingBehaviorResponse = {
  payload: AlarmingBehavior[];
  meta: {
    page: number;
    pageSize: number;
    pageTotal: number;
    total: number;
  };
};

export type Group = 'page' | 'trigger';

export type Order = {
  member: string;
  direction: Direction;
};

export const alarmingEventsMap = {
  rageClicks: 'rageClicks',
  deadClicks: 'deadClicks',
  mouseMoves: 'mouseMoves',
  scrolls: 'scrolls',
  refreshes: 'refreshes',
  uTurns: 'uTurns',
};

export const regularEventsMap = {
  clickInteractions: 'clickInteractions',
  moveInteractions: 'moveInteractions',
  scrollInteractions: 'scrollInteractions',
};

export const alarmingEventsTranslations = {
  [alarmingEventsMap.rageClicks]: 'all.eventTracking.alarmingBehaviorEvents.triggers.rageClicks',
  [alarmingEventsMap.deadClicks]: 'all.eventTracking.alarmingBehaviorEvents.triggers.deadClicks',
  [alarmingEventsMap.mouseMoves]: 'all.eventTracking.alarmingBehaviorEvents.triggers.mouseMoves',
  [alarmingEventsMap.scrolls]: 'all.eventTracking.alarmingBehaviorEvents.triggers.scrolls',
  [alarmingEventsMap.refreshes]: 'all.eventTracking.alarmingBehaviorEvents.triggers.refreshes',
  [alarmingEventsMap.uTurns]: 'all.eventTracking.alarmingBehaviorEvents.triggers.uTurns',
};

export const regularEventsTranslations = {
  [regularEventsMap.clickInteractions]: 'sessionRecordings.visitorsActions.events.clickInteractions',
  [regularEventsMap.moveInteractions]: 'sessionRecordings.visitorsActions.events.moveInteractions',
  [regularEventsMap.scrollInteractions]: 'sessionRecordings.visitorsActions.events.scrollInteractions',
};

export type UseAlarmingBehaviorEventsParams = {
  pagination: Pagination;
  order?: Order[];
  group?: Group;
  initialFilters?: Filter[];
};

export type AlarmingBehaviorEventsContextParams = {
  data: AlarmingBehaviorResponse | undefined;
  isLoading: boolean;
  pagination: PaginationState;
  paginationId: string;
  sorting: SortingState;
  rowSelection: RowSelectionState;
  setRowSelection: Dispatch<SetStateAction<RowSelectionState>>;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  setSorting: Dispatch<SetStateAction<SortingState>>;
  selectedAlarmingBehaviorEvents: AlarmingBehavior[];
  toggleABESelection: (behavior: AlarmingBehavior) => void;
  selectedMetric: string;
  selectMetric: (metric: string) => void;
  groupingKey: Group;
};

export type ActionButtonDetails = {
  id: string;
  tooltip: string;
  icon: ReactNode;
  path: string;
  onClick?: () => void;
};

export type MappedAlarmingEvent = {
  trigger: AlarmingEventTrigger;
  count: number;
};

export type MappedAlarmingEvents = {
  totalCount: number;
  events: MappedAlarmingEvent[];
};

type Mapper<T> = (response: AlarmingBehaviorResponse) => T;

type RequestBody = {
  pagination: Pagination;
  where: Where;
};

type CurrentPeriod = {
  from: string | Moment;
  until: string | Moment;
  unit: string;
};

export type AlarmingBehaviorEventsGraphFetchParams = {
  websiteId: string;
  query: {
    type?: string;
  } & CurrentPeriod;
  body?: RequestBody;
};

export type AlarmingBehaviorEventsFetchParams = {
  url: string;
  queryData: {
    order?: Order;
    group?: Group;
  } & CurrentPeriod;
  body?: RequestBody;
};

export type UseAlarmingBehaviorEventsFetchParams<T> = {
  query: {
    order?: Order | string;
    group: string;
  } & Pagination;
  body?: {
    where: Where;
  };
  mapper: Mapper<T>;
};

export type EventListItem = {
  eventType: EventTypeEnum | AlarmingEventTrigger;
  count: number;
  icon?: React.ReactNode;
  translationKey: string;
};

export type UsabilityScoreGraphFetchParams = {
  websiteId: string;
  query: {
    type?: string;
  } & CurrentPeriod;
  body?: RequestBody;
};

export type UseAlarmingBehaviorEventsGraphFetchParams = {
  websiteId: string;
} & CurrentPeriod;

export type UseUsabilityScoreFetchParams = {
  websiteId: string;
} & CurrentPeriod;

export type UseAlarmingSessionsGraphFetchParams = {
  websiteId: string;
} & CurrentPeriod;
