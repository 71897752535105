import { IconProps } from '.';

export const UpwardTrendIcon = ({ className, color = 'var(--color-positive)' }: IconProps) => {
  return (
    <svg className={className} width='9' height='11' viewBox='0 0 9 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.50012 5.00003V11H3.50012V5.00003L-0.202881 4.99603L4.00012 0.79303L8.20612 4.99903L4.50012 5.00003Z'
        fill={color}
      />
    </svg>
  );
};
