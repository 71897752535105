import { PlanIndexes } from '@va/constants';
import { getAccountInformation } from '@va/dashboard/selectors/api';
import { SubscriptionTypeEnum } from '@va/standalone/shared/constants';
import { createSelector } from 'reselect';

export const getAccountLocale = createSelector([getAccountInformation], (accountInformation) => {
  return accountInformation?.locale as string | undefined;
});

export const getUpgradePlanIndex = (state: any) => state.getIn(['app', 'upgradePlanIndex']) as PlanIndexes | undefined;

export const getUpgradePlanSubscriptionType = (state: any) =>
  state.getIn(['app', 'upgradePlanSubscriptionType']) as SubscriptionTypeEnum | undefined;
