import dayjs from 'dayjs';

export const getPreviousDate = (date) => {
  const display = [];
  for (let i = 0; i <= date.date(0).day(); i++) {
    display.push(date.date(0).subtract(i, 'day'));
  }
  return display.sort((a, b) => a.date() - b.date());
};

export const getCurrentDate = (date) => {
  return Array.from(
    {
      length: date.daysInMonth(),
    },
    (v, k) => date.date(k + 1),
  );
};

export const getNextDate = (date) => {
  const display = [];
  for (let i = 1; i <= 42 - (getPreviousDate(date).length + date.daysInMonth()); i++) {
    display.push(date.date(i).month(date.month()).add(1, 'month'));
  }
  return display;
};

export const getDisableDate = (date, disableDate) => {
  if (typeof disableDate === 'function') {
    return disableDate(date.toDate());
  } else {
    return false;
  }
};

export const getBetweenRange = (date, { previous, next }) => {
  let pattern;
  if (previous.isAfter(next, 'date')) {
    pattern = '(]';
  } else {
    pattern = '[)';
  }
  return !!(date.isBetween(previous, next, 'date', pattern) && !date.off);
};

export const getToValueFromString = (date, { formatter }) => {
  return date.format(formatter.date);
};

export const getToValueFromArray = ({ previous, next }, { formatter, separator }) => {
  let start = previous;
  let end = next;
  if (next.isBefore(previous)) {
    start = next;
    end = previous;
  }

  return `${start.tz(window.timezone, true).format(formatter.date)}${separator}${end
    .tz(window.timezone, true)
    .format(formatter.date)}`;
};

export const getDirective = (binding) => {
  const { instance, arg, value } = binding;
  document.body.addEventListener('click', ($event) => {
    if ($event.target.classList.contains('litepie-datepicker-overlay')) {
      return (instance.isShow = false);
    } else {
      if (instance.LitepieDatepickerRef) {
        const { autoApply, previous, next } = instance;
        const target = $event.target.classList.contains('litepie-datepicker-date');
        if (target && autoApply && !previous && !next) {
          return (instance.isShow = false);
        }
        if (!autoApply && $event.target.classList.contains(`${arg}-apply-picker`) && instance.value !== '') {
          return (instance.isShow = false);
        }
        if ($event.target.classList.contains(`${arg}-cancel-picker`)) {
          return (instance.isShow = false);
        }
        if ($event.target.classList.contains(`litepie-shortcuts`) && autoApply) {
          return (instance.isShow = false);
        }

        return (instance.isShow =
          instance.LitepieDatepickerRef.contains($event.target) ||
          document.getElementById(value) === $event.target ||
          value === $event.target);
      }
      return (instance.isShow = true);
    }
  });
};

export const getVisibleViewport = (el) => {
  const { right } = el.getBoundingClientRect();
  const vWidth = window.innerWidth || document.documentElement.clientWidth;

  return right < vWidth;
};

export const isArray = (modelValue) => {
  return Array.isArray(modelValue);
};

export const isObject = (modelValue) => {
  return typeof modelValue === 'object';
};

const formatDate = (newDate) => {
  let month = newDate.getMonth() + 1;
  let date = newDate.getDate();
  let year = newDate.getFullYear();

  return `${year}-${month}-${date}`;
};

export const getFormatedDate = (date) => {
  return dayjs(formatDate(new Date(date)));
};

export const getDateRange = (key, date, minDate) => {
  const data = {
    today: { start: dayjs(), end: dayjs() },
    yesterday: { start: dayjs().subtract(1, 'day'), end: dayjs().subtract(1, 'day') },
    last7Days: { start: dayjs().subtract(date - 1, 'day'), end: dayjs() },
    last30Days: { start: dayjs().subtract(date - 1, 'day'), end: dayjs() },
    thisMonth: { start: dayjs().date(1), end: dayjs() },
    last40Days: { start: dayjs().subtract(date - 1, 'day'), end: dayjs() },
    lastMonth: {
      start: dayjs().date(1).subtract(1, 'month'),
      end: dayjs().date(0),
    },
    prev6Month: {
      start: dayjs().subtract(date, 'month').add(1, 'day'),
      end: dayjs(),
    },
    prev12Month: {
      start: dayjs().subtract(date, 'month').add(1, 'day'),
      end: dayjs(),
    },
    thisYear: {
      start: dayjs().year(dayjs().year()).month(0).date(1),
      end: dayjs(),
    },
    lastYear: {
      start: dayjs()
        .year(dayjs().year() - 1)
        .month(0)
        .date(1),
      end: dayjs()
        .year(dayjs().year() - 1)
        .month(11)
        .date(31),
    },
    allTime: {
      start: dayjs(minDate),
      end: dayjs(),
    },
  };

  const { start, end } = data[key];
  return { start: start.tz(window.timezone, true).startOf('day'), end: end.tz(window.timezone, true).endOf('day') };
};

export const defaultShortcuts = [
  { key: 'today', transKey: 'datePicker.period.today' },
  { key: 'yesterday', transKey: 'datePicker.period.yesterday' },
  { key: 'last7Days', transKey: 'datePicker.period.lastDays', diff: 7 },
  { key: 'last30Days', transKey: 'datePicker.period.lastDays', diff: 30, isDefault: true },
  { key: 'thisMonth', transKey: 'datePicker.period.thisMonth' },
  { key: 'lastMonth', transKey: 'datePicker.period.lastMonth' },
  { key: 'prev6Month', transKey: 'datePicker.period.prevMonths', diff: 6 },
  { key: 'prev12Month', transKey: 'datePicker.period.prevMonths', diff: 12 },
  { key: 'thisYear', transKey: 'datePicker.period.thisYear' },
  { key: 'lastYear', transKey: 'datePicker.period.lastYear' },
  { key: 'allTime', transKey: 'datePicker.period.allTime' },
];

export const freePlanHistoryShortcut = { key: 'last40Days', transKey: 'datePicker.period.lastDays', diff: 40 };

export const sortDates = (dateArray) => {
  const [started, ended] = dateArray;
  let s = started?.isAfter(ended) ? ended : started;
  let e = started?.isAfter(ended) ? started : ended;
  return [s?.startOf('day'), e?.endOf('day')];
};
