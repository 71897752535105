import AnnouncementModal from './AnnouncementModal';
import FeatureLimitReached from './FeatureLimitReached';
import { FreeTrialEndingModal } from './FreeTrialEndingModal';
import ManualScreenshotContainer from './ManualScreenshot';
import SignDPA from './SignDPA';
import SsrReachedLimit from './SsrReachedLimit';
import SsrTechnicalLimitation from './SsrTechnicalLimitation/SsrTechnicalLimitation';
import TabInformation from './TabInformation';
import VideoOverlay from './VideoOverlay';
import VisitsLimitReached from './VisitsLimitReached';

const Modals = {
  AnnouncementModal,
  SignDPA,
  SsrReachedLimit,
  SsrTechnicalLimitation,
  VisitsLimitReached,
  VideoOverlay,
  FeatureLimitReached,
  TabInformation,
  ManualScreenshotContainer,
  FreeTrialEndingModal,
};

export default Modals;
