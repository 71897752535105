import { fontWeights, Heading5, Paragraph } from '@va/ui/design-system';
import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';
import { OnboardingIconContainer } from './OnboardingIconContainer';

type OnboardingStepProps = {
  title?: string;
  className?: string;
  titleClassName?: string;
  subtitle?: string;
  icon?: ReactNode;
  footer?: ReactNode;
};

export const OnboardingStep = ({
  icon,
  subtitle,
  className,
  title,
  titleClassName,
  footer,
  children,
}: PropsWithChildren<OnboardingStepProps>) => {
  return (
    <div className={classNames('mt-36px max-w-600px mx-auto px-18px', className)}>
      {icon && <OnboardingIconContainer className='mx-auto mb-6'>{icon}</OnboardingIconContainer>}
      {(title || subtitle) && (
        <div className='mb-6'>
          <Heading5
            className={classNames('text-center', titleClassName)}
            colorClassName='text-black-lighter-84'
            weight={fontWeights.semibold}
          >
            {title}
          </Heading5>
          <Paragraph className='text-center' colorClassName='text-black-lighter-72' weight={fontWeights.medium}>
            {subtitle}
          </Paragraph>
        </div>
      )}
      {children}
      {footer && <div className='w-full mt-18px'>{footer}</div>}
    </div>
  );
};
