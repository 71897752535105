import { markModalAsViewed, setUserFlag } from '@va/dashboard/actions/api';
import { AnnouncementBannerContent, getAnnouncementBanner } from '@va/dashboard/api-client/dashboard';
import { getAccountInformation } from '@va/dashboard/selectors/api';
import { getWebsite } from '@va/dashboard/selectors/core';
import { useLocale } from '@va/localization';
import { hasUserRole } from '@va/standalone/shared/selectors';
import { CloseIconWithBorder } from '@va/svg-visa-icons';
import { UserRole } from '@va/types/website';
import { changeColorContrast, getParameterByName, isWixApp } from '@va/util/helpers';
import { DashboardAppWorker } from '@va/util/misc';
import { Link } from '@va/util/router';
import parse, { DOMNode, HTMLReactParserOptions, domToReact } from 'html-react-parser';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './AnnouncementBanner.scss';

const DEFAULT_COLOR = '#333';

const useShouldShowBanner = () => {
  const isOwner = useSelector(hasUserRole)(UserRole.OWNER);
  const queryParam = getParameterByName('inapp_banner');

  if (queryParam) return true;
  if (isWixApp()) return true;
  if (isOwner) return true;

  return false;
};

type AnnouncementBannerProps = {
  markForWebsite?: boolean;
};

const AnnouncementBanner = ({ markForWebsite }: AnnouncementBannerProps) => {
  const [announcement, setAnnouncement] = useState<AnnouncementBannerContent | null>(null);
  const [bgColor, setBackgroundColor] = useState('');
  const [view, setView] = useState(false);
  const [previewId, setPreviewId] = useState<string | null>(null);
  const { locale } = useLocale();
  const website = useSelector(getWebsite);
  const accountInformation = useSelector(getAccountInformation);
  const showBanner = useShouldShowBanner();
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParam = getParameterByName('inapp_banner');
    if (queryParam) {
      setPreviewId(queryParam);
    }
  }, []);

  const fetchAnnouncement = useCallback(async () => {
    if (!showBanner) return;
    const { payload: response } = await getAnnouncementBanner(website.id, locale);
    if (response) {
      const announcementData: AnnouncementBannerContent = response.info[locale] || response.info['en'];

      announcementData ? setAnnouncement(announcementData) : setAnnouncement(null);
    }
  }, [locale, showBanner, website.id]);

  useEffect(() => {
    fetchAnnouncement();
  }, [fetchAnnouncement]);

  useEffect(() => {
    if (!announcement) return;

    if (previewId && announcement.id === previewId) {
      setView(true);
      setBackgroundColor(announcement.bg_color);
      return;
    }

    if (announcement) {
      const show = markForWebsite
        ? !(website.frontendFlags && website.frontendFlags[announcement.id.toString()])
        : !(accountInformation.frontendFlags && accountInformation.frontendFlags[announcement.id.toString()]);
      setView(!!show);
      setBackgroundColor(announcement.bg_color);
    }
  }, [accountInformation.frontendFlags, announcement, markForWebsite, previewId, website.frontendFlags]);

  const reset = useCallback(() => {
    setView(false);
    setAnnouncement(null);
    setPreviewId(null);
  }, []);

  const onClose = useCallback(
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation();
      if (!announcement) return;

      if (previewId) {
        reset();
        return;
      }

      if (markForWebsite) {
        // Update website's flags for apps without user
        dispatch(markModalAsViewed(announcement.id.toString()));
      } else {
        // Update user frontend flags
        dispatch(setUserFlag(announcement.id.toString(), true));
      }
      reset();
    },
    [announcement, dispatch, markForWebsite, previewId, reset],
  );

  const getAnnouncementDestination = useCallback((): string | undefined => {
    if (!announcement) return undefined;
    const anchorTag = document.getElementById(announcement.id)?.getElementsByTagName('a');
    if (!anchorTag || anchorTag.length === 0) {
      return undefined;
    }

    return anchorTag[0].href;
  }, [announcement]);

  const onBannerClick = () => {
    const href = getAnnouncementDestination();
    if (!announcement) return;

    DashboardAppWorker.trackEvent({
      category: 'Inapp Announcement Banner',
      action: 'onclick',
      label: announcement.text_plain,
      value: announcement.id,
      fieldsObject: `destination:${href}`,
    });

    if (!href) return;

    window.open(href, '_blank');
  };

  const htmlParseOptions: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode.type === 'tag' && domNode.name === 'internal-link') {
        return <Link to={domNode.attribs.to}>{domToReact(domNode.children as DOMNode[])}</Link>;
      }
    },
  };

  return (
    view &&
    announcement && (
      <button
        className={`banner-wrapper p-1.5 rounded-2xl text-15 m-2 flex items-center border-transparent border-2 focus:border-blue-300 order-first z-[var(--zIndex-layout)]`}
        onMouseEnter={() => {
          const href = getAnnouncementDestination();
          if (!announcement) return;

          setBackgroundColor(changeColorContrast(announcement.bg_color, -20));

          DashboardAppWorker.trackEvent({
            category: 'Inapp Announcement Banner',
            action: 'onmouseover',
            label: announcement.text_plain,
            value: announcement.id,
            fieldsObject: `destination:${href}`,
          });
        }}
        onMouseLeave={() => setBackgroundColor(announcement.bg_color)}
        onMouseDown={() => setBackgroundColor(changeColorContrast(announcement.bg_color, -40))}
        onMouseUp={() => setBackgroundColor(changeColorContrast(announcement.bg_color, -20))}
        style={{ background: bgColor, color: DEFAULT_COLOR }}
        onClick={onBannerClick}
      >
        <img className='ml-2 h-6 w-6' src={announcement.icon} alt='icon' />
        <span id={announcement.id} className='mx-3 leading-normal text-left '>
          {parse(announcement.text_html, htmlParseOptions)}
        </span>
        <span className='bg-white hover:bg-transparent p-3 rounded-xl ml-auto cursor-pointer' onClick={onClose}>
          <CloseIconWithBorder color={DEFAULT_COLOR} className='h-6 w-6' radius={10} />
        </span>
      </button>
    )
  );
};
export default AnnouncementBanner;
