import { domainRegex, validationTranslationKeys } from '@va/constants';
import { useTranslate } from '@va/localization';
import { TextInput } from '@va/ui/design-system';
import { useFormik } from 'formik';
import * as yup from 'yup';

export const AddWebsiteForm: React.FC<{
  onSubmit: (values: { url: string }) => void;
  formId?: string;
  initialValues?: { url: string };
}> = ({ formId, onSubmit, initialValues = { url: '' } }) => {
  const translate = useTranslate();

  const { handleChange, values, errors, handleSubmit, setFieldValue } = useFormik({
    onSubmit: (values) => {
      onSubmit({ ...values });
    },
    initialValues,
    validationSchema: getValidationSchema(translate),
  });

  return (
    <form id={formId} className='max-w-600px mx-auto' onSubmit={handleSubmit}>
      <TextInput
        name='url'
        label={translate('onboarding.labels.websiteUrl')}
        value={values.url}
        clearField={() => {
          setFieldValue('url', '');
        }}
        error={errors.url}
        onChange={handleChange}
      />
    </form>
  );
};

function getValidationSchema(translate: Function) {
  return yup.object().shape({
    url: yup
      .string()
      .required(translate(validationTranslationKeys.required))
      .matches(domainRegex, translate(validationTranslationKeys.websiteUrl)),
  });
}
