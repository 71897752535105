import * as React from "react";
const SvgConsentCookieBanner = (props) => /* @__PURE__ */ React.createElement("svg", { width: 180, height: 120, viewBox: "0 0 180 120", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { width: 180, height: 120, rx: 12, fill: "white" }), /* @__PURE__ */ React.createElement("rect", { x: 60, y: 9, width: 60, height: 6, rx: 3, fill: "black", fillOpacity: 0.06, style: {
  mixBlendMode: "plus-darker"
} }), /* @__PURE__ */ React.createElement("rect", { x: 50, y: 21, width: 80, height: 6, rx: 3, fill: "black", fillOpacity: 0.06, style: {
  mixBlendMode: "plus-darker"
} }), /* @__PURE__ */ React.createElement("rect", { x: 75, y: 33, width: 30, height: 6, rx: 3, fill: "black", fillOpacity: 0.06, style: {
  mixBlendMode: "plus-darker"
} }), /* @__PURE__ */ React.createElement("path", { d: "M6 57C6 52.8579 9.35786 49.5 13.5 49.5H166.5C170.642 49.5 174 52.8579 174 57V72C174 76.1421 170.642 79.5 166.5 79.5H13.5C9.35787 79.5 6 76.1421 6 72V57Z", fill: "black", fillOpacity: 0.06 }), /* @__PURE__ */ React.createElement("rect", { x: 50, y: 61.5, width: 80, height: 6, rx: 3, fill: "black", fillOpacity: 0.06, style: {
  mixBlendMode: "plus-darker"
} }), /* @__PURE__ */ React.createElement("rect", { x: 6, y: 84, width: 168, height: 30, rx: 7.5, fill: "black", fillOpacity: 0.06 }), /* @__PURE__ */ React.createElement("rect", { x: 50, y: 96, width: 80, height: 6, rx: 3, fill: "black", fillOpacity: 0.06, style: {
  mixBlendMode: "plus-darker"
} }));
export default SvgConsentCookieBanner;
