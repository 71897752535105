import { FilterCondition } from '../filter-templates';

export type StoredFilter = {
  member: string;
  operator: FilterOperators;
  values: any[];
};

export type DefaultFilterOptionType<T = string | number> = {
  value: T;
  label: string;
  icon?: React.ReactNode;
  allOption?: boolean;
  disabled?: boolean;
  logicalOperator?: FilterLogicalOperators;
};

export enum FilterTypes {
  SingleSelect = 'SingleSelect',
  MultiSelect = 'MultiSelect',
  Text = 'Text',
  Boolean = 'Boolean',
  Comparison = 'Comparison',
  Interval = 'Interval',
  KeyValue = 'KeyValue',
  Funnel = 'Funnel',
}

export type FilterOptions = Array<NestedFiltersPage | Filter>;

export enum FilterOperators {
  is = 'is',
  isNot = 'isNot',
  contains = 'contains',
  notContains = 'notContains',
  doesNotContain = 'doesNotContain',
  startsWith = 'startsWith',
  endsWith = 'endsWith',
  isSet = 'isSet',
  isNotSet = 'isNotSet',

  between = 'between',
  notBetween = 'notBetween',
  notEqual = 'notEqual',
  equal = 'equal',
  greaterThan = 'greaterThan',
  greaterThanOrEqual = 'greaterThanOrEqual',
  lowerThan = 'lowerThan',
  lowerThanOrEqual = 'lowerThanOrEqual',
}

export enum FilterLogicalOperators {
  and = 'and',
  or = 'or',
}

export enum ApiOperators {
  // Numerical values only
  eq = 'eq',
  gt = 'gt',
  gte = 'gte',
  lt = 'lt',
  lte = 'lte',
  // Strings only
  in = 'in',
  notIn = 'not in',
}

export type Where = {
  and: FilterCondition[];
};

export type FilterInputComponentType = React.ForwardRefExoticComponent<any>;

export type AppliedFilterInputComponentType = React.FC<any>;

export abstract class Filter<V = any, D = Record<string, unknown>> {
  id: string;
  label: string;
  name: string;
  operator: FilterOperators;
  appliedLabel?: string;
  input: FilterInputComponentType;
  appliedInput: AppliedFilterInputComponentType;
  operatorOptions?: FilterOperators[];
  multipleValuesAllowed: boolean;
  valueMapper?: (value: V, operator: FilterOperators) => any;
  operatorMapper?: (operator: FilterOperators) => ApiOperators;
  apiFieldNameMapper?: (values: any[], operator: FilterOperators) => string;
  values: V[];
  inputProps?: D;
  getApiFieldName: (values: V[], operator: FilterOperators) => string;
  getApiOperator: (operator: FilterOperators) => ApiOperators;
  getValueMapper: (value: V, operator: FilterOperators) => any[];
  allowMultiSelect?: boolean;
  viewOnly?: boolean;
  logicalOperator?: FilterLogicalOperators;
  parentId?: string;
  type: FilterTypes;

  constructor({
    id,
    label,
    name,
    operator,
    appliedLabel,
    input,
    appliedInput,
    operatorOptions,
    multipleValuesAllowed = false,
    valueMapper,
    operatorMapper,
    apiFieldNameMapper,
    values,
    inputProps,
    allowMultiSelect,
    viewOnly = false,
    logicalOperator,
    parentId,
    type,
  }: {
    id: string;
    label: string;
    name: string;
    operator: FilterOperators;
    input: FilterInputComponentType;
    appliedLabel?: string;
    appliedInput: AppliedFilterInputComponentType;
    operatorOptions?: FilterOperators[];
    multipleValuesAllowed?: boolean;
    valueMapper?: (value: V, operator: FilterOperators) => any;
    operatorMapper?: (operator: FilterOperators) => ApiOperators;
    apiFieldNameMapper?: Filter['apiFieldNameMapper'];
    values?: V[];
    inputProps?: D;
    allowMultiSelect?: boolean;
    viewOnly?: boolean;
    logicalOperator?: FilterLogicalOperators;
    parentId?: string;
    type: FilterTypes;
  }) {
    this.id = id;
    this.label = label;
    this.name = name;
    this.operator = operator;
    this.appliedLabel = appliedLabel;
    this.input = input;
    this.appliedInput = appliedInput;
    this.operatorOptions = operatorOptions;
    this.multipleValuesAllowed = multipleValuesAllowed;
    this.valueMapper = valueMapper;
    this.operatorMapper = operatorMapper;
    this.apiFieldNameMapper = apiFieldNameMapper;
    this.values = values || [];
    this.inputProps = inputProps;
    this.allowMultiSelect = allowMultiSelect;
    this.viewOnly = viewOnly;
    this.logicalOperator = logicalOperator;
    this.parentId = parentId;
    this.type = type;

    this.getApiFieldName = (values: V[], operator: FilterOperators) => {
      return this.apiFieldNameMapper ? this.apiFieldNameMapper(values, operator) : this.name;
    };

    this.getApiOperator = (operator: FilterOperators) => {
      return this.operatorMapper ? this.operatorMapper(operator) : defaultOperatorMapper(operator);
    };

    this.getValueMapper = (value: V, operator: FilterOperators) => {
      return this.valueMapper ? this.valueMapper(value, operator) : value;
    };
  }
}

export type NestedFiltersPage = {
  label: string;
  id: string;
  name: string;
  filters: Array<NestedFiltersPage | Filter>;
  childrenLogicalOperator?: FilterLogicalOperators;
  parentId?: string;
};

export const buildNestedFiltersPage = (page: NestedFiltersPage) => {
  return {
    ...page,
    filters: page.filters.map((filter) => {
      if (isNestedFiltersPage(filter)) return filter;

      filter.logicalOperator = page.childrenLogicalOperator;
      filter.parentId = page.id;

      return filter;
    }),
  };
};

export function isNestedFiltersPage(item: NestedFiltersPage | Filter): item is NestedFiltersPage {
  return (item as NestedFiltersPage).filters !== undefined;
}

export function isFilter(item: NestedFiltersPage | Filter): item is Filter {
  return (item as Filter).id !== undefined;
}

export type useOptionsHook = () => {
  data?: DefaultFilterOptionType[];
  isLoading?: boolean;
  error?: Error;
};

export type CommonFilterParams<V, D> = {
  id: Filter['id'];
  label: Filter['label'];
  name: Filter['name'];
  operator?: Filter['operator'];
  operatorOptions?: Filter['operatorOptions'];
  appliedLabel?: Filter['appliedLabel'];
  valueMapper?: Filter['valueMapper'];
  operatorMapper?: Filter['operatorMapper'];
  apiFieldNameMapper?: Filter['apiFieldNameMapper'];
  values?: V[];
  inputProps?: D;
  viewOnly?: boolean;
};

export const defaultOperatorMapper = (operator: FilterOperators) => {
  switch (operator) {
    case FilterOperators.equal:
      return ApiOperators.eq;
    case FilterOperators.greaterThan:
      return ApiOperators.gt;
    case FilterOperators.greaterThanOrEqual:
      return ApiOperators.gte;
    case FilterOperators.lowerThan:
      return ApiOperators.lt;
    case FilterOperators.lowerThanOrEqual:
      return ApiOperators.lte;
    case FilterOperators.doesNotContain:
      return ApiOperators.notIn;
    case FilterOperators.is:
      return ApiOperators.in;
    case FilterOperators.isNot:
      return ApiOperators.notIn;
    default:
      return ApiOperators.in;
  }
};

export type FilterInputComponentRefType = {
  submit: () => void;
};
