import { getWebsite, isPremium } from '@va/dashboard/selectors/core';
import { SubscriptionTypeEnum } from '@va/standalone/shared/constants';
import { WixOnboardingStepsEnum } from '@va/wix/shared/constants';
import { createSelector } from 'reselect';

export const isMigrationEmailSent = (state: any) => state.getIn(['app', 'isMigrationEmailSent'], false);

export const isMonthlyPayer = createSelector(
  [(state: any) => state.getIn(['core', 'website', 'cycleType'], 0), isPremium],
  (cycleType, isPremium) => {
    /*
     cycleType: { 0, 1, 2, 3 }
     0 - unknown
     1 - free
     2 - monthly
     3 - yearly
     */
    return isPremium && cycleType === 2;
  },
);

export const getMetaSiteId = createSelector([getWebsite], (website) => {
  return website.metaSiteId;
});

export const getPremiumPlans = (state: any) => state.getIn(['app', 'premiumPlan']).toJS();

export const getInitialUpgradePlanId = (state: any) =>
  state.getIn(['app', 'initialUpgradePlanId'], undefined) as string | undefined;

export const getInitialUpgradePlanSubscriptionType = (state: any) =>
  state.getIn(['app', 'initialUpgradePlanSubscriptionType'], undefined) as SubscriptionTypeEnum | undefined;

export const getInitialOnboardingStep = (state: any) =>
  state.getIn(['app', 'initialOnboardingStep'], undefined) as WixOnboardingStepsEnum | undefined;
