import { FEATURE_TOUR_IDS } from '@va/constants';
import { CommunityButton } from '@va/dashboard/components';
import { getChatwootToken } from '@va/dashboard/selectors/customization';
import { getActiveTab, isMobileDevice, isTabletDevice } from '@va/dashboard/selectors/ui';
import { navigationActions } from '@va/dashboard/slices';
import { openChatwootWidget } from '@va/dashboard/util-helpers/chatwoot';
import { ChatIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { WebsiteSelectorV2 } from '@va/standalone/shared/feature-website-selector';
import { Button } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { debounce, isStandaloneApp } from '@va/util/helpers';
import classNames from 'classnames';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Sidebar.scss';
import SidebarHeader from './SidebarHeader';
import SidebarList from './SidebarList';
import { TrackingCodeButton } from './TrackingCodeButton';
import { getFilteredList, getTopLevelParent } from './helper';
import { SidebarItemProps, SidebarProps } from './propDefinitions';

export const Sidebar: FunctionComponent<SidebarProps> = ({
  sidebarItems = [],
  isSidebarExpand = false,
  onToggleSidebar,
  footer,
}) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const isTablet = useSelector(isTabletDevice);
  const isMobile = useSelector(isMobileDevice);
  const chatwootToken = useSelector(getChatwootToken);
  const activeTab = useSelector(getActiveTab);
  const [selectedItem, setSelectedItem] = useState<SidebarItemProps>();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [searchItem, setSearchItem] = useState<string>('');
  const [filteredItems, setFilteredItems] = useState<SidebarItemProps[]>([]);

  useEffect(() => {
    setFilteredItems(sidebarItems);
  }, [sidebarItems]);

  useEffect(() => {
    setIsExpanded(isSidebarExpand);
  }, [isSidebarExpand]);

  useEffect(() => {
    if (!activeTab) return;
    if (activeTab === selectedItem?.name) return;
    setSelectedItem({ name: getTopLevelParent(filteredItems, activeTab) ?? activeTab });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleSearch = useCallback(
    (searchStr: string) => {
      setSearchItem(searchStr);
      const handleSearchDelay = debounce(() => {
        const filteredItems = getFilteredList(sidebarItems, searchStr, translate);
        return setFilteredItems(filteredItems);
      }, 100);
      handleSearchDelay();
    },
    [sidebarItems, translate],
  );

  const handleClick = useCallback(
    (item: SidebarItemProps) => {
      if (item.submenu && item.submenu.length > 0 && selectedItem?.name !== item.name && !isExpanded) {
        setSelectedItem(item.submenu[0]);
      } else if (isExpanded || !item.submenu) {
        setSelectedItem(item);
        setTimeout(() => {
          dispatch(navigationActions.closeSidebar());
        }, 500);
      }
      if (isTablet) {
        onToggleSidebar && onToggleSidebar();
      }
    },
    [dispatch, isExpanded, isTablet, onToggleSidebar, selectedItem?.name],
  );

  const onToggle = useCallback(
    (newValue: boolean) => {
      onToggleSidebar && onToggleSidebar();
      setIsExpanded(newValue);
    },
    [onToggleSidebar],
  );

  useEffect(() => {
    return () => {
      dispatch(navigationActions.closeSidebar());
    };
  }, [dispatch]);

  return (
    <>
      <div
        data-tour={FEATURE_TOUR_IDS.sidebarContainer}
        className={classNames(
          'z-[var(--zIndex-sidebar)] top-[var(--top-bar-height)] transition-all duration-200 ease-in-out fixed bottom-0 left-0 right-0 lg:block lg:left-auto lg:right-auto lg:border-r lg:border-gray-mercury bg-secondary flex-col items-center justify-start scrollbar scrollbar-thumb ',
          {
            'lg:w-[72px] hidden': !isExpanded,
            'w-auto lg:w-[300px] !overflow-y-scroll': isExpanded,
          },
        )}
      >
        <div className='w-full flex items-center flex-col h-full'>
          {isStandaloneApp() && (
            <div className='lg:hidden w-full z-30 px-2 pt-2'>
              <WebsiteSelectorV2 />
            </div>
          )}
          <SidebarHeader
            isExpanded={isExpanded}
            searchItem={searchItem}
            handleSearch={handleSearch.bind(this)}
            onToggle={onToggle}
          />
          <div className={classNames('w-full px-18px', { 'px-6px': !isExpanded })}>
            <TrackingCodeButton className='mb-3' />
          </div>
          <SidebarList
            sidebarItems={filteredItems}
            isExpanded={isExpanded}
            selectedItem={selectedItem}
            handleClick={handleClick.bind(this)}
          />

          <div className='w-full pt-3 px-2 gap-2 flex flex-col'>
            {isTablet && <CommunityButton />}
            {isMobile && chatwootToken && (
              <Button
                onClick={openChatwootWidget}
                className='w-full justify-center'
                color='tertiary'
                text={translate('button.contactChatSupport')}
                icon={() => <ChatIcon className='shrink-0' color='#969696' />}
              />
            )}
          </div>

          {isExpanded && typeof footer === 'function' && <div className='w-full my-4 h-full px-2'>{footer()}</div>}
        </div>
      </div>
      {isExpanded && (
        <div
          onClick={() => {
            onToggleSidebar();
          }}
          className='z-[1002] w-screen h-screen bg-black-lighter-50 cursor-pointer fixed top-[var(--top-bar-height)] left-0'
        ></div>
      )}
    </>
  );
};

type SidebarPreviewProps = {
  sidebarItems: SidebarItemProps[];
  isExpanded: boolean;
  tooltipText: string;
  className?: string;
};

export function SidebarPreview({ sidebarItems, isExpanded, tooltipText, className }: SidebarPreviewProps) {
  return (
    <TooltipWrapper content={tooltipText} placement='right' followCursorAxis='y'>
      <div
        className={classNames(
          'z-[var(--zIndex-sidebar)] transition-all duration-200 ease-in-out fixed bottom-0 left-0 right-0 lg:block top-[70px] lg:left-auto lg:right-auto lg:border-r lg:border-gray-mercury bg-secondary flex-col items-center justify-start scrollbar scrollbar-thumb',
          {
            'lg:w-[72px] hidden': !isExpanded,
            'w-auto lg:w-[300px] overflow-y-scroll': isExpanded,
          },
          className,
        )}
      >
        <div className='w-full flex items-center flex-col pointer-events-none '>
          <SidebarHeader isExpanded={isExpanded} searchItem={''} handleSearch={() => {}} onToggle={() => {}} />
          <SidebarList sidebarItems={sidebarItems} isExpanded={isExpanded} handleClick={() => {}} previewMode />
        </div>
      </div>
    </TooltipWrapper>
  );
}
