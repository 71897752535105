export const T3_URL_PARAMS = {
  locale: 'locale',
  endDate: 'endDate',
  startDate: 'startDate',
  mainDashboardPageUrl: 'mainDashboardPageUrl',
  recordingsOverviewPageUrl: 'recordingsOverviewPageUrl',
  newFunnelUrl: 'newFunnelUrl',
  newHeatmapUrl: 'newHeatmapUrl',
  redirectUrl: 'redirectUrl',
};

export const WIX_MINI_URL_PARAMS = {
  predefinedTimePeriod: 'predefinedTimePeriod',
};
