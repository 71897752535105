import { supportLinks } from '@va/constants';
import { getSupportCenterDomain } from '@va/dashboard/selectors/customization';
import { ExternalLinkIcon, FolderIcon, NotesIcon, UserIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { AccordionSection, IconWithBackground, InfoSectionItem } from '@va/ui/design-system';
import classNames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export const AdditionalSupportResources = () => {
  const translate = useTranslate();
  const [isOpen, setIsOpen] = useState(true);
  const supportCenterDomain = useSelector(getSupportCenterDomain);

  return (
    <AccordionSection
      icon={<FolderIcon color='#969696' />}
      title={translate('onboarding.trackingCode.resources.title')}
      className='!rounded-12 !h-12 pr-3px cursor-pointer'
      wrapperClassName={classNames('!rounded-12 w-full mt-12 sm:mt-36px', { '!rounded-b-30': isOpen })}
      arrowButtonClassName='h-42px w-42px !bg-transparent'
      isOpenExternal={isOpen}
      toggleIsOpenExternal={() => setIsOpen(!isOpen)}
    >
      <div className='p-3 space-y-1.5 rounded-b-30'>
        <a
          className='block'
          href={translate('trackingCode.instructions.installSnippetGuideUrl', { supportCenterDomain })}
          target='_blank'
          rel='noreferrer'
        >
          <InfoSectionItem
            className='!bg-white rounded-18'
            label={translate('labels.guide')}
            title={translate('onboarding.trackingCode.resources.addToSourceCode')}
            icon={<IconWithBackground className='bg-white-snow' icon={() => <NotesIcon color='#696969' />} />}
            rightNode={<ExternalLinkIcon color='#c3c3c3' />}
          />
        </a>
        <a className='block' href={supportLinks.contact} target='_blank' rel='noreferrer'>
          <InfoSectionItem
            className='!bg-white rounded-18'
            label={translate('labels.requestHelp')}
            title={translate('trackingCode.instructions.contactOurSupport')}
            icon={<IconWithBackground className='bg-white-snow' icon={() => <UserIcon color='#696969' />} />}
            rightNode={<ExternalLinkIcon color='#c3c3c3' />}
          />
        </a>
      </div>
    </AccordionSection>
  );
};
