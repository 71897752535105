import * as Icons from '@va/icons';
import { capitalizeFirstLetter } from '@va/util/helpers';
import {
  overviewPremiumCardIds,
  packageId,
  subscriptionPlanType,
  WIX_ADVANCED_PACK_INCREASE_PERCENTAGE,
  WIX_YEARLY_PACK_PERCENTAGE_FROM_MONTHLY,
  WixPermissionPack,
} from '@va/wix/shared/constants';
import Config from 'Config';
import moment from 'moment';

export function getWixBillingPackage(websitePermissionPack: number) {
  switch (websitePermissionPack) {
    case WixPermissionPack.FREE:
      return 'Free';
    case WixPermissionPack.STARTER:
      return 'Starter';
    case WixPermissionPack.BASIC:
      return 'Basic';
    case WixPermissionPack.ADVANCED:
      return 'Advanced';
    case WixPermissionPack.PRO:
      return 'Pro';
    default:
      return '';
  }
}

export function getSubscriptionPlanTypeByPermissionPackId(permissionPackId: number) {
  switch (permissionPackId) {
    case WixPermissionPack.FREE:
      return subscriptionPlanType.FREE;
    case WixPermissionPack.BASIC:
      return subscriptionPlanType.BASIC;
    case WixPermissionPack.ADVANCED:
      return subscriptionPlanType.ADVANCED;
    case WixPermissionPack.PRO:
      return subscriptionPlanType.PRO;
    default:
      return subscriptionPlanType.BASIC;
  }
}

export function constructWixUpgradeUrl(packageId: string, instanceId: string, paymentCycleType: string) {
  const baseUpgradeUrl = 'https://premium.wix.com/wix/api/tpaStartPurchase?';
  const siteId = 'appInstanceId=' + instanceId;
  const appDefinitionId = '&appDefinitionId=' + Config.appId;
  const paymentCycle = '&paymentCycle=' + paymentCycleType;
  const vendorProductId = '&vendorProductId=' + packageId;

  return baseUpgradeUrl + siteId + appDefinitionId + paymentCycle + vendorProductId;
}

export function getUpgradeUrl(
  packageIdentifier = packageId.BASIC,
  instanceId: string,
  paymentCycleType: string,
  yearlyUpgradeUrl: string,
  monthlyUpgradeUrl = '',
) {
  if (isUpgradeUrlLoaded(monthlyUpgradeUrl, yearlyUpgradeUrl)) {
    return paymentCycleType === 'MONTHLY' ? monthlyUpgradeUrl : yearlyUpgradeUrl;
  }

  return constructWixUpgradeUrl(packageIdentifier, instanceId, paymentCycleType);
}

export function getManageWebsiteTrackingUrl(metaSiteId: string) {
  return `https://www.wix.com/dashboard/${metaSiteId}/manage-website/embeds/?referralInfo=sidebar`;
}

export function isUpgradeUrlLoaded(monthlyUpgradeUrl: string, yearlyUpgradeUrl: string) {
  return monthlyUpgradeUrl !== '#' && yearlyUpgradeUrl !== '#';
}

function isAtLeastMonthlyActive(latestAccesses: Array<number>) {
  let count = 0;
  let sum = 0;
  latestAccesses.push(Math.floor(Date.now() / 1000));

  for (let i = latestAccesses.length - 1; i > 0; i--) {
    const diff = latestAccesses[i] - latestAccesses[i - 1];
    count++;
    sum += diff;
  }

  const averageSec = sum / count;
  const averageDays = averageSec / (60 * 60 * 24);
  return Math.round(averageDays * 100) / 100 <= 30;
}

export function hasFullDiscountVoucherCode() {
  return Config && Config.voucherCode && Config.voucherCode[100] && Config.showVoucherCode;
}

export function getWixYearlyPricePerMonthFromMonthlyPrice(monthlyPrice: number) {
  return Math.round(monthlyPrice * WIX_YEARLY_PACK_PERCENTAGE_FROM_MONTHLY) / 100;
}

export function getWixHigherPackPrices(
  monthlyPrice: number,
  increasePercentage = WIX_ADVANCED_PACK_INCREASE_PERCENTAGE,
) {
  const higherPackMonthlyPrice = Math.round(monthlyPrice * increasePercentage) / 100;
  return {
    MONTHLY: higherPackMonthlyPrice,
    YEARLY: getWixYearlyPricePerMonthFromMonthlyPrice(higherPackMonthlyPrice),
  };
}

export function handleExpiredRefreshToken() {
  window.location.reload();
}

export function shouldRefreshToken(token: string, tokenExpiry: number) {
  return token && isAccessTokenExpired(tokenExpiry);
}

export function isAccessTokenExpired(tokenExpiry: number) {
  return moment().unix() > tokenExpiry;
}

export const isWixOverviewPremiumCard = (cardId: string) => {
  return overviewPremiumCardIds.includes(cardId);
};

/** @deprecated UPDATE */
export const getNavIconByName = (rawName: string, active: boolean, iconColor?: string) => {
  const name = capitalizeFirstLetter(rawName);

  const Component = (Icons as any)['Nav' + name];
  return <Component isActive={active} color={iconColor} />;
};
