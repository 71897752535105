import { CommonFilterParams, Filter, FilterOperators, FilterTypes, useOptionsHook } from '@va/types/filters';
import { SingleSelectFilterInput } from './SingleSelectFilterInput';
import { SingleSelectFilterInputApplied } from './SingleSelectFilterInputApplied';

export type SingleSelectFilterValue = number | string | undefined;

export type SingleSelectFilterProps = {
  useOptions: useOptionsHook;
  label?: string;
  showSearchInput?: boolean;
  searchPlaceholder?: string;
};

type SingleSelectFilterParams = CommonFilterParams<SingleSelectFilterValue, SingleSelectFilterProps>;

export class SingleSelectFilter extends Filter<SingleSelectFilterValue, SingleSelectFilterProps> {
  constructor(params: SingleSelectFilterParams) {
    super({
      ...params,
      operator: params.operator || FilterOperators.is,
      input: SingleSelectFilterInput,
      appliedInput: SingleSelectFilterInputApplied,
      type: FilterTypes.SingleSelect,
    });
  }
}
