import { apiStatus } from '@va/constants';
import { WixPermissionPack, WixPlanCycle } from '@va/wix/shared/constants';

export const getApiRequestStatus = (state: any, apiRequestName: string) =>
  state.getIn(['api', apiRequestName, 'status'], apiStatus.NONE);

export const getPermissionPackage = (state: any) =>
  state.getIn(
    ['core', 'api', 'getFeaturePermissions', 'wixPermissionPack'],
    WixPermissionPack.FREE,
  ) as WixPermissionPack;

export const getWixPlanCycle = (state: any) =>
  state.getIn(['core', 'api', 'getFeaturePermissions', 'wixCycleType'], WixPlanCycle.UNKNOWN) as WixPlanCycle;
