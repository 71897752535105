import {
  SubscriptionList,
  useNewsletterSubscriberSA,
  useNewsletterSubscriberWix,
  useSubscribeToNewsletterSA,
  useSubscribeToNewsletterWix,
  useUnsubscribeToNewsletterSA,
  useUnsubscribeToNewsletterWix,
} from '@va/dashboard/api-client/newsletter-preferences';
import { useGetNotificationSettings, useUpdateNotificationSettings } from '@va/dashboard/feature/general-settings';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { isWixApp } from '@va/util/helpers';
import { useCallback } from 'react';
import { NewsletterSettingsFormFieldsType } from './useNewsletterSettings';

export const useUpdateNewsletterSettings = () => {
  const { showErrorNotification } = useAddNotification();
  const { getSubscriber, subscribe, unsubscribe } = useNewsletterSubscriber();

  const { execute: executeSubscribe, isLoading: isSubscribeLoading } = subscribe();
  const { execute: executeUnsubscribe, isLoading: isUnsubscribeLoading } = unsubscribe();
  const { data: currentSubscription, mutate: mutateSubscription, isLoading: isSubscriptionLoading } = getSubscriber();
  const { execute: updateReportsSettings, isLoading: isReportsSettingsUpdateLoading } = useUpdateNotificationSettings();

  const {
    data: reportsSettings,
    mutate: mutateNotificationSettings,
    isLoading: isReportsLoading,
  } = useGetNotificationSettings();

  const updateNewsletterSettings = useCallback(
    async (values: NewsletterSettingsFormFieldsType, onSuccess?: () => void) => {
      const reportsPayload = { reportType: values.reportType };

      const newsletterPayload = isWixApp()
        ? { lists: [SubscriptionList.wixNewsletter, SubscriptionList.wixPromotions] }
        : { lists: [SubscriptionList.standalonePromotions, SubscriptionList.standaloneNewsletter] };

      try {
        await updateReportsSettings(reportsPayload);
        if (values.newsletter) {
          await executeSubscribe(newsletterPayload);
        } else {
          await executeUnsubscribe(newsletterPayload);
        }
        onSuccess?.();
      } catch (err) {
        showErrorNotification();
        mutateNotificationSettings();
        mutateSubscription();
      }
    },
    [
      executeSubscribe,
      executeUnsubscribe,
      mutateNotificationSettings,
      mutateSubscription,
      showErrorNotification,
      updateReportsSettings,
    ],
  );

  return {
    currentSubscription,
    isSubscriptionLoading,
    isReportsLoading,
    reportsSettings,
    updateNewsletterSettings,
    isNewsletterSettingsUpdateLoading: isSubscribeLoading || isUnsubscribeLoading || isReportsSettingsUpdateLoading,
  };
};

const useNewsletterSubscriber = () => {
  return {
    getSubscriber: isWixApp() ? useNewsletterSubscriberWix : useNewsletterSubscriberSA,
    subscribe: isWixApp() ? useSubscribeToNewsletterWix : useSubscribeToNewsletterSA,
    unsubscribe: isWixApp() ? useUnsubscribeToNewsletterWix : useUnsubscribeToNewsletterSA,
  };
};
