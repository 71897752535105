import { TEST_IDS } from '@va/constants';
import { PlusIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { hasPermission } from '@va/standalone/shared/selectors';
import { Paragraph, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

type UpgradePlanTooltipProps = {
  onClose?: () => void;
  requiredPlanText?: string;
  currentPlanText?: string;
  image?: ReactNode;
  title: string;
  text: string;
  onUpgradeClick?: () => void;
  className?: string;
  buttonClassName?: string;
  buttonTextColorClassName?: string;
  displayButtonIcon?: boolean;
};

export const UpgradePlanTooltipComponent = ({
  onClose,
  requiredPlanText,
  currentPlanText,
  image,
  title,
  text,
  onUpgradeClick,
  className,
  buttonClassName = 'bg-white',
  buttonTextColorClassName = 'text-gray-charcoal',
  displayButtonIcon = true,
}: UpgradePlanTooltipProps) => {
  const translate = useTranslate();
  const hasUpgradePermission = useSelector(hasPermission);

  return (
    <div
      className={classNames(
        'flex flex-col justify-center items-center p-6 rounded-24 bg-black-lighter-84 gap-3',
        className,
      )}
      data-testid={TEST_IDS.generic.tooltipContent}
    >
      <Paragraph
        data-testid={TEST_IDS.generic.tooltipTitle}
        className='text-white text-center'
        size={paragraphSizes.large}
        weight={fontWeights.medium}
      >
        {title}
      </Paragraph>

      {image}

      <Paragraph
        data-testid={TEST_IDS.generic.tooltipSubtitle}
        size={paragraphSizes.tiny}
        weight={fontWeights.medium}
        colorClassName='text-white'
        className='text-center'
      >
        {text}
      </Paragraph>
      <div className='flex flex-col w-full rounded-15 flex-grow overflow-hidden justify-center'>
        {requiredPlanText && currentPlanText && (
          <div className='flex-grow bg-black-dark/25 py-3 text-center' data-testid={TEST_IDS.generic.tooltipText}>
            <Paragraph colorClassName='text-white' weight={fontWeights.medium} size={paragraphSizes.tiny}>
              {requiredPlanText}
            </Paragraph>
            <Paragraph colorClassName='text-white' weight={fontWeights.medium} className='!text-[10px] !leading-4'>
              {currentPlanText}
            </Paragraph>
          </div>
        )}

        {onUpgradeClick && (
          <TooltipWrapper disabled={hasUpgradePermission} content={translate('tooltips.contributorRole')}>
            <div
              className={classNames(
                'flex flex-row gap-[9px] items-center justify-center py-3 text-center cursor-pointer',
                buttonClassName,
              )}
              onClick={() => {
                onClose?.();
                onUpgradeClick();
              }}
              data-testid={TEST_IDS.generic.buttons.upgrade}
            >
              {displayButtonIcon && <PlusIcon className='w-6 h-6 shrink-0' color='#3C3C3C' />}
              <Paragraph
                size={paragraphSizes.normal}
                weight={fontWeights.medium}
                colorClassName={buttonTextColorClassName}
              >
                {translate('all.upgradePlanTooltip.upgradePlan')}
              </Paragraph>
            </div>
          </TooltipWrapper>
        )}
      </div>
    </div>
  );
};
