import { StarIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { PrivacyLevelsEnum } from '@va/standalone/shared/types';
import {
  GrayGradientDivider,
  Paragraph,
  ParagraphWithTooltip,
  fontWeights,
  paragraphSizes,
} from '@va/ui/design-system';
import classNames from 'classnames';
import { DataPrivacyCardRadioButton } from './DataPrivacyCardRadioButton';
import { DataPrivacyLevelIcon } from './DataPrivacyLevelIcon';

type DataPrivacyCardProps = {
  onClick: (value: PrivacyLevelsEnum) => void;
  selected: boolean;
  value: PrivacyLevelsEnum;
  baseKey: string;
  variant?: 'default' | 'wix-mini';
  bannerKey?: string;
};

const styles = {
  default: {
    banner: 'bg-gradient-to-r from-primary-lighter-13 to-white',
    card: {
      base: 'bg-white-snow',
      selected: '!bg-primary-lighter-13 ',
    },
    detailsDivider: {
      selected: '',
    },
  },
  'wix-mini': {
    banner: 'bg-white',
    card: {
      base: 'bg-blue-solitude',
      selected: 'bg-white hover:bg-white',
    },
    detailsDivider: {
      selected: 'bg-white w-full',
    },
  },
};

export const DataPrivacyCard = ({
  onClick,
  selected,
  value,
  baseKey,
  variant = 'default',
  bannerKey,
}: DataPrivacyCardProps) => {
  const translate = useTranslate();

  return (
    <div className='relative flex flex-col w-[261px]'>
      <div className='h-6 mb-1.5'>
        {bannerKey && (
          <div
            className={classNames(
              'flex items-center justify-start bg-white rounded-full py-1 pl-1.5 pr-9px ',
              styles[variant].banner,
            )}
          >
            <StarIcon color='var(--color-primary)' className='shrink-0 inline mr-1.5 opacity-50' />
            <ParagraphWithTooltip
              className='inline text-center'
              colorClassName='text-primary'
              size={paragraphSizes.tiny}
              weight={fontWeights.medium}
            >
              {translate(bannerKey)}
            </ParagraphWithTooltip>
          </div>
        )}
      </div>
      <div
        onClick={() => {
          onClick(value);
        }}
        className={classNames(
          'relative flex flex-col items-center cursor-pointer text-center break-words shrink-0 gap-1.5 p-1.5 rounded-[24px]',
          styles[variant].card.base,
          { [styles[variant].card.selected]: selected },
        )}
      >
        <DataPrivacyCardRadioButton selected={selected} />
        <div className={'flex w-full items-center gap-9px self-start'}>
          <DataPrivacyLevelIcon selected={selected} value={value} />
          <div className={'text-start'}>
            <Paragraph
              className={'!text-15 !leading-18 text-start'}
              size={paragraphSizes.large}
              colorClassName={classNames({
                'text-black-lighter-84': !selected,
                '!text-primary': selected,
              })}
              weight={fontWeights.semibold}
            >
              {translate(`${baseKey}.title`)}
            </Paragraph>
            <Paragraph
              size={paragraphSizes.tiny}
              weight={fontWeights.medium}
              colorClassName={classNames({
                'text-black-lighter-60': !selected,
                '!text-primary': selected,
              })}
            >
              {translate(`${baseKey}.subtitle`)}
            </Paragraph>
          </div>
        </div>
        <div className='rounded-18 text-text-primary w-full break-words overflow-hidden'>
          <div className={classNames('bg-white-66 px-3 py-1.5', { '!bg-gray-athens': selected })}>
            <Paragraph
              size={paragraphSizes.tiny}
              colorClassName={classNames({
                'text-gray-devil': !selected,
                'text-gray-charcoal': selected,
              })}
              weight={variant === 'wix-mini' ? fontWeights.semibold : fontWeights.medium}
            >
              {translate(`${baseKey}.info1`)}
            </Paragraph>
          </div>
          <GrayGradientDivider
            className={classNames('bg-none bg-transparent', {
              [styles[variant].detailsDivider.selected]: selected,
            })}
          />

          <div className={classNames('bg-white-66 px-3 py-1.5', { '!bg-gray-athens': selected })}>
            <Paragraph
              size={paragraphSizes.tiny}
              colorClassName={classNames('text-gray-devil', {
                '!text-gray-mine-shaft-light': selected,
              })}
              weight={fontWeights.medium}
            >
              {translate(`${baseKey}.info2`)}
            </Paragraph>
          </div>
          <GrayGradientDivider
            className={classNames('bg-none bg-transparent', {
              [styles[variant].detailsDivider.selected]: selected,
            })}
          />

          <div className={classNames('bg-white-66 px-3 py-1.5', { '!bg-gray-athens': selected })}>
            <ParagraphWithTooltip
              size={paragraphSizes.tiny}
              colorClassName={'text-gray-charcoal'}
              weight={fontWeights.medium}
            >
              {translate('onboarding.privacyLevels.modeComplies')}
            </ParagraphWithTooltip>
            <ParagraphWithTooltip
              size={paragraphSizes.tiny}
              weight={variant === 'wix-mini' ? fontWeights.semibold : fontWeights.medium}
              colorClassName={classNames('text-gray-devil', {
                '!text-gray-mine-shaft-light': selected,
              })}
            >
              {translate(`${baseKey}.info3`)}
            </ParagraphWithTooltip>
          </div>
          <GrayGradientDivider
            className={classNames('bg-none bg-transparent', {
              [styles[variant].detailsDivider.selected]: selected,
            })}
          />

          <div className={classNames('bg-white-66 px-3 py-1.5', { '!bg-gray-athens': selected })}>
            <ParagraphWithTooltip
              size={paragraphSizes.tiny}
              colorClassName={'text-gray-charcoal'}
              weight={fontWeights.medium}
            >
              {translate('onboarding.privacyLevels.dataTracking')}
            </ParagraphWithTooltip>
            <ParagraphWithTooltip
              size={paragraphSizes.tiny}
              weight={variant === 'wix-mini' ? fontWeights.semibold : fontWeights.medium}
              colorClassName={classNames('text-gray-devil', {
                '!text-gray-mine-shaft-light': selected,
              })}
            >
              {translate(`${baseKey}.info4`)}
            </ParagraphWithTooltip>
          </div>
        </div>
      </div>
    </div>
  );
};
