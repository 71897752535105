import { useTranslate } from '@va/localization';
import { CommonFilterParams, Filter } from '@va/types/filters';
import { Paragraph } from '@va/ui/design-system';
import { FilterBlock } from '../../FilterBlock';

type BooleanFilterInputAppliedProps = {
  disableEditing?: boolean;
  viewOnly?: boolean;
  isGrouped?: boolean;
  size?: 'small' | 'medium' | 'large';
  filter: Filter<boolean>;
} & CommonFilterParams<boolean, any>;

export const BooleanFilterInputApplied: React.FC<BooleanFilterInputAppliedProps> = ({
  filter,
  disableEditing,
  viewOnly,
  isGrouped,
  size = 'large',
}) => {
  const { id, operator, operatorOptions, label } = filter;

  const translate = useTranslate();

  return (
    <FilterBlock
      filterId={id}
      fieldName={label}
      operator={operator}
      operatorOptions={operatorOptions}
      hideDeleteButton={viewOnly}
      disabled={disableEditing}
      size={size}
      isGrouped={isGrouped}
      input={
        <div className='bg-white self-stretch flex items-center justify-center px-3 rounded-r-12'>
          <Paragraph>{translate('all.filters.active')}</Paragraph>
        </div>
      }
    />
  );
};
