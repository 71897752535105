import { CodeEditorWithCopyBtn } from '@va/dashboard/ui/components';
import AcceptCookiesImage from '@va/images/cookie-banners/consent-cookie-banner-accept.svg?react';
import DenyCookiesImage from '@va/images/cookie-banners/consent-cookie-banner-deny.svg?react';
import { useTranslate } from '@va/localization';
import {
  getMpmTrackingCode as getMpmTrackingCodeSelector,
  getTrackingCode as getTrackingCodeSelector,
} from '@va/standalone/shared/selectors';
import { fontWeights, GrayGradientDivider, Heading5, Paragraph } from '@va/ui/design-system';
import { useSelector } from 'react-redux';

export const ConsentTrackingCodeSection = () => {
  const translate = useTranslate();
  const trackingCode = useSelector(getTrackingCodeSelector);
  const mpmTrackingCode = useSelector(getMpmTrackingCodeSelector);

  return (
    <div className='flex flex-col gap-3 rounded-t-24 p-3 pt-18px rounded-b-48px bg-gray-mercury-darker'>
      <div className='text-center'>
        <Heading5 weight={fontWeights.semibold}>{translate('trackingCode.installation.consent.title')}</Heading5>
        <Paragraph weight={fontWeights.medium}>{translate('trackingCode.installation.consent.subtitle')}</Paragraph>
      </div>
      <div className='flex flex-col gap-2 bg-white rounded-t-24 rounded-b-36px p-18px'>
        <div className='px-1.5'>
          <Paragraph weight={fontWeights.semibold} colorClassName={'text-gray-charcoal'} className={'leading-24'}>
            {translate('trackingCode.consent.default.description')}
          </Paragraph>
        </div>
        <div className='flex gap-3'>
          <AcceptCookiesImage className='shrink-0 sm:hidden md:hidden lg:block' />
          <CodeEditorWithCopyBtn editorClassName='!bg-white-wild-sand' code={trackingCode} />
        </div>
      </div>
      <div className='flex gap-30px items-center'>
        <GrayGradientDivider className='flex-1 rotate-180 h-1.5px' />
        <Paragraph weight={fontWeights.medium}>{translate('trackingCode.consent.and')}</Paragraph>
        <GrayGradientDivider className='flex-1 h-1.5px' />
      </div>
      <div className='flex flex-col gap-2 bg-white rounded-t-24 rounded-b-36px p-18px'>
        <div className='px-1.5'>
          <Paragraph weight={fontWeights.semibold} colorClassName={'text-gray-charcoal'} className={'leading-24'}>
            {translate('trackingCode.consent.maxPrivacyMode.description')}
          </Paragraph>
        </div>
        <div className='flex gap-3'>
          <DenyCookiesImage className='shrink-0 sm:hidden md:hidden lg:block' />
          <CodeEditorWithCopyBtn editorClassName='!bg-white-wild-sand' code={mpmTrackingCode} />
        </div>
      </div>
    </div>
  );
};
