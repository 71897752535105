import { defaultTheme } from '@va/constants';
import { addTransparencyToHexColor, getBrighterColor, hex2rgbString, isHexDarker } from '../colors/colors.helpers';

type Colors = {
  primary: string;
  secondary: string;
  tertiary: string;
  positive?: string;
  negative?: string;
  neutral?: string;
  text?: {
    primary: string;
    dark?: string;
    secondary?: string;
    tertiary?: string;
  };
};

export const applyTheme = ({ fontName, colors }: { fontName?: string; colors: Colors }) => {
  const { primary, secondary, tertiary, positive = '#06A25A', negative = '#EA2A0C', neutral = '#C3C3C3' } = colors;

  const root = document.documentElement;

  const isColorHexDarker = isHexDarker(colors.primary);

  root.style.setProperty('--color-primary', primary);
  root.style.setProperty(
    '--color-primary-darker-06',
    `linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), ${primary}`,
  );
  root.style.setProperty(
    '--color-primary-darker-13',
    `linear-gradient(0deg, rgba(0, 0, 0, 0.13333), rgba(0, 0, 0, 0.13333)), ${primary}`,
  );
  root.style.setProperty('--color-primary-lighter-06', addTransparencyToHexColor(primary, 0.06));
  root.style.setProperty('--color-primary-lighter-13', addTransparencyToHexColor(primary, 0.13));
  root.style.setProperty('--color-primary-lighter-20', addTransparencyToHexColor(primary, 0.2));
  root.style.setProperty('--color-primary-lighter-60', addTransparencyToHexColor(primary, 0.6));
  root.style.setProperty('--color-primary-bright-50', getBrighterColor(primary));
  root.style.setProperty('--color-secondary', secondary);
  root.style.setProperty('--color-secondary-rgb', hex2rgbString(secondary));
  root.style.setProperty('--color-tertiary', tertiary);
  root.style.setProperty('--color-positive', positive);
  root.style.setProperty('--color-negative', negative);
  root.style.setProperty('--color-neutral', neutral);
  root.style.setProperty('--font-family', `${fontName}`);
  root.style.setProperty('--color-tooltip-background', isColorHexDarker ? '#FFFFFF' : '#000000');
  root.style.setProperty('--color-tooltip-text', isColorHexDarker ? '#000000' : '#FFFFFF');
  root.style.setProperty('--color-text-primary', colors.text?.primary ?? defaultTheme.TEXT_PRIMARY_COLOR);
  root.style.setProperty('--color-text-dark', colors.text?.dark ?? defaultTheme.TEXT_DARK_COLOR);
  root.style.setProperty('--color-text-secondary', colors.text?.secondary ?? defaultTheme.TEXT_SECONDARY_COLOR);
  root.style.setProperty('--color-text-tertiary', colors.text?.tertiary ?? defaultTheme.TEXT_TERTIARY_COLOR);
  window.PRIMARY_COLOR = primary;
  window.SECONDARY_COLOR = secondary;
  window.TERTIARY_COLOR = tertiary;
  window.POSITIVE_COLOR = positive;
  window.NEGATIVE_COLOR = negative;
  window.NEUTRAL_COLOR = neutral;
  window.fontFamily = `${fontName}, sans-serif`;
};

export const appHasDarkTheme = () => {
  return isHexDarker(window.PRIMARY_COLOR);
};

export function setDefaultTheme() {
  const root = document.documentElement;
  if (root) {
    applyTheme({
      fontName: defaultTheme.FONT_FAMILY,
      colors: {
        primary: defaultTheme.PRIMARY_COLOR,
        secondary: defaultTheme.SECONDARY_COLOR,
        tertiary: defaultTheme.TERTIARY_COLOR,
        positive: defaultTheme.POSITIVE_COLOR,
        negative: defaultTheme.NEGATIVE_COLOR,
        neutral: defaultTheme.NEUTRAL_COLOR,
      },
    });
    window.logoId = '';
  }
}

export const isDefaultThemeApplied = () => defaultTheme.PRIMARY_COLOR === window.PRIMARY_COLOR;
