import { createSelector } from '@reduxjs/toolkit';
import { AaasFeaturePermission, FeatureName, RootState } from '@va/aaas/shared/types';
import { apiStatus } from '@va/constants';

const selectFPermissions = (state: RootState) => state.get('app')?.featurePermissions.data as AaasFeaturePermission[];
const selectFPermisionsStatus = (state: RootState) => state.get('app')?.featurePermissions.status as apiStatus;

export const Selectors3asFPermissions = {
  data: selectFPermissions,
  isLoading: createSelector(selectFPermisionsStatus, (status) => status === apiStatus.IN_PROGRESS),
  status: selectFPermisionsStatus,
  isEnabled: {
    ipToCompany: createSelector(selectFPermissions, (permissions) => {
      return isEnabled(permissions, FeatureName.ip2company);
    }),
    visitorSegments: createSelector(selectFPermissions, (permissions) => {
      return isEnabled(permissions, FeatureName.visitorSegments);
    }),
    countryBasedPrivacy: createSelector(selectFPermissions, (permissions) => {
      return isEnabled(permissions, FeatureName.countryBasedPrivacy);
    }),
    customReports: createSelector(selectFPermissions, (permissions) => {
      return isEnabled(permissions, FeatureName.customReports);
    }),
    customDashboards: createSelector(selectFPermissions, (permissions) => {
      return isEnabled(permissions, FeatureName.customDashboards);
    }),
    fullDataAccess: createSelector(selectFPermissions, (permissions) => {
      return isEnabled(permissions, FeatureName.fullDataAccess);
    }),
  },
  limit: {
    ipToCompany: createSelector(selectFPermissions, (permissions) => {
      return getMinStpsCount(permissions, FeatureName.ip2company);
    }),
    visitorSegments: createSelector(selectFPermissions, (permissions) => {
      return getMinStpsCount(permissions, FeatureName.visitorSegments);
    }),
    countryBasedPrivacy: createSelector(selectFPermissions, (permissions) => {
      return getMinStpsCount(permissions, FeatureName.countryBasedPrivacy);
    }),
    customReports: createSelector(selectFPermissions, (permissions) => {
      return getMinStpsCount(permissions, FeatureName.customReports);
    }),
    customDashboards: createSelector(selectFPermissions, (permissions) => {
      return getMinStpsCount(permissions, FeatureName.customDashboards);
    }),
    fullDataAccess: createSelector(selectFPermissions, (permissions) => {
      return getMinStpsCount(permissions, FeatureName.fullDataAccess);
    }),
  },
};

function isEnabled(features: AaasFeaturePermission[], featureName: FeatureName) {
  return features.find((item) => item.feature === featureName)?.enabled || false;
}

function getMinStpsCount(features: AaasFeaturePermission[], featureName: FeatureName) {
  return features.find((item) => item.feature === featureName)?.minStps ?? 0;
}
