export const EVENTS = {
  collapseSidebarItems: 'collapseSidebarItems',
  collapseFilters: 'collapseFilters',
  collapseSegments: 'collapseSegments',
  selectedSegmentsChanged: 'selectedSegmentsChanged',
  appliedFiltersChanged: 'appliedFiltersChanged',
  saveCustomDashboard: 'saveCustomDashboard',
  openDashboardEditMode: 'openDashboardEditMode',
  skipUnsavedChangesWarning: 'skipUnsavedChangesWarning',
  editRBName: 'editRBName',
};

export enum BindingActions {
  abort = 'abort',
  afterprint = 'afterprint',
  beforeprint = 'beforeprint',
  beforeunload = 'beforeunload',
  blur = 'blur',
  canplay = 'canplay',
  canplaythrough = 'canplaythrough',
  change = 'change',
  click = 'click',
  contextmenu = 'contextmenu',
  copy = 'copy',
  cut = 'cut',
  dblclick = 'dblclick',
  drag = 'drag',
  dragend = 'dragend',
  dragenter = 'dragenter',
  dragleave = 'dragleave',
  dragover = 'dragover',
  dragstart = 'dragstart',
  drop = 'drop',
  durationchange = 'durationchange',
  ended = 'ended',
  error = 'error',
  focus = 'focus',
  focusin = 'focusin',
  focusout = 'focusout',
  fullscreenchange = 'fullscreenchange',
  fullscreenerror = 'fullscreenerror',
  hashchange = 'hashchange',
  input = 'input',
  invalid = 'invalid',
  keydown = 'keydown',
  keypress = 'keypress',
  keyup = 'keyup',
  load = 'load',
  loadeddata = 'loadeddata',
  loadedmetadata = 'loadedmetadata',
  loadstart = 'loadstart',
  message = 'message',
  mousedown = 'mousedown',
  mouseenter = 'mouseenter',
  mouseleave = 'mouseleave',
  mousemove = 'mousemove',
  mouseover = 'mouseover',
  mouseout = 'mouseout',
  mouseup = 'mouseup',
  mousewheel = 'mousewheel',
  offline = 'offline',
  online = 'online',
  open = 'open',
  pagehide = 'pagehide',
  pageshow = 'pageshow',
  paste = 'paste',
  pause = 'pause',
  play = 'play',
  playing = 'playing',
  progress = 'progress',
  ratechange = 'ratechange',
  resize = 'resize',
  reset = 'reset',
  scroll = 'scroll',
  search = 'search',
  seeked = 'seeked',
  seeking = 'seeking',
  select = 'select',
  show = 'show',
  stalled = 'stalled',
  storage = 'storage',
  submit = 'submit',
  suspend = 'suspend',
  timeupdate = 'timeupdate',
  toggle = 'toggle',
  touchcancel = 'touchcancel',
  touchend = 'touchend',
  touchmove = 'touchmove',
  touchstart = 'touchstart',
  unload = 'unload',
  volumechange = 'volumechange',
  waiting = 'waiting',
  wheel = 'wheel',
}
