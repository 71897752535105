import { PlanNames } from '@va/constants';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { FREE_PLAN_DETAILS, SubscriptionTypeEnum } from '@va/standalone/shared/constants';
import { useFetchData } from '@va/util/hooks';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export type ResponsePaymentPlan = {
  id: string;
  name: PlanNames;
  price: number;
  permissions: { name: string; value: number }[];
  active: boolean;
  type: string;
  weight: number;
  oldPlanIds?: Array<string | number>;
} & { [key: string]: unknown };

export type PlanType = {
  name: PlanNames;
  planId: string;
  limit: number;
  monthlyPrice: number;
  index: number;
  monthlyPriceWithoutDiscount: number;
  type: string;
  oldPlanIds?: Array<string | number>;
};

export const freeWebsitePlan: PlanType = {
  planId: 'FREE',
  index: -1,
  name: PlanNames.Free,
  limit: FREE_PLAN_DETAILS.visits,
  monthlyPriceWithoutDiscount: 0,
  monthlyPrice: 0,
  type: SubscriptionTypeEnum.YEARLY,
};

export type PaymentPlansResponse = {
  monthly: ResponsePaymentPlan[];
  yearly: ResponsePaymentPlan[];
};

export type MappedPlansResponse = {
  monthly: PlanType[];
  yearly: PlanType[];
};

type PaymentPlans<T extends boolean> = {
  monthly: T extends true ? Record<number, PlanType> : PlanType[];
  yearly: T extends true ? Record<number, PlanType> : PlanType[];
};

export const usePaymentPlans = <T extends boolean = false>(asObject: T = false as T) => {
  const websiteId = useSelector(getInstanceId);
  const url = `/websites/${websiteId}/payment-plans`;
  const mapper = useCallback((response: PaymentPlansResponse) => {
    const { monthly, yearly } = response;
    const permissionLimit = 'VISITS_LIMIT';

    const monthlyPlans: PlanType[] = Object.values(monthly).map((plan: ResponsePaymentPlan, index) => ({
      name: plan.name,
      index: index,
      planId: plan.id,
      limit: plan.permissions.find((item) => item.name === permissionLimit)?.value || 0,
      monthlyPrice: plan.price,
      monthlyPriceWithoutDiscount: plan.price,
      type: plan.type,
      oldPlanIds: plan?.oldPlanIds,
    }));

    const yearlyPlans: PlanType[] = Object.values(yearly).map((plan: ResponsePaymentPlan, index) => ({
      name: plan.name,
      index: index,
      planId: plan.id,
      limit: plan.permissions.find((item) => item.name === permissionLimit)?.value || 0,
      monthlyPrice: plan.price / 12,
      monthlyPriceWithoutDiscount: monthlyPlans[index].monthlyPrice,
      type: plan.type,
      oldPlanIds: plan?.oldPlanIds,
    }));

    return {
      monthly: [freeWebsitePlan, ...(monthlyPlans as PlanType[])],
      yearly: [freeWebsitePlan, ...(yearlyPlans as PlanType[])],
    } as PaymentPlans<T>;
  }, []);
  const { data, ...swr } = useFetchData<PaymentPlans<T>>(url, {}, mapper);

  if (asObject && data) {
    let monthlyPlans: PlanType[] | Record<number, PlanType> = data.monthly;
    let yearlyPlans: PlanType[] | Record<number, PlanType> = data.yearly;

    monthlyPlans = (monthlyPlans as PlanType[]).reduce((acc, plan) => ({ ...acc, [plan.index]: plan }), {
      [freeWebsitePlan.index]: freeWebsitePlan,
    });
    yearlyPlans = (yearlyPlans as PlanType[]).reduce((acc, plan) => ({ ...acc, [plan.index]: plan }), {
      [freeWebsitePlan.index]: freeWebsitePlan,
    });

    return {
      ...swr,
      data: {
        monthly: monthlyPlans,
        yearly: yearlyPlans,
      } as PaymentPlans<T>,
    };
  }

  return { data, ...swr };
};
