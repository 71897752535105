import { setGlobalFilter } from '@va/dashboard/actions/ui';
import { getWebsite } from '@va/dashboard/selectors/core';
import {
  getGlobalFilter,
  shouldRefreshDatePicker as shouldRefreshDatePickerSelector,
} from '@va/dashboard/selectors/ui';
import { FreePlanUpgradeTooltipContent } from '@va/dashboard/ui/components';
import { useFreePlanStatus, useRefreshDateFilter } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { DatePicker } from '@va/shared/feature-litepie-datepicker';
import dayjs from 'dayjs';
import { Moment } from 'moment';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

type DatePickerContainerProps = {
  featureReleaseDate?: number;
  installDate?: number;
  placement?: string;
  size?: 'small' | 'medium';
  onClick?: () => void;
  onHover?: () => void;
};

function DatePickerContainer({
  featureReleaseDate,
  installDate: installDateProp,
  placement,
  size = 'medium',
  onClick,
  onHover,
}: DatePickerContainerProps) {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const filter = useSelector(getGlobalFilter);
  const website = useSelector(getWebsite);
  const installDate = installDateProp ?? website.installDate;
  const installDateInstance = dayjs(installDate * 1000).tz(window.timezone);
  const today = dayjs().tz(window.timezone).endOf('day');
  const { hasActiveFreePlan, freePlanHistoryStartDate } = useFreePlanStatus();

  const shouldRefreshDatePicker = useSelector(shouldRefreshDatePickerSelector);
  const onPeriodChange = useCallback(
    (date: [Moment, Moment]) => {
      const [from, until] = date;

      dispatch(setGlobalFilter({ from, until }));
    },
    [dispatch],
  );

  const getClosestDate = useCallback(() => {
    const featureDate = featureReleaseDate ? dayjs(featureReleaseDate * 1000).tz(window.timezone) : null;

    const datesToCompare = [];
    if (featureDate) datesToCompare.push(featureDate);
    if (hasActiveFreePlan) {
      datesToCompare.push(freePlanHistoryStartDate);
    } else {
      datesToCompare.push(installDateInstance);
    }
    return datesToCompare.reduce((closest, current) =>
      Math.abs(current.diff(today)) < Math.abs(closest.diff(today)) ? current : closest,
    );
  }, [featureReleaseDate, hasActiveFreePlan, freePlanHistoryStartDate, installDateInstance, today]);

  const minimumDate = useMemo(() => getClosestDate(), [getClosestDate]);
  const maximumDate = dayjs().tz(window.timezone).endOf('day');

  const getDatePickerTooltip = () => {
    const closestDate = getClosestDate();

    if (hasActiveFreePlan && closestDate.isSame(dayjs(freePlanHistoryStartDate).tz(window.timezone))) {
      return <FreePlanUpgradeTooltipContent />;
    }

    if (featureReleaseDate && closestDate.isSame(dayjs(featureReleaseDate * 1000).tz(window.timezone))) {
      return translate('filter.featureReleaseDate', {
        releaseDate: closestDate.format('L'),
      });
    }

    return translate('filter.installedToday');
  };

  useRefreshDateFilter();
  return (
    <DatePicker
      tooltipContent={getDatePickerTooltip()}
      onPeriodChange={onPeriodChange}
      startDate={filter.from}
      endDate={filter.until}
      shouldDisablePastDates={hasActiveFreePlan}
      trackedDataStartDate={installDateInstance}
      minDate={minimumDate}
      maxDate={maximumDate}
      shouldRefreshDatePicker={shouldRefreshDatePicker}
      placement={placement}
      onClick={onClick}
      onHover={onHover}
      size={size}
    />
  );
}

export default DatePickerContainer;
