import { OnboardingStep } from '@va/dashboard/shared/onboarding';
import { useTranslate } from '@va/localization';
import { AddWebsiteForm } from '@va/standalone/core/shared/onboarding';
import { Button } from '@va/ui/design-system';
import { useCallback } from 'react';

type AddWebsiteUrlStepProps = { onContinue: (url: string) => void; initialValue?: string };

const FORM_ID = 'add-website-url-form';

const AddWebsiteUrlStep = ({ onContinue, initialValue }: AddWebsiteUrlStepProps) => {
  const translate = useTranslate();

  const onSubmit = useCallback(
    (values: { url: string }) => {
      onContinue(values.url);
    },
    [onContinue],
  );

  return (
    <OnboardingStep
      title={translate('standalone.websiteCreation.websiteUrl.title')}
      subtitle={translate('standalone.websiteCreation.websiteUrl.subtitle')}
      footer={<Button type='submit' form={FORM_ID} className='w-full' text={translate('button.continue')} />}
    >
      <AddWebsiteForm formId={FORM_ID} onSubmit={onSubmit} initialValues={{ url: initialValue ?? '' }} />
    </OnboardingStep>
  );
};

export default AddWebsiteUrlStep;
