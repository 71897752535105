import { reportType } from '@va/constants';
import {
  GET_NOTIFICATION_SETTINGS_REQUEST,
  SETTINGS_REQUEST,
  UPDATE_ACCOUNT_INFO_REQUEST,
  UPDATE_SETTINGS_NOTIFICATIONS_REQUEST,
  UPDATE_SETTINGS_REQUEST,
  UPDATE_SETTINGS_SUCCEEDED,
  getNotificationSettings,
  getNotificationSettingsFailed,
  getNotificationSettingsSucceeded,
  requestSettings,
  requestSettingsFailed,
  requestSettingsSucceeded,
  requestWebsiteSucceeded,
  resetRequestStatus,
  updateAccountInfoFailed,
  updateAccountInfoSucceeded,
  updateSettings,
  updateSettingsFailed,
  updateSettingsNotificationsFailed,
  updateSettingsNotificationsSucceeded,
  updateSettingsSucceeded,
} from '@va/dashboard/actions/api';
import Api from '@va/dashboard/api-client/index';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { getLocale } from '@va/dashboard/selectors/ui';
import Immutable from 'immutable';
import { all, call, delay, put, select, take, takeLatest } from 'redux-saga/effects';

export function* watchers() {
  yield all([
    takeLatest(SETTINGS_REQUEST, getSettingsSaga),
    takeLatest(GET_NOTIFICATION_SETTINGS_REQUEST, getNotificationSettingsSaga),
    takeLatest(UPDATE_SETTINGS_REQUEST, updateSettingsSaga),
    takeLatest(UPDATE_SETTINGS_NOTIFICATIONS_REQUEST, updateSettingsNotificationsSaga),
    takeLatest(UPDATE_ACCOUNT_INFO_REQUEST, updateAccountInfoSaga),
  ]);
}

/** @deprecated */
function* getSettingsSaga() {
  try {
    const websiteId = yield select(getInstanceId);
    // Quick fix for removing redundant getSettings request. Get website returns the required settings fields.
    //TODO: Remove getSettings and use only getWebsite api call.
    const data = yield call(Api.getWebsite, websiteId);
    yield put(requestSettingsSucceeded(data));
    yield put(requestWebsiteSucceeded(data));
  } catch (error) {
    yield put(requestSettingsFailed(error));
  }
}

function* updateSettingsSaga(action) {
  try {
    const websiteId = yield select(getInstanceId);
    const locale = yield select(getLocale);

    const requestData = {
      email: action.data.get('email') && action.data.get('email', '').trim(),
      firstName: action.data.get('firstName') && action.data.get('firstName', '').trim(),
      lastName: action.data.get('lastName') && action.data.get('lastName', '').trim(),
      company: action.data.get('companyName') && action.data.get('companyName', '').trim(),
      businessSector: action.data.get('businessSector') && action.data.get('businessSector', ''),
      businessType: action.data.get('businessType') && action.data.get('businessType', ''),
      businessSize: action.data.get('businessSize') && action.data.get('businessSize', ''),
      country: action.data.get('country') && action.data.get('country', ''),
      timezone: action.data.get('timezone'),
      newsletter: action.data.get('reportType'),
      locale: locale,
    };

    const data = yield call(Api.updateSettings, websiteId, requestData);
    yield put(updateSettingsSucceeded(data));
    yield put(requestWebsiteSucceeded(data));
  } catch (error) {
    yield put(updateSettingsFailed(error));
  } finally {
    yield delay(3000);
    yield put(resetRequestStatus('updateSettings'));
  }
}

const getReportTypeValue = (statReports, frequency) => {
  if (statReports === true) {
    return parseInt(frequency);
  } else return reportType.NEVER;
};

function* updateSettingsNotificationsSaga(action) {
  try {
    const websiteId = yield select(getInstanceId);
    const requestData = {
      reportType: getReportTypeValue(action.data.get('statReports'), action.data.get('sendEmailFrequency')),
      //tips and offers has merged on UI
      sendEmailTips: action.data.get('sendEmailTips'),
      sendEmailOffers: action.data.get('sendEmailTips'),
    };
    yield call(Api.updateSubscription, websiteId, requestData);
    const email = action.data.get('emailAddress');
    if (email) {
      yield put(updateSettings(Immutable.Map({ email: email })));
      yield take([UPDATE_SETTINGS_SUCCEEDED, UPDATE_SETTINGS_SUCCEEDED]);
      yield put(requestSettings());
    }
    yield put(updateSettingsNotificationsSucceeded());
    yield put(getNotificationSettings());
  } catch (error) {
    yield put(updateSettingsNotificationsFailed(error));
  } finally {
    yield delay(3000);
    yield put(resetRequestStatus('updateSettingsNotifications'));
  }
}

function* getNotificationSettingsSaga() {
  try {
    const websiteId = yield select(getInstanceId);
    const data = yield call(Api.getNotificationSettings, websiteId);
    yield put(getNotificationSettingsSucceeded(data));
  } catch (error) {
    yield put(getNotificationSettingsFailed(error));
  }
}

function* updateAccountInfoSaga(action) {
  try {
    const websiteId = yield select(getInstanceId);
    let requestData = {};
    if (Object.prototype.hasOwnProperty.call(action.data, 'email')) {
      requestData.email = action.data.email ? action.data.email.trim() : '';
    }
    if (Object.prototype.hasOwnProperty.call(action.data, 'firstName')) {
      requestData.firstName = action.data.firstName ? action.data.firstName.trim() : '';
    }
    if (Object.prototype.hasOwnProperty.call(action.data, 'lastName')) {
      requestData.lastName = action.data.lastName ? action.data.lastName.trim() : '';
    }
    if (Object.prototype.hasOwnProperty.call(action.data, 'companyName')) {
      requestData.company = action.data.companyName ? action.data.companyName.trim() : '';
    }
    if (Object.prototype.hasOwnProperty.call(action.data, 'businessSector')) {
      requestData.businessSector = action.data.businessSector;
    }

    const data = yield call(Api.updateSettings, websiteId, requestData);
    yield put(updateAccountInfoSucceeded());
    yield put(requestWebsiteSucceeded(data));
  } catch (error) {
    yield put(updateAccountInfoFailed(error));
  } finally {
    yield delay(3000);
    yield put(resetRequestStatus('updateAccountInfo'));
  }
}
