import { Portal } from '@va/util/components';
import { setResizeObserver } from '@va/util/helpers';
import classNames from 'classnames';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Redirect, Route, useHistory, useRouteMatch } from 'react-router';
import './multi-step-wizard.scss';
import { StepsVisualization } from './StepsVisualization';

export type MultiStepWizardStep = {
  label: React.ReactNode;
  component: ReactNode;
  hasError?: boolean;
  isFinalized?: boolean;
  subInfo?: string;
  smallLabel?: boolean;
  canReturn?: boolean;
  className?: string;
};

export type MultiStepWizardRoute = MultiStepWizardStep & {
  path: string;
};

export type MultiStepWizardProps<T extends boolean> = {
  steps: T extends true ? MultiStepWizardRoute[] : MultiStepWizardStep[];
  activeStep: number;
  onStepChange?: (step: number) => void;
  variant?: 'default' | 'wix-mini';
  stepsContainerId?: string;
  useRoutes?: T;
};

const STEP_ITEM_WIDTH = 100;
const STEP_ITEM_ICON_WIDTH = 24;
const STEP_ITEM_GAP = 4;

export const MultiStepWizard = <T extends boolean>({
  steps,
  activeStep,
  onStepChange,
  variant = 'default',
  stepsContainerId,
  useRoutes = false as T,
}: MultiStepWizardProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { url, path } = useRouteMatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const externalContainerRef = useRef<HTMLDivElement | null>(null);

  const [containerWidth, setContainerWidth] = useState(containerRef.current?.clientWidth ?? 0);

  const onResizeCallback = useCallback(() => {
    setContainerWidth(externalContainerRef.current?.clientWidth ?? containerRef.current?.clientWidth ?? 0);
  }, []);

  useEffect(() => {
    externalContainerRef.current = document.querySelector(`#${stepsContainerId}`);

    setResizeObserver(externalContainerRef.current ? externalContainerRef : containerRef, onResizeCallback);
  }, [onResizeCallback, stepsContainerId]);

  const translateBy = useMemo(() => {
    return (
      containerWidth * 0.5 - STEP_ITEM_WIDTH * activeStep - STEP_ITEM_GAP * activeStep - STEP_ITEM_ICON_WIDTH * 0.5
    );
  }, [activeStep, containerWidth]);

  useEffect(() => {
    if (useRoutes) {
      history.push(path + (steps[activeStep] as MultiStepWizardRoute).path);
    }
  }, [activeStep, history, path, steps, useRoutes]);

  return (
    <div ref={containerRef}>
      {stepsContainerId ? (
        <Portal elementSelector={`#${stepsContainerId}`}>
          <StepsVisualization
            ref={ref}
            activeStep={activeStep}
            steps={steps}
            onStepChange={onStepChange}
            variant={variant}
            itemWidth={STEP_ITEM_WIDTH}
            containerWidth={containerWidth}
            style={{
              transform: `translateX(${translateBy}px)`,
            }}
          />
        </Portal>
      ) : (
        <StepsVisualization
          ref={ref}
          activeStep={activeStep}
          steps={steps}
          onStepChange={onStepChange}
          variant={variant}
          itemWidth={STEP_ITEM_WIDTH}
          containerWidth={containerWidth}
          style={{
            transform: `translateX(${translateBy}px)`,
          }}
        />
      )}

      {!useRoutes && (
        <div className={classNames(steps[activeStep]?.className)}>
          {steps[activeStep] ? steps[activeStep].component : <Redirect to={'/error'} />}
        </div>
      )}
      {useRoutes && (
        <>
          {(steps as MultiStepWizardRoute[]).map((step) => (
            <Route key={step.path} path={url + step.path}>
              {step.component}
            </Route>
          ))}
        </>
      )}
    </div>
  );
};
