import { TEST_IDS, validationTranslationKeys } from '@va/constants';
import { CheckmarkIcon, CopyIcon, ExternalLinkIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { RouterWarningModal, useConfirmationDialogContext } from '@va/shared/util-confirmation';
import { NOT_ALLOWED_CUSTOM_DOMAINS } from '@va/standalone/shared/constants';
import {
  Button,
  InputMessage,
  Paragraph,
  ParagraphWithTooltip,
  SelectDropdownOption,
  TextInput,
  fontWeights,
  paragraphSizes,
} from '@va/ui/design-system';
import { getUrlPrefix } from '@va/util/helpers';
import { useCopyToClipboard } from '@va/util/hooks';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useCallback, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { number, object, string } from 'yup';
import { SubSectionHeader } from '../misc/SubSectionHeader';
import { DomainSupportUrlComponent } from './DomainSupportUrlComponent';
import UpdateCustomDomainPrefixModal from './UpdateCustomDomainPrefixModal';

type CustomDomainsFormValues = {
  subdomain: string;
  domain: number;
};

type CustomSubdomainComponentProps = {
  customUrl: string;
  onSubmit: (domainUrl: string) => void;
  domains: SelectDropdownOption<number>[];
  supportCenterUrl?: string;
  saveButtonProps?: { disabled?: boolean };
  isCustomDomainLoading?: boolean;
};

export const CustomSubdomainComponent: React.FC<CustomSubdomainComponentProps> = ({
  onSubmit,
  domains,
  supportCenterUrl,
  customUrl,
  saveButtonProps,
  isCustomDomainLoading,
}) => {
  const validationSchema = useCustomDomainsValidationSchema();
  const translate = useTranslate();
  const { confirm } = useConfirmationDialogContext();

  const initialValues: CustomDomainsFormValues = useMemo(
    () => ({
      subdomain: customUrl ? getUrlPrefix(customUrl) : '',
      domain: domains.length === 1 ? domains[0].value : -1,
    }),
    [customUrl, domains],
  );

  const createDomainUrl = useCallback(
    (values: CustomDomainsFormValues) => {
      const { subdomain, domain } = values;
      const domainLabel = domains.find((d) => d.value === domain)?.label;
      return 'https://'
        .concat(subdomain)
        .concat('.')
        .concat(domainLabel ?? '');
    },
    [domains],
  );

  const { values, touched, errors, handleSubmit, handleChange, handleBlur, setFieldValue } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      confirm(() => {
        onSubmit(createDomainUrl(values));
      }, UpdateCustomDomainPrefixModal);
    },
    validationSchema,
  });

  const { onClickCopy: copyUrl, success: copyUrlSuccess } = useCopyToClipboard();

  const domainUrl = useMemo(() => createDomainUrl(values), [createDomainUrl, values]);

  const domainError = useMemo(
    () => touched.subdomain && (errors.subdomain || errors.domain),
    [errors.domain, errors.subdomain, touched.subdomain],
  );

  const hasMadeChanges = useMemo(() => customUrl !== domainUrl, [customUrl, domainUrl]);

  const isSaveButtonDisabled = useMemo(
    () => !hasMadeChanges || saveButtonProps?.disabled,
    [hasMadeChanges, saveButtonProps?.disabled],
  );

  const selectedDomain = useMemo(() => domains.find((d) => d.value === values.domain), [domains, values.domain]);

  const domainSupportUrl = useMemo(
    () => supportCenterUrl ?? domainUrl.concat('/support'),
    [domainUrl, supportCenterUrl],
  );

  return (
    <>
      <RouterWarningModal when={hasMadeChanges} />
      <form onSubmit={handleSubmit}>
        <SubSectionHeader
          text={translate('whiteLabel.ownSubdomain.title')}
          tooltip={translate('whiteLabel.ownSubdomain.tooltip')}
        />
        <div className='w-full flex gap-3 items-center bg-white-snow rounded-18 text-gray-devil py-6px px-3 overflow-x-auto scrollbar scrollbar-thumb'>
          {isCustomDomainLoading ? (
            <Skeleton className='h-60 rounded-18' />
          ) : (
            <>
              <div>https://</div>
              <TextInput
                wrapperClassName='overflow-hidden grow'
                label={translate('domain.prefix.label')}
                name='subdomain'
                value={values.subdomain}
                clearField={() => setFieldValue('subdomain', '')}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                error={(touched.subdomain && errors.subdomain) || undefined}
                showErrorMessage={false}
                bgColor='#F0F0F0'
                bgColorHover='#F9F9F9'
              />
              <div>.</div>
              <div className='min-w-180px flex items-start flex-col ml-2 grow overflow-hidden bg-gray-concrete h-60px rounded-15 px-18px pt-2'>
                <Paragraph
                  colorClassName='text-gray-dark-charchoal'
                  size={paragraphSizes.tiny}
                  weight={fontWeights.medium}
                >
                  {translate('labels.domain')}
                </Paragraph>

                <ParagraphWithTooltip
                  colorClassName='text-gray-dark-charchoal'
                  className='!font-525'
                  weight={fontWeights.medium}
                >
                  {selectedDomain?.label}
                </ParagraphWithTooltip>
              </div>
              <Button
                onClick={() => copyUrl(domainUrl)}
                tooltip={
                  copyUrlSuccess ? translate('button.copiedToClipboard') : translate('button.copyToClipboardText')
                }
                color={'quaternary'}
                icon={() => (copyUrlSuccess ? <CheckmarkIcon color='white' /> : <CopyIcon color='#3C3C3C' />)}
                className={classNames(copyUrlSuccess && '!bg-green-pure')}
                data-testid={TEST_IDS.generic.buttons.copyToClipboard}
              />
              <Button
                onClick={() => {
                  window.open(domainUrl);
                }}
                color='quaternary'
                icon={() => <ExternalLinkIcon color='#3C3C3C' />}
                tooltip={translate('domain.goToWebsite')}
                data-testid={TEST_IDS.generic.buttons.goToWebsite}
              />
            </>
          )}
        </div>
        {<InputMessage error={domainError && translate(domainError)} />}
        <DomainSupportUrlComponent url={domainSupportUrl} />
        <Button
          type={isSaveButtonDisabled ? 'button' : 'submit'}
          disabled={isSaveButtonDisabled}
          text={translate('button.saveChanges')}
          className='w-full'
          data-testid={TEST_IDS.generic.buttons.submit}
        />
      </form>
    </>
  );
};

export default CustomSubdomainComponent;

const useCustomDomainsValidationSchema = () => {
  return object().shape({
    subdomain: string()
      .nullable()
      .required(validationTranslationKeys.required)
      .min(3, validationTranslationKeys.minChars)
      .max(20, validationTranslationKeys.maxChars)
      .notOneOf(NOT_ALLOWED_CUSTOM_DOMAINS, validationTranslationKeys.unavailableCustomDomain)
      .matches(/^[a-zA-Z0-9-_]*$/, validationTranslationKeys.invalidCustomDomainCharacters)
      .matches(/^[a-zA-Z0-9]/, validationTranslationKeys.invalidStartCharCustomDomain),
    domain: number(),
  });
};
