import { useSessionInfo } from '@va/dashboard/api-client/Visitors';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { VisitorInfo } from '@va/dashboard/shared/visitors';
import { useCheckPrivacySetting } from '@va/dashboard/util-hooks';
import { useLocalizationContext } from '@va/localization';
import { PRIVACY_FEATURES } from '@va/standalone/shared/types';
import { fontWeights, Paragraph, paragraphSizes } from '@va/ui/design-system';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';

export const SessionInfoTooltip = ({ sessionKey }: { sessionKey: string }) => {
  const { data, isLoading, error } = useSessionInfo(sessionKey);
  const { translate, locale } = useLocalizationContext();
  const websiteId = useSelector(getInstanceId);

  const { isTrackingEnabled } = useCheckPrivacySetting();

  if ((!isLoading && !data) || error) {
    return translate('notifications.generalError');
  }

  const isPageHistoryTrackingEnabled =
    data && isTrackingEnabled(PRIVACY_FEATURES.individualPageHistory, data.countryCode);

  return (
    <div>
      {isLoading ? (
        <Skeleton className='min-w-[220px] h-[77px]' baseColor='transparent' highlightColor='#000000D6' />
      ) : (
        data && (
          <VisitorInfo
            browserName={data.browserName}
            countryCode={data.countryCode}
            deviceType={data.deviceType}
            pagesVisited={data.pagesVisited}
            locale={locale}
            translate={translate}
            platform={data.platform}
            sessionKey={sessionKey}
            timestamp={data.timestamp}
            visitorType={data.status}
            isPageHistoryTrackingEnabled={isPageHistoryTrackingEnabled}
            websiteId={websiteId}
          />
        )
      )}
      <a href='https://www.maxmind.com/en/geoip-accuracy-comparison' target='_blank' rel='noreferrer'>
        <Paragraph
          size={paragraphSizes.tiny}
          className='text-center !mt-1'
          colorClassName='text-purple-french-lilac'
          weight={fontWeights.medium}
        >
          {translate('visitorsMap.pinsAccuracy')}
        </Paragraph>
      </a>
    </div>
  );
};
