import { CommonFilterParams, Filter, FilterOperators, FilterTypes } from '@va/types/filters';
import { forwardRef } from 'react';
import { BooleanFilterInputApplied } from './BooleanFilterInputApplied';

type BooleanFilterParams = CommonFilterParams<boolean, {}>;

export class BooleanFilter extends Filter<boolean, {}> {
  constructor(params: BooleanFilterParams) {
    super({
      ...params,
      operator: params.operator || FilterOperators.is,
      input: forwardRef(() => null),
      appliedInput: BooleanFilterInputApplied,
      type: FilterTypes.Boolean,
    });
  }
}
