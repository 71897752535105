import ConsentCookieBannerImage from '@va/images/cookie-banners/consent-cookie-banner.svg?react';
import { useTranslate } from '@va/localization';
import { fontWeights, Heading5, InfoBanner, Paragraph } from '@va/ui/design-system';
import { ConsentTrackingCodeSection } from './ConsentTrackingCodeSection';

export const ConsentTrackingCode = () => {
  const translate = useTranslate();

  return (
    <div className='flex flex-col gap-3 sm:mt-3 mt-18px'>
      <div className='rounded-24 bg-primary'>
        <div className='w-full h-full bg-white-96 sm:p-18px md:p-18px lg:p-6 flex gap-3'>
          <ConsentCookieBannerImage className='sm:hidden md:hidden lg:block shrink-0' />
          <div className='overflow-hidden basis-80 grow'>
            <Heading5 weight={fontWeights.semibold}>
              {translate('trackingCode.installation.consent.infoCard.title')}
            </Heading5>
            <Paragraph className='mb-1.5' weight={fontWeights.medium}>
              {translate('trackingCode.installation.consent.infoCard.subtitle')}
            </Paragraph>
            <InfoBanner text={translate('trackingCode.installation.consent.banner')} />
          </div>
        </div>
      </div>
      <ConsentTrackingCodeSection />
    </div>
  );
};
