import { PlanIndexes, websiteFlagKeys } from '@va/constants';
import { useLeftVisitsCount } from '@va/dashboard/api-client/FeatureCards';
import { PlanType, usePaymentPlans } from '@va/dashboard/api-client/pricing-plans.api';
import { updateWebsiteFrontendFlags } from '@va/dashboard/api-client/Settings';
import { getInstanceId, getUnlimitedVoucher } from '@va/dashboard/selectors/app';
import { getLastSeenFreeTrialModal } from '@va/dashboard/selectors/core';
import { useLocalizationContext } from '@va/localization';
import { setUpgradePlanIndex, setUpgradePlanSubscriptionType } from '@va/standalone/shared/actions';
import { SubscriptionTypeEnum } from '@va/standalone/shared/constants';
import { appHistory } from '@va/standalone/shared/router';
import {
  Button,
  fontWeights,
  Heading3,
  Paragraph,
  paragraphSizes,
  TabbedButton,
  TabbedButtonProps,
} from '@va/ui/design-system';
import { ModalWrapper } from '@va/util/components';
import { addNumberSeparator } from '@va/util/helpers';
import dayjs from 'dayjs';
import { clamp } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { PricingPlanTile } from './PricingPlanTile';

const daysLeftToShowModal = [15, 5, 2, 1, 0];

export const FreeTrialEndingModal = () => {
  const unlimitedVoucher = useSelector(getUnlimitedVoucher);
  const lastSeenModal = useSelector(getLastSeenFreeTrialModal);

  const daysLeft = useMemo(
    () =>
      unlimitedVoucher && unlimitedVoucher.voucherCode
        ? dayjs.unix(unlimitedVoucher.expiresAt).diff(dayjs(), 'day')
        : null,
    [unlimitedVoucher],
  );

  const isOpen = useMemo(
    () => daysLeftToShowModal.some((day) => day === daysLeft && lastSeenModal !== day),
    [daysLeft, lastSeenModal],
  );

  if (!isOpen || daysLeft === null) return null;

  return <FreeTrialEndingModalContent daysLeft={daysLeft} />;
};

const FreeTrialEndingModalContent = ({ daysLeft }: { daysLeft: number }) => {
  const dispatch = useDispatch();
  const websiteId = useSelector(getInstanceId);
  const { locale, translate } = useLocalizationContext();

  const [isOpen, setIsOpen] = useState(true);
  const [subscriptionType, setSubscriptionType] = useState(SubscriptionTypeEnum.YEARLY);

  const { data: paymentPlans, isLoading: isPlansLoading } = usePaymentPlans(true);
  const { data: visitsCount, isLoading: isVisitsCountLoading } = useLeftVisitsCount();

  const isLoading = isPlansLoading || isVisitsCountLoading;
  const daysPassed = useMemo(() => clamp(30 - daysLeft, 1, 30), [daysLeft]);

  const tabs = useMemo<TabbedButtonProps['buttonOptions']>(
    () => [
      {
        value: SubscriptionTypeEnum.MONTHLY,
        label: translate('modals.freeTrialEnding.tabs.monthly'),
        disabled: isLoading,
      },
      {
        value: SubscriptionTypeEnum.YEARLY,
        label: translate('modals.freeTrialEnding.tabs.yearly'),
        disabled: isLoading,
      },
    ],
    [translate, isLoading],
  );

  const { recommendedPlan, otherPlans } = useMemo(() => {
    if (visitsCount === undefined || !paymentPlans || daysLeft === null)
      return { recommendedPlan: null, otherPlans: [] };

    const estimatedVisitsPerMonth = visitsCount * (30 / daysPassed);
    const plans = paymentPlans[subscriptionType];

    const recommendedPlan =
      Object.values(plans).find(
        (plan: PlanType) => plan.index !== PlanIndexes.Free && estimatedVisitsPerMonth <= plan.limit,
      ) ?? plans[PlanIndexes.ProPlus];

    const otherPlans = getRelevantPlans(recommendedPlan, plans);

    return {
      recommendedPlan,
      otherPlans,
    };
  }, [visitsCount, paymentPlans, daysLeft, daysPassed, subscriptionType]);

  const [selectedPlanIndex, setSelectedPlanIndex] = useState(recommendedPlan?.index);

  const closeModal = useCallback(async () => {
    try {
      await updateWebsiteFrontendFlags(websiteId, { [websiteFlagKeys.LAST_SEEN_FREE_TRIAL_MODAL]: daysLeft });
    } catch (e) {
      console.error(e);
    } finally {
      setIsOpen(false);
    }
  }, [daysLeft, websiteId]);

  const handleSubmit = useCallback(async () => {
    dispatch(setUpgradePlanIndex(selectedPlanIndex));
    dispatch(setUpgradePlanSubscriptionType(subscriptionType));

    await closeModal();

    appHistory.push(`/website/${websiteId}/upgrade`);
  }, [closeModal, dispatch, selectedPlanIndex, subscriptionType, websiteId]);

  useEffect(() => {
    if (selectedPlanIndex !== undefined || recommendedPlan === null) return;

    setSelectedPlanIndex(recommendedPlan.index);
  }, [recommendedPlan, selectedPlanIndex]);

  const getTitle = () => {
    if (daysLeft <= 0) return translate('modals.freeTrialEnding.title.ended');
    if (daysLeft === 1) return translate('modals.freeTrialEnding.title.oneDay');

    return translate('modals.freeTrialEnding.title.daysCount', { count: daysLeft });
  };

  return (
    <ModalWrapper isModalOpen={isOpen} closeModal={closeModal} contentClassName='sm:rounded-24 rounded-30 sm:w-full'>
      <div className='max-w-[720px] sm:p-3 sm:pt-18px p-18px pt-6 overflow-hidden'>
        <Heading3 className='text-center text-wrap' weight={fontWeights.semibold}>
          {getTitle()}
        </Heading3>
        <Paragraph className='text-center' weight={fontWeights.medium}>
          {translate('modals.freeTrialEnding.pastDays', { count: daysPassed })}&nbsp;
          <span className='text-primary font-semibold'>
            {translate('modals.freeTrialEnding.pageViewsCount', {
              count: addNumberSeparator(visitsCount, locale),
            })}
          </span>
        </Paragraph>
        <TabbedButton
          className='my-3 p-[1.5px] !h-12'
          buttonOptions={tabs}
          onChange={setSubscriptionType}
          selectedValue={subscriptionType}
        />
        {isLoading && !recommendedPlan && (
          <Skeleton
            className='block rounded-12'
            containerClassName='block w-full mb-1.5'
            inline
            count={1}
            height={90}
          />
        )}
        {recommendedPlan && (
          <PricingPlanTile
            selected={selectedPlanIndex === recommendedPlan.index}
            viewsAmount={recommendedPlan.limit}
            title={recommendedPlan.name}
            onClick={() => setSelectedPlanIndex(recommendedPlan.index)}
            className='mb-1.5'
            price={recommendedPlan.monthlyPrice}
            recommended
          />
        )}
        <div className='flex sm:flex-col gap-1.5 overflow-hidden mb-3'>
          {isLoading && !otherPlans.length && (
            <Skeleton
              className='block rounded-12'
              containerClassName='block flex gap-1.5 w-[540px]'
              count={2}
              height={82}
              inline
            />
          )}
          {otherPlans.map((plan, index) => (
            <PricingPlanTile
              key={index}
              selected={plan.index === selectedPlanIndex}
              viewsAmount={plan.limit}
              onClick={() => setSelectedPlanIndex(plan.index)}
              title={plan.name}
              price={plan.monthlyPrice}
            />
          ))}
          <Button
            className='rounded-18'
            textClasses='text-wrap'
            text={translate('modals.freeTrialEnding.allPlansBtn')}
            color='secondary'
            onClick={async () => {
              await closeModal();

              appHistory.push(`/website/${websiteId}/upgrade`);
            }}
            disabled={isLoading}
          />
        </div>
        <div className='px-18px py-3 bg-gray-wild-sand rounded-12'>
          <Paragraph size={paragraphSizes.tiny}>
            <span className='font-semibold'>{translate('modals.freeTrialEnding.hint')}</span>&nbsp;
            {translate('modals.freeTrialEnding.hintText')}
          </Paragraph>
        </div>
        <div className='flex gap-3 mt-3'>
          <Button
            className='flex-1'
            color='quinary'
            text={translate('modals.freeTrialEnding.closeBtn')}
            onClick={closeModal}
          />
          <Button
            className='flex-1'
            text={translate('modals.freeTrialEnding.submitBtn')}
            disabled={isLoading}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};

const getRelevantPlans = (plan: PlanType, plans: Record<number, PlanType>) => {
  const relevantPlansMap: Record<number, PlanType[]> = {
    [PlanIndexes.ProPlus]: [plans[plan.index - 2], plans[plan.index - 1]],
    [PlanIndexes.Starter]: [plans[plan.index + 1], plans[plan.index + 2]],
  };

  return relevantPlansMap[plan.index] ?? [plans[plan.index - 1], plans[plan.index + 1]];
};
