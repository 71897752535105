import { PrivacyLockIcon } from '@va/icons';
import { PrivacyLevelsEnum } from '@va/standalone/shared/types';
import classNames from 'classnames';
import { useCallback } from 'react';

type DataPrivacyLevelIconProps = {
  selected: boolean;
  value: PrivacyLevelsEnum;
};

export const DataPrivacyLevelIcon = ({ selected, value }: DataPrivacyLevelIconProps) => {
  const levelIconColor = useCallback(
    (level: PrivacyLevelsEnum) => {
      if (selected) {
        if (value >= level) return 'var(--color-primary)';
        return 'var(--color-primary)';
      }
      if (value >= level) return 'var(--color-primary)';
      return '#FFFFFF';
    },
    [selected, value],
  );

  return (
    <div
      className={classNames('relative rounded-full flex justify-center items-center h-42px w-42px', {
        'bg-white': !selected,
        'bg-primary': selected,
      })}
    >
      <div className={classNames('grid grid-cols-2 w-full h-full gap-0.5', {})}>
        <div
          style={{ borderColor: levelIconColor(PrivacyLevelsEnum.completePrivacy) }}
          className='border-primary rounded-tl-full border-t-2.5 border-l-2.5'
        />
        <div
          style={{ borderColor: levelIconColor(PrivacyLevelsEnum.defaultPrivacy) }}
          className='border-primary rounded-tr-full border-t-2.5 border-r-2.5'
        />
        <div
          style={{ borderColor: levelIconColor(PrivacyLevelsEnum.GDPRMode) }}
          className='border-primary rounded-bl-full border-b-2.5 border-l-2.5'
        />
        <div
          style={{ borderColor: levelIconColor(PrivacyLevelsEnum.basicPrivacy) }}
          className='border-primary rounded-br-full border-b-2.5 border-r-2.5'
        />
      </div>
      <PrivacyLockIcon color={selected ? '#FFFFFF' : '#969696'} className='absolute' level={value} />
    </div>
  );
};
