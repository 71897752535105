import { initialize } from '@va/dashboard/actions/app';
import { TrackingManualCodeInstallation } from '@va/dashboard/feature/tracking-code';
import { isTrackingCodeInstalled as isTrackingCodeInstalledSelector } from '@va/dashboard/selectors/app';
import { OnboardingStep } from '@va/dashboard/shared/onboarding';
import { WeeblyIcon, WixIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button, fontWeights, GrayGradientDivider, Paragraph } from '@va/ui/design-system';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AdditionalSupportResources } from './AdditionalSupportResources';
import TrackingCodeInstalationResultView from './TrackingCodeInstalationResultView';
import InstallTrackingCodeContextProvider, {
  InstallTrackingCodeContext,
  useInstallTrackingCodeContext,
} from './useTrackingCodeInstallationView';

enum ViewEnum {
  installation = 'installation',
  installationResult = 'installationResult',
}

type TrackingCodeStepProps = {
  onError: () => void;
  onClickRetryInstallation?: () => void;
};

export const TrackingCodeStep = ({ onClickRetryInstallation, onError }: TrackingCodeStepProps) => {
  const dispatch = useDispatch();

  const onOpenDashboardClick = useCallback(() => {
    dispatch(initialize());
  }, [dispatch]);

  return (
    <InstallTrackingCodeContextProvider onError={onError}>
      <InstallTrackingCodeContext.Consumer>
        {({ view, setView }) => {
          return (
            <div>
              {view === ViewEnum.installation && <TrackingCodeInstallation />}
              {view === ViewEnum.installationResult && (
                <TrackingCodeInstalationResultView
                  onOpenDashboardClick={onOpenDashboardClick}
                  onClickRetryInstallation={() => {
                    onClickRetryInstallation?.();
                    setView(ViewEnum.installation);
                  }}
                  onBackButtonClick={() => setView(ViewEnum.installation)}
                />
              )}
            </div>
          );
        }}
      </InstallTrackingCodeContext.Consumer>
    </InstallTrackingCodeContextProvider>
  );
};

const TrackingCodeInstallation = () => {
  const translate = useTranslate();
  const { onSuccess, onError } = useInstallTrackingCodeContext();
  const isTrackingCodeInstalled = useSelector(isTrackingCodeInstalledSelector);

  return (
    <OnboardingStep className='!max-w-[840px]' subtitle={translate('onboarding.standalone.trackingCode.subtitle')}>
      <TrackingManualCodeInstallation onSuccess={onSuccess} />
      {!isTrackingCodeInstalled && (
        <Button
          onClick={() => onError()}
          className='mx-auto mt-3 md:min-w-[324px]'
          text={translate('onboarding.trackingCode.installLater')}
        />
      )}

      <AdditionalSupportResources />

      <div className='flex gap-30px items-center mt-18px mb-30px'>
        <GrayGradientDivider className='flex-1 rotate-180 h-1.5px' />
        <Paragraph colorClassName='text-black-dark opacity-[60%]' weight={fontWeights.semibold}>
          {translate('trackingCode.installation.method.or')}
        </Paragraph>
        <GrayGradientDivider className='flex-1 h-1.5px' />
      </div>

      <Button
        className='w-full justify-center'
        icon={() => <WixIcon />}
        color='tertiary'
        text={translate('onboarding.trackingCode.installPlugin.Wix')}
      />
      <Button
        className='w-full justify-center mt-15px'
        icon={() => <WeeblyIcon />}
        color='tertiary'
        text={translate('onboarding.trackingCode.installPlugin.Weebly')}
      />
    </OnboardingStep>
  );
};
