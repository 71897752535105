import { Button, fontWeights, Paragraph, paragraphSizes } from '@va/ui/design-system';
import classNames from 'classnames';

type TrackingCodeTabsProps = {
  tabs: { tabIndex: number; title: string; tooltip: string }[];
  activeTabIndex: number;
  onTabChange: (tabIndex: number) => void;
};

export const TrackingCodeTabs = ({ tabs, activeTabIndex, onTabChange }: TrackingCodeTabsProps) => {
  return (
    <div
      className={
        'w-full rounded-18 p-6px bg-white-snow min-h-60px h-auto mb-6px grid grid-cols-2 sm:grid-cols-1	gap-x-2'
      }
    >
      {tabs.map(({ tabIndex, title, tooltip }, index) => {
        const isTabActive = tabIndex === activeTabIndex;
        return (
          <Button
            textClasses={classNames('border-b border-dashed', {
              'border-gray-silver': isTabActive,
              'border-transparent': !isTabActive,
            })}
            key={index}
            text={title}
            tooltip={
              <div>
                <Paragraph size={paragraphSizes.large} weight={fontWeights.medium} className='text-center mb-2'>
                  {title}
                </Paragraph>
                <Paragraph size={paragraphSizes.tiny}>{tooltip}</Paragraph>
              </div>
            }
            tooltipProps={{
              trigger: 'click',
              disabled: !isTabActive,
              maxWidth: 'max-w-480px',
              tooltipClassNames: 'px-6 py-18px',
            }}
            color='quaternary'
            className={classNames(
              'h-full flex justify-center items-center rounded-12 active:focus:!ring-0',
              {
                'bg-white text-primary hover:bg-white focus:!bg-white': isTabActive,
              },
              {
                'bg-white-snow !text-gray-charcoal hover:!bg-white-smoke': !isTabActive,
              },
            )}
            onClick={() => onTabChange(tabIndex)}
          />
        );
      })}
    </div>
  );
};
