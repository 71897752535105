import { BLACK_COLOR } from './constants';

export function ArrowUp(rawProps: JSX.IntrinsicElements['svg']) {
  const { color = BLACK_COLOR, ...props } = rawProps;

  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 16 12'
      xmlSpace='preserve'
      {...props}
    >
      <polygon id='XMLID_80_' fill={color} points='13.2,9.4 8,4.2 2.8,9.4 2,8.6 8,2.6 14,8.6 ' />
    </svg>
  );
}

export function ArrowDown(rawProps: JSX.IntrinsicElements['svg']) {
  const { color = BLACK_COLOR, ...props } = rawProps;

  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 16 12'
      xmlSpace='preserve'
      {...props}
    >
      <polygon id='XMLID_80_' fill={color} points='13.2,2.6 8,7.8 2.8,2.6 2,3.4 8,9.4 14,3.4 ' />
    </svg>
  );
}

export function ArrowRight(rawProps: JSX.IntrinsicElements['svg']) {
  const { color = BLACK_COLOR, ...props } = rawProps;

  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 16 12'
      xmlSpace='preserve'
      {...props}
    >
      <polygon id='XMLID_80_' fill={color} points='4.6,11.2 9.8,6 4.6,0.8 5.4,0 11.4,6 5.4,12 ' />
    </svg>
  );
}

export function ArrowLeft(rawProps: JSX.IntrinsicElements['svg']) {
  const { color = BLACK_COLOR, ...props } = rawProps;

  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 16 12'
      xmlSpace='preserve'
      {...props}
    >
      <polygon id='XMLID_80_' fill={color} points='11.4,11.2 6.2,6 11.4,0.8 10.6,0 4.6,6 10.6,12 ' />
    </svg>
  );
}
