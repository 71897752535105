import { fontWeights, Heading3, MultiStepWizardStep, Paragraph, paragraphSizes } from '@va/ui/design-system';
import classNames from 'classnames';
import { CSSProperties, forwardRef } from 'react';
import './multi-step-wizard.scss';
import { StepStatusIcon } from './StepStatusIcon';

type StepsVisualizationProps = {
  steps: MultiStepWizardStep[];
  activeStep: number;
  onStepChange?: (step: number) => void;
  variant?: 'default' | 'wix-mini';
  style?: CSSProperties;
  itemWidth?: number;
  containerWidth?: number;
};

const styles = {
  default: {
    smallLabelStyle: 'mt-0',
  },
  'wix-mini': {
    smallLabelStyle: 'mt-8',
  },
};

export const StepsVisualization = forwardRef<HTMLDivElement, StepsVisualizationProps>(
  ({ activeStep, steps, onStepChange, variant = 'default', style, itemWidth, containerWidth }, ref) => {
    return (
      <div
        ref={ref}
        id='multi-step'
        className='flex flex-row flex-nowrap gap-1 transition-transform duration-500'
        style={style}
      >
        {steps.map((step, index) => {
          const { hasError, label, isFinalized, subInfo, smallLabel } = step;
          const isActive = activeStep === index;
          const isCompleted = activeStep > index || isFinalized;
          const canJumpToStep = activeStep > index && steps[index + 1]?.canReturn;
          const isLastStep = index === steps.length - 1;

          const shouldShowStepLabel = () => {
            if (!isActive) return false;
            return true;
          };

          const labelStyles = 'mx-2 pt-3 text-center break-words';
          const labelColorStyles = classNames({
            'text-text-secondary': !isActive && !isCompleted,
            '!text-primary': isActive,
            'text-primary': isCompleted,
            '!text-negative': hasError,
          });

          const handleStepChange = () => {
            if (canJumpToStep) {
              onStepChange?.(index);
            }
          };

          return (
            <div key={index} className='step flex flex-col items-start shrink-0' style={{ width: `${itemWidth}px` }}>
              <div className='w-full flex justify-center'>
                <StepStatusIcon
                  className={classNames({
                    'cursor-pointer': canJumpToStep,
                  })}
                  index={index}
                  hasError={!!hasError}
                  isActive={isActive}
                  isCompleted={!!isCompleted}
                  onClick={handleStepChange}
                />
                <svg className={classNames('my-auto mx-2', { invisible: isLastStep })} width={itemWidth} height={2}>
                  <line
                    x1='0'
                    y1='0'
                    strokeWidth={2}
                    x2={itemWidth}
                    y2='0'
                    stroke='black'
                    opacity={0.12}
                    strokeDasharray='5'
                    strokeLinecap='round'
                  />
                </svg>
              </div>
              {shouldShowStepLabel() && (
                <div
                  className={classNames('flex flex-col mt-1.5 overflow-hidden', {
                    'cursor-pointer': canJumpToStep,
                  })}
                  // 24px is the width of the StepStatusIcon
                  style={{
                    transform: `translateX(calc(-50% + (24px / 2)))`,
                    width: containerWidth,
                  }}
                >
                  {smallLabel ? (
                    <Paragraph
                      className={classNames('text-center', styles[variant].smallLabelStyle)}
                      size={paragraphSizes.medium}
                      weight={fontWeights.medium}
                      onClick={handleStepChange}
                      colorClassName={labelColorStyles}
                    >
                      {label}
                    </Paragraph>
                  ) : (
                    <Heading3
                      className={classNames('text-center', labelStyles)}
                      weight={fontWeights.medium}
                      colorClassName={labelColorStyles}
                      onClick={handleStepChange}
                    >
                      {label}
                    </Heading3>
                  )}
                  {subInfo && isActive && (
                    <Paragraph weight={fontWeights.medium} className='mt-1' colorClassName={labelColorStyles}>
                      {subInfo}
                    </Paragraph>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  },
);
