import { apiRequestResponseMsg, defaultSsrSettings } from '@va/constants';
import { createSsrSettings } from '@va/dashboard/actions/api';
import { getSsrSettingsSelector } from '@va/dashboard/selectors/core';
import { useTrackingCodeStatusCard } from '@va/dashboard/shared/onboarding';
import { useTrackingCodeVerifier } from '@va/dashboard/util-hooks';
import { StarIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { getTrackingCode } from '@va/standalone/shared/actions';
import { fontWeights, InfoSectionItem, Paragraph, paragraphSizes } from '@va/ui/design-system';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TrackingCodeTabs } from '../TrackingCodeTabs';
import { ConsentTrackingCode } from './ConsentTrackingCode';
import { NoConsentTrackingCode } from './NoConsentTrackingCode';

type TrackingManualCodeInstallationProps = {
  onSuccess?: () => void;
};

const TrackingManualCodeInstallation = ({ onSuccess }: TrackingManualCodeInstallationProps) => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const ssrSettings = useSelector(getSsrSettingsSelector);

  const onTrackingCodeSuccess = useCallback(() => {
    if (ssrSettings?.error?.message === apiRequestResponseMsg.NOT_FOUND_SSR_SETTINGS) {
      dispatch(createSsrSettings(defaultSsrSettings));
    }
  }, [dispatch, ssrSettings?.error?.message]);

  const { verify: checkTrackingCode, status } = useTrackingCodeVerifier(onTrackingCodeSuccess);
  const handleCodeTrackerClick = useMemo(() => () => checkTrackingCode(), [checkTrackingCode]);
  const { actionButton, icon, title } = useTrackingCodeStatusCard({ status, handleCodeTrackerClick, onSuccess });

  useEffect(() => {
    dispatch(getTrackingCode());
  }, [dispatch]);

  const tabs = useMemo(
    () => [
      {
        tabIndex: 0,
        title: translate('trackingCode.installation.noConsentTab'),
        tooltip: translate('trackingCode.installation.noConsentTabTooltip'),
      },
      {
        tabIndex: 1,
        title: translate('trackingCode.installation.consentTab'),
        tooltip: translate('trackingCode.installation.consentTabTooltip'),
      },
    ],
    [translate],
  );

  return (
    <>
      <div className='my-3 w-full'>
        <div className='sm:w-full w-1/2 flex justify-center'>
          <div
            className={classNames(
              'flex gap-1.5 py-4.5px pl-1.5 pr-9px mb-1.5 items-center rounded-full bg-gradient-to-r from-primary-lighter-13 to-white w-fit',
              {
                'opacity-45': activeTabIndex === 1,
              },
            )}
          >
            <StarIcon color='var(--color-primary)' />
            <Paragraph colorClassName='text-primary' weight={fontWeights.medium} size={paragraphSizes.tiny}>
              {translate('trackingCode.installation.recommended')}
            </Paragraph>
          </div>
        </div>
        <TrackingCodeTabs tabs={tabs} activeTabIndex={activeTabIndex} onTabChange={setActiveTabIndex} />

        {activeTabIndex === 0 && <NoConsentTrackingCode />}
        {activeTabIndex === 1 && <ConsentTrackingCode />}
      </div>

      <InfoSectionItem
        className='rounded-12'
        label={translate('trackingCode.installation.trackingCodeStatus')}
        rightNode={actionButton}
        title={title}
        icon={icon}
      />
    </>
  );
};
export default TrackingManualCodeInstallation;
