import { ButtonLoader } from '@va/svg-visa-icons';
import { Button, InfoSectionItem } from '@va/ui/design-system';
import classNames from 'classnames';

type TrackingCodeStatusCardType = {
  codeTrackerIconClass: string;
  codeTrackerIcon: JSX.Element;
  codeTrackerStatus: string;
  handleCodeTrackerClick: () => void;
  isLoading: boolean;
  statusBtnText: string;
  title: string;
};
export const TrackingCodeStatusCard = (props: TrackingCodeStatusCardType) => {
  const {
    codeTrackerIconClass,
    codeTrackerIcon,
    codeTrackerStatus,
    handleCodeTrackerClick,
    isLoading,
    statusBtnText,
    title,
  } = props;
  return (
    <div className='rounded-18 w-full p-3 bg-white-snow flex flex-wrap items-center justify-between gap-y-3'>
      <InfoSectionItem
        icon={
          <div className={classNames('rounded-1/2 w-12 h-12 flex justify-center items-center', codeTrackerIconClass)}>
            {codeTrackerIcon}
          </div>
        }
        label={title}
        title={codeTrackerStatus}
        rightNode={
          <Button
            color='quinary'
            onClick={handleCodeTrackerClick}
            icon={() => (isLoading ? <ButtonLoader color={'var(--color-primary)'} /> : undefined)}
            disabled={isLoading}
            text={statusBtnText}
          />
        }
      />
    </div>
  );
};
