import L, { LatLngBounds } from 'leaflet';
import { useCallback, useMemo, useRef } from 'react';
import { Circle, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import './markercluster.d.ts';
import { SessionInfoTooltip } from './SessionInfoTooltip';
import { LeafletMapPoint } from './types';
import { checkCommaPosForCluster, processBigNumber } from './utils';

const MAX_CLUSTER_CHILDREN = 500;

type CustomMarkerClusterGroupProps = {
  points: LeafletMapPoint[];
  circleRadius: number;
};

const markerIcon = L.icon({
  iconUrl: '/marker-icon.png',
  iconSize: [19, 35],
});

const CustomMarkerClusterGroup = ({ points, circleRadius }: CustomMarkerClusterGroupProps) => {
  const markersCount = useRef<Record<string, number>>({});

  const markers = useMemo(() => {
    const output: JSX.Element[] = [];

    for (let i = points.length - 1; i >= 0; --i) {
      const point = points[i];
      const key = `${point.lat}_${point.lng}`;
      markersCount.current[key] = (markersCount.current[key] ?? 0) + 1;

      if (markersCount.current[key] > MAX_CLUSTER_CHILDREN) continue;

      if (point.isPrivate) {
        output.push(
          <Circle
            className='max-privacy-map-circle'
            key={`${point.sessionKey}_${i}`}
            center={[point.lat, point.lng]}
            radius={circleRadius}
          />,
        );
      } else {
        output.push(
          <Marker key={`${point.sessionKey}_${i}`} position={[point.lat, point.lng]} icon={markerIcon}>
            <Popup className='session-info-tooltip' minWidth={244} maxWidth={580}>
              <SessionInfoTooltip sessionKey={point.sessionKey} />
            </Popup>
          </Marker>,
        );
      }
    }

    return output;
  }, [circleRadius, points]);

  const createClusterCustomIcon = useCallback((cluster: any) => {
    let clusterCount = 0;
    const clusterBounds: LatLngBounds = cluster.getBounds();

    Object.entries(markersCount.current).forEach(([key, value]) => {
      const [lat, lng] = key.split('_').map(Number);
      if (clusterBounds.contains({ lat, lng })) {
        clusterCount += value;
      }
    });

    return L.divIcon({
      html: `<div><span>${processBigNumber(clusterCount, checkCommaPosForCluster)}</span></div>`,
      className: 'marker-cluster marker-cluster-small',
      iconSize: L.point(40, 40, true),
    });
  }, []);

  return (
    <MarkerClusterGroup
      maxClusterRadius={50}
      removeOutsideVisibleBounds={true}
      iconCreateFunction={createClusterCustomIcon}
    >
      {markers}
    </MarkerClusterGroup>
  );
};

export default CustomMarkerClusterGroup;
