export const START_CHECK_PASSWORD_FLOW = 'APP_START_CHECK_PASSWORD_FLOW';
export const END_CHECK_PASSWORD_FLOW = 'APP_END_CHECK_PASSWORD_FLOW';
export const START_CHANGE_EMAIL_FLOW = 'APP_START_CHANGE_EMAIL_FLOW';
export const START_DELETE_ACCOUNT_FLOW = 'APP_START_DELETE_ACCOUNT_FLOW';

export const START_LEAVE_WEBSITE_FLOW = 'APP_START_LEAVE_WEBSITE_FLOW';
export const START_CONFIRM_CONTRIBUTOR_FLOW = 'APP_START_CONFIRM_CONTRIBUTOR_FLOW';
export const START_CANCEL_SUBSCRIPTION_FLOW = 'APP_START_CANCEL_SUBSCRIPTION_FLOW';
export const START_DELETE_SUBSCRIPTION_FLOW = 'APP_START_DELETE_SUBSCRIPTION_FLOW';

export const INITIALIZE = 'APP_INITIALIZE';
export const INITIALIZE_SUCCEEDED = 'APP_INITIALIZE_SUCCEEDED';

export const RESET_STORE = 'APP_RESET_STORE';
export const SET_ACTIVE_WEBSITE = 'APP_SET_ACTIVE_WEBSITE';
export const SET_ACTIVE_WEBSITE_SUCCEEDED = 'APP_SET_ACTIVE_WEBSITE_SUCCEEDED';
export const SET_BRAINTREE_INSTANCE_LOADED = 'APP_SET_BRAINTREE_INSTANCE_LOADED';

export const SET_APPSUMO_STEP = 'APP_SET_APPSUMO_STEP';
export const SET_APPSUMO_USER_ID = 'APP_SET_APPSUMO_USER_ID';
export const SET_VOUCHERS_LIST = 'APP_SET_VOUCHERS_LIST';
export const UPDATE_VOUCHERS_LIST = 'APP_UPDATE_VOUCHERS_LIST';
export const AUTH_EXTERNAL = 'APP_AUTH_EXTERNAL';

export const REFRESH_SUBSCRIPTION_DETAILS = 'APP_REFRESH_SUBSCRIPTION_DETAILS';

export const SET_PADDLE_CALLBACK_EVENT = 'APP_SET_PADDLE_CALLBACK_EVENT';

export const ADD_AGENCY_CUSTOM_THEME = 'ADD_AGENCY_CUSTOM_THEME';
export const START_CREATE_AGENCY_FLOW = 'START_CREATE_AGENCY_FLOW';

export const SET_ONBOARDING_STEP = 'SET_ONBOARDING_STEP';

export const SET_WEBSITE_CREATION_STEP = 'SET_WEBSITE_CREATION_STEP';

export const SET_CHATWOOT_ATTRIBUTES = 'SET_CHATWOOT_ATTRIBUTES';

export const SET_UPGRADE_PLAN_STEP = 'SET_UPGRADE_PLAN_STEP';
export const SET_UPGRADE_PLAN_INDEX = 'SET_UPGRADE_PLAN_INDEX';
export const RESET_UPGRADE_PLAN_INDEX = 'RESET_UPGRADE_PLAN_INDEX';
export const SET_UPGRADE_PLAN_SUBSCRIPTION_TYPE = 'SET_UPGRADE_PLAN_SUBSCRIPTION_TYPE';
