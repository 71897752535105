import { apiGateway } from '@va/api-client';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { get, patch, post, put, remove } from '@va/http-client';
import { getAccessToken } from '@va/standalone/shared/helpers';
import { CreateHeatmapPayloadType, DeviceType, HeatmapType, MouseEventType } from '@va/types/heatmap';
import { useAsyncFunction, useFetchData } from '@va/util/hooks';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

type Image = {
  image: string;
};

const loadImage = (url: string) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      resolve(image);
    };
    image.onerror = reject;
    image.src = 'data:image/png;base64, ' + url;
  });
};

export const activateHeatmapsFeature = (websiteId: string) => {
  const url = `/websites/${websiteId}/heatmaps-activate`;
  return post(url, {}, {});
};

export const getHeatmapForegroundUrl = (
  websiteId: string,
  heatmapId: string,
  deviceType: DeviceType,
  interactionType: MouseEventType,
) => {
  return `/websites/${websiteId}/heatmaps/${heatmapId}/devices/${deviceType}/foreground/interactions/${interactionType}`;
};

export const getHeatmapBackgroundUrl = (websiteId: string, heatmapId: string, deviceType: DeviceType) => {
  return `/websites/${websiteId}/heatmaps/${heatmapId}/devices/${deviceType}/background`;
};

export const useHeatmapImage = (url: string) => {
  const fetcher = useCallback(async (url: string) => {
    const response: Image = await get(url, {});
    if (!response.image) {
      throw new Error('MISSING_IMAGE');
    }
    return loadImage(response.image);
  }, []);

  const { data, error, isLoading } = useFetchData(url, {}, undefined, fetcher);

  return {
    image: data,
    error,
    inProgress: isLoading,
  };
};

export const useHeatmapForeground = (
  websiteId: string,
  heatmapId: string,
  deviceType: DeviceType,
  interactionType: MouseEventType,
) => {
  const url = useMemo(
    () => getHeatmapForegroundUrl(websiteId, heatmapId, deviceType, interactionType),
    [deviceType, heatmapId, interactionType, websiteId],
  );

  return useHeatmapImage(url);
};

export const useHeatmapBackground = (websiteId: string, heatmapId: string, deviceType: DeviceType) => {
  const url = useMemo(
    () => getHeatmapBackgroundUrl(websiteId, heatmapId, deviceType),
    [deviceType, heatmapId, websiteId],
  );
  return useHeatmapImage(url);
};

export const retakeHeatmapBackground = (websiteId: string) => {
  const url = `/websites/${websiteId}/ss-retake`;
  return post(url, {}, {});
};

export const useHeatmaps = (websiteId: string) => {
  return useFetchData<HeatmapType[], Error>(`/websites/${websiteId}/heatmaps`, {
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateIfStale: true,
  });
};

export const deleteHeatmaps = (websiteId: string, data: {}) => {
  const url = `/websites/${websiteId}/heatmaps/delete`;
  return remove(url, {}, data);
};

export const createHeatmap = (websiteId: string, data: {}) => {
  const url = `/websites/${websiteId}/heatmaps`;
  return post(url, {}, data);
};

export const updateHeatmap = (websiteId: string, heatmapId: string, data: {}) => {
  const url = `/websites/${websiteId}/heatmaps/${heatmapId}`;
  return patch(url, {}, data);
};

export const resetHeatmaps = (websiteId: string, data: {}) => {
  const url = `/websites/${websiteId}/heatmaps/reset`;
  return post(url, {}, data);
};

export const toggleHeatmapsStatus = (websiteId: string, heatmapIds: string[], statusPaused: boolean) => {
  const url = `/websites/${websiteId}/heatmaps/pause`;
  const data = { heatmapIds: heatmapIds, paused: statusPaused };
  return put(url, {}, data);
};

export const useCreateHeatmap = () => {
  const websiteId = useSelector(getInstanceId);
  const createHeatmap = useCallback(
    (data: CreateHeatmapPayloadType) => {
      return post(`/websites/${websiteId}/heatmaps`, {}, data);
    },
    [websiteId],
  );
  return useAsyncFunction<typeof createHeatmap>(createHeatmap, undefined);
};

export const usePagesWithHeatmaps = () => {
  const websiteId = useSelector(getInstanceId);
  const url = useMemo(() => `/api/websites/${websiteId}/heatmaps/unique-pages`, [websiteId]);
  const fetcher = useCallback(
    () =>
      apiGateway.get<string[]>(url, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }),
    [url],
  );

  return useFetchData<string[]>(
    [{ url }],
    {
      revalidateOnMount: true,
    },
    undefined,
    fetcher,
  );
};
