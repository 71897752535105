import classNames from 'classnames';

export const GradientCogIcon = ({
  color = 'url(#paint0_linear_4286_240972)',
  className,
  animated = false,
  ...props
}: { animated?: boolean } & JSX.IntrinsicElements['svg']) => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classNames({ 'spinning-icon': animated }, className)}
      {...props}
    >
      <path
        opacity='0.25'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20 27.2C23.9765 27.2 27.2 23.9765 27.2 20C27.2 16.0236 23.9765 12.8 20 12.8C16.0236 12.8 12.8 16.0236 12.8 20C12.8 23.9765 16.0236 27.2 20 27.2ZM20 29C24.9706 29 29 24.9706 29 20C29 15.0294 24.9706 11 20 11C15.0294 11 11 15.0294 11 20C11 24.9706 15.0294 29 20 29Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.4002 4.87659C25.4002 5.64633 26.3308 6.03182 26.8751 5.48753L30.1825 2.18013L37.8193 9.81688L34.5119 13.1243C33.9676 13.6686 34.3531 14.5992 35.1228 14.5992H39.8002V25.3992H35.1228C34.3531 25.3992 33.9676 26.3299 34.5119 26.8742L37.8193 30.1816L30.1825 37.8183L26.8751 34.5109C26.3308 33.9666 25.4002 34.3521 25.4002 35.1219V39.7992H14.6002V35.1218C14.6002 34.3521 13.6695 33.9666 13.1253 34.5109L9.81786 37.8183L2.1811 30.1816L5.4885 26.8742C6.03279 26.3299 5.64731 25.3992 4.87757 25.3992H0.200195V14.5992H4.87756C5.6473 14.5992 6.03279 13.6686 5.4885 13.1243L2.1811 9.81688L9.81786 2.18013L13.1253 5.48753C13.6695 6.03182 14.6002 5.64633 14.6002 4.87659V0.199219H25.4002V4.87659ZM29.4207 8.03311L30.1825 7.2713L32.7281 9.81688L31.9663 10.5787C29.1541 13.3909 31.1458 18.1992 35.1228 18.1992H36.2002V21.7992H35.1228C31.1458 21.7992 29.1541 26.6076 31.9663 29.4197L32.7281 30.1816L30.1825 32.7271L29.4207 31.9653C26.6085 29.1531 21.8002 31.1449 21.8002 35.1219V36.1992H18.2002V35.1218C18.2002 31.1448 13.3918 29.1532 10.5797 31.9653L9.81786 32.7271L7.27227 30.1816L8.03409 29.4197C10.8463 26.6076 8.85457 21.7992 4.87757 21.7992H3.8002V18.1992H4.87756C8.85457 18.1992 10.8462 13.3908 8.03408 10.5787L7.27227 9.81688L9.81786 7.2713L10.5797 8.03311C13.3918 10.8453 18.2002 8.85361 18.2002 4.87659V3.79922H21.8002V4.87659C21.8002 8.85358 26.6086 10.8453 29.4207 8.03311ZM20.0002 25.3992C22.9825 25.3992 25.4002 22.9816 25.4002 19.9992C25.4002 17.0169 22.9825 14.5992 20.0002 14.5992C17.0179 14.5992 14.6002 17.0169 14.6002 19.9992C14.6002 22.9816 17.0179 25.3992 20.0002 25.3992Z'
        fill={color}
      />
      <defs>
        <linearGradient
          id='paint0_linear_4286_240972'
          x1='11'
          y1='20.0566'
          x2='29'
          y2='20.0566'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF6A00' />
          <stop offset='1' stopColor='#6B11C5' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_4286_240972'
          x1='0.200195'
          y1='20.1238'
          x2='39.8002'
          y2='20.1238'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF6A00' />
          <stop offset='1' stopColor='#6B11C5' />
        </linearGradient>
      </defs>
    </svg>
  );
};
