import {
  defaultTimezone,
  defaultWebsiteId,
  NO_PLATFORM,
  ONE_DAY,
  ONE_HOUR,
  pathName,
  PENDING_CONTRIBUTOR_REQUEST_USER_ROLES,
  platformsByCode,
  platformsById,
} from '@va/constants';
import * as Icons from '@va/icons';
import * as Types from '@va/standalone/shared/actions';
import {
  MAX_TOP_BAR_APPLY_LIFETIME_BADGE_WIDTH,
  MAX_TOP_BAR_APPLY_LIFETIME_BADGE_WITH_TRACKING_ERROR_WIDTH,
  MAX_TOP_BAR_TRACKING_CODE_ERROR_WIDTH,
  MAX_TOP_BAR_UPGRADE_BADGE_WIDTH,
  storageItems,
} from '@va/standalone/shared/constants';
import { appHistory } from '@va/shared/router';
import { ActiveSubscriptionType } from '@va/standalone/shared/types';
import { UserRole, WebsiteResponse } from '@va/types/website';
import { capitalizeFirstLetter, getPlatformName, LocalStorage, removeProtocol } from '@va/util/helpers';
import md5 from 'blueimp-md5';
import Config from 'Config';
import flagsmith from 'flagsmith';
import { isNumber } from 'lodash';
import map from 'lodash/map';
import moment from 'moment-timezone';

export function isAccountSettingsPageSelected(path?: string) {
  return (
    [
      pathName.accountSettings,
      pathName.oldAccountSettings,
      pathName.manageWebsites,
      pathName.agencyCommission,
      pathName.agencyWhiteLabel,
      pathName.billing,
      pathName.consent,
      pathName.whiteLabelCustomization,
      pathName.addWebsite,
    ].indexOf(path || window.location.pathname) >= 0
  );
}

export function getLastWebsiteId() {
  const lastWebsiteId = LocalStorage.getItem(storageItems.lastWebsiteId);
  return lastWebsiteId && lastWebsiteId !== defaultWebsiteId ? lastWebsiteId : null;
}

export function getAccessToken() {
  return LocalStorage.getItem(storageItems.loginToken);
}

export function getUserId() {
  return LocalStorage.getItem(storageItems.userId);
}

export function getAccessTokenExpiry() {
  return LocalStorage.getItem(storageItems.loginTokenExpiry);
}

export function getRefreshToken() {
  return LocalStorage.getItem(storageItems.refreshToken);
}

export function getImpersonateToken() {
  return LocalStorage.getItem(storageItems.impersonateToken);
}

export function getAccountType() {
  return LocalStorage.getItem(storageItems.accountType);
}

export function isAccessTokenExpired() {
  //TODO for testing purposes, generate refresh token every 150 seconds. Change for the real env
  // console.error(moment().unix() - getAccessTokenExpiry() + 3450);
  // return moment().unix() > getAccessTokenExpiry() - 3450;
  return moment().unix() > Number(getAccessTokenExpiry());
}

export function handleExpiredRefreshToken(pathName: string) {
  removeCredentialsFromLocalStorage();
  appHistory.push(pathName || '/login', {});
}

export function shouldRefreshToken() {
  return getAccessToken() && isAccessTokenExpired();
}

export function removeCredentialsFromLocalStorage() {
  sessionStorage.clear();
  localStorage.clear();
}

export function addCredentialsToLocalStorage(
  data: {
    userId: string;
    token: string;
    refreshToken: string;
    impersonate?: boolean;
    accountType?: string;
  },
  actionType?: string,
) {
  addTokensToLocalStorage({ token: data.token, refreshToken: data.refreshToken });

  LocalStorage.setItem(storageItems.userId, data.userId);
  if (actionType && actionType === Types.standalone.Api.GENERATE_IMPERSONATE_TOKEN_REQUEST)
    LocalStorage.setItem(storageItems.impersonateToken, 'true');
  if (data.impersonate) LocalStorage.setItem(storageItems.impersonate, data.impersonate.toString());
  if (data.accountType) {
    LocalStorage.setItem(storageItems.accountType, data.accountType);
  }
  flagsmith.identify(data.userId, { platform: getPlatformName() });
}

export function addTokensToLocalStorage({ token, refreshToken }: { token: string; refreshToken: string }) {
  const now = moment().unix();

  //login token is valid for an hour
  LocalStorage.setItem(storageItems.loginTokenExpiry, (now + ONE_HOUR).toString());
  LocalStorage.setItem(storageItems.loginToken, token);

  //refresh token is valid for 24 hours
  LocalStorage.setItem(storageItems.refreshTokenExpiry, (now + ONE_DAY).toString());
  LocalStorage.setItem(storageItems.refreshToken, refreshToken);
}

export const mapPlatform = (code: string) =>
  platformsByCode[code] !== undefined ? platformsByCode[code].id : NO_PLATFORM.id;
export const getPlatformNameById = (id: number) =>
  platformsById[id && id.toString()] !== undefined ? platformsById[id.toString()].name : NO_PLATFORM.name;

export function getRouteWithoutWebsiteIdPrefix(path: string) {
  const pathArr = path.split('/');
  if (pathArr[1] === 'website') {
    pathArr.splice(0, 3);
    return '/' + pathArr.join('/');
  }

  return path;
}

export function getBaseRouteFromHistoryRoute(historyRoute: string) {
  switch (historyRoute) {
    case '/visitors/history':
      return '/visitors/latest';
    case '/campaigns/history':
      return '/campaigns/latest';
    default:
      return null;
  }
}

export function getNoOfDaysUntil(endAt = moment().add(30, 'days').unix(), timezone = defaultTimezone) {
  const endDate = moment(endAt * 1000).tz(timezone);
  const daysLeft = endDate.diff(moment().startOf('day').tz(timezone), 'days');

  return Math.max(daysLeft, 0);
}

export function getNextBillingDate(activeSubscription: ActiveSubscriptionType, timezone = defaultTimezone) {
  if (isNumber(activeSubscription.nextBillingDate)) {
    return moment.unix(activeSubscription.nextBillingDate).tz(timezone).format('L');
  }

  return '';
}

export function getActiveUntilDate(activeSubscription: ActiveSubscriptionType, timezone = defaultTimezone) {
  // TODO
  const untilDate =
    activeSubscription &&
    // @ts-ignore
    (activeSubscription.activeUntil ||
      activeSubscription.isActiveUntil ||
      // @ts-ignore
      (activeSubscription.get && activeSubscription.get('activeUntil')));
  if (untilDate) {
    return moment.unix(untilDate).tz(timezone).format('L');
  }

  return '';
}

export function getLifeTimeDealTopBarMaxWidth(
  isSumoUser: boolean,
  hasAppliedLifeTimeDeal: boolean,
  leftMenuExpanded: boolean,
  hasTrackingCodeError: boolean,
) {
  const EXPANDED_MENU_WIDTH_GAP = leftMenuExpanded ? 170 : 0;
  if (isSumoUser) {
    let maxWidth = hasTrackingCodeError
      ? MAX_TOP_BAR_TRACKING_CODE_ERROR_WIDTH
      : hasAppliedLifeTimeDeal
      ? MAX_TOP_BAR_UPGRADE_BADGE_WIDTH
      : MAX_TOP_BAR_UPGRADE_BADGE_WIDTH + 245;

    maxWidth = hasTrackingCodeError && !hasAppliedLifeTimeDeal ? maxWidth + 245 : maxWidth;
    return maxWidth + EXPANDED_MENU_WIDTH_GAP;
  }

  return hasTrackingCodeError
    ? MAX_TOP_BAR_TRACKING_CODE_ERROR_WIDTH + EXPANDED_MENU_WIDTH_GAP
    : MAX_TOP_BAR_UPGRADE_BADGE_WIDTH + EXPANDED_MENU_WIDTH_GAP;
}

export function getLifeTimeBadgeTopBarMaxWidth(hasTrackingCodeError: boolean) {
  const EXPANDED_MENU_WIDTH_GAP = 0;
  return hasTrackingCodeError
    ? MAX_TOP_BAR_APPLY_LIFETIME_BADGE_WITH_TRACKING_ERROR_WIDTH + EXPANDED_MENU_WIDTH_GAP
    : MAX_TOP_BAR_APPLY_LIFETIME_BADGE_WIDTH + EXPANDED_MENU_WIDTH_GAP;
}

export function handleLastSelectedWebsite(websiteId: string) {
  LocalStorage.setItem(storageItems.lastWebsiteId, websiteId);
}

export function isUserLoggedInWithCustomUrl() {
  const previewMRRegex = /dev-dashboard-mr-\d+.va-endpoint.com/g;
  // merge request previews
  if (window.location.host.match(previewMRRegex)) return false;
  // canary deployments
  if (window.location.host === 'app-canary.twipla.com' || window.location.host === 'app-canary.visitor-analytics.io') {
    return false;
  }
  // new domain - transitioned from app.visitor-analytics.io
  if (window.location.host === 'app.twipla.com') return false;
  return removeProtocol(Config.assetsUrl) !== `${window.location.host}/`;
}

export function getCustomUrl(url: string) {
  return `https://${url}${Config.customDomain}`;
}

export const getUserRole = (roles: Array<string>) => {
  if (!roles) return UserRole.WATCHER;
  if (roles.includes(UserRole.OWNER)) return UserRole.OWNER;
  if (roles.includes(UserRole.EDITOR)) return UserRole.EDITOR;
  if (roles.includes(UserRole.WATCHER)) return UserRole.WATCHER;
  if (roles.includes(UserRole.DASHBOARD)) return UserRole.DASHBOARD;
  if (
    roles.includes(PENDING_CONTRIBUTOR_REQUEST_USER_ROLES.REQUEST_EDITOR) ||
    roles.includes(PENDING_CONTRIBUTOR_REQUEST_USER_ROLES.REQUEST_WATCHER)
  )
    return undefined; //When website has pending status for contributor request.
};

export const mapWebsites = (websites: Array<WebsiteResponse>) => {
  return (
    websites &&
    websites.length > 0 &&
    map(websites, (website) => {
      return {
        ...website,
        platform: mapPlatform(website.platform),
        hash: md5(website.id),
        isWebsiteOwner: website.types?.includes(UserRole.OWNER),
        // TODO
        // @ts-ignore
        sharedWebsiteRequest: website.contributorRequests?.id,
        userRole:
          getUserRole(website.types) ||
          getUserRole(
            // TODO
            // @ts-ignore
            website.contributorRequests.length > 0 &&
              // @ts-ignore
              website.contributorRequests[0].types,
          ),
      };
    })
  );
};

/** @deprecated UPDATE */
export const getNavIconByName = (rawName: string, active: boolean, iconColor?: string) => {
  const name = capitalizeFirstLetter(rawName);

  const Component = (Icons as any)['Nav' + name];
  return <Component isActive={active} color={iconColor} />;
};
