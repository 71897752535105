import { modalName as commonModalName, ONE_HOUR } from '@va/constants';
import {
  generateImpersonateTokenSucceeded,
  GET_ACCOUNT_INFORMATION_SUCCEEDED,
  getAccountInformation,
  getAnnouncements,
  WEBSITE_FAILED,
  WEBSITE_SUCCEEDED,
} from '@va/dashboard/actions/api';
import { setWebsiteProperty } from '@va/dashboard/actions/app';
import {
  openModal,
  SET_MODAL_MOUNTED,
  setGlobalFilter,
  setMainDashboardChartPageUrlFilterValue,
  setNewFunnelUrlPattern,
  setNewHeatmapUrlPattern,
  setRecordingsOverviewPageUrlFilterValue,
} from '@va/dashboard/actions/ui';
import { accountWatchers } from '@va/dashboard/sagas/api';
import { watchers as coreWatchers } from '@va/dashboard/sagas/shared';
import { getAccountInformation as getAccountInformationData } from '@va/dashboard/selectors/api';
import { getWebsiteTimezone } from '@va/dashboard/selectors/core';
import * as Actions from '@va/standalone/shared/actions';
import * as Types from '@va/standalone/shared/actions';
import { setOnboardingStep } from '@va/standalone/shared/actions';
import { refreshImpersonateToken, refreshToken } from '@va/standalone/shared/api-client/Auth';
import { weeblyRefreshToken } from '@va/standalone/shared/api-client/weebly';
import { accountType, modalName, storageItems, T3_URL_PARAMS } from '@va/standalone/shared/constants';
import {
  addCredentialsToLocalStorage,
  getAccessToken,
  getAccountType,
  getRefreshToken,
  handleExpiredRefreshToken,
  isUserLoggedInWithCustomUrl,
  removeCredentialsFromLocalStorage,
} from '@va/standalone/shared/helpers';
import { appHistory } from '@va/shared/router';
import { getLastOnboardingStep, userHasFinishedOnboarding } from '@va/standalone/shared/selectors';
import { getParameterByName, isRefreshTokenExpired, LocalStorage } from '@va/util/helpers';
import { isNumber } from 'lodash';
import moment from 'moment-timezone';
import { all, call, delay, put, select, spawn, take, takeLatest } from 'redux-saga/effects';
import * as ApiSaga from './api';
import * as AppSaga from './app';

export const watchersArr = [
  takeLatest(Types.standalone.App.INITIALIZE, initialize),
  ApiSaga.watchers(),
  AppSaga.watchers(),
  accountWatchers(),
  ...coreWatchers,
];

export default function* rootSaga() {
  yield all(watchersArr);
}

export function* refreshTokenPeriodically() {
  if (isRefreshTokenExpired()) {
    handleExpiredRefreshToken(appHistory.location.pathname);
    return;
  }
  while (true) {
    try {
      let storedRefreshToken = getRefreshToken();
      let payload = {
        refreshToken: storedRefreshToken,
      };
      if (isUserLoggedInWithCustomUrl()) {
        payload.agencyId = localStorage.getItem(storageItems.agencyId);
      }
      const loggedAccountType = getAccountType();
      const isWeeblyAccountType = loggedAccountType === accountType.WEEBLY;
      const impersonateDataStringified = getParameterByName('impersonateData');
      let refreshTokenAction = refreshToken;
      if (impersonateDataStringified) {
        refreshTokenAction = refreshImpersonateToken;
        const impersonateData = JSON.parse(impersonateDataStringified);
        storedRefreshToken = impersonateData.refreshToken;
        payload.impersonate = impersonateData.instanceId;
        addCredentialsToLocalStorage(impersonateData, Types.standalone.Api.GENERATE_IMPERSONATE_TOKEN_REQUEST);
      } else if (isWeeblyAccountType) {
        refreshTokenAction = weeblyRefreshToken;
      }
      const data = yield call(refreshTokenAction, payload);
      addCredentialsToLocalStorage(data);
      yield put(Actions.standalone.Api.refreshTokenEnded());
      yield delay((ONE_HOUR / 2) * 1000); //half of auth token expire duration (in ms)
    } catch (e) {
      handleExpiredRefreshToken(appHistory.location.pathname);
      return;
    }
  }
}

function isValidUnsubscribeUrl() {
  const pathName = appHistory.location.pathname;
  const regex = /^\/website\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/unsubscribe/i;

  if (regex.test(pathName)) return true;

  if (pathName.includes('unsubscribe')) throw new Error('Invalid Unsubscribe URL');

  return false;
}

export function* initialize() {
  try {
    if (isValidUnsubscribeUrl()) return;
  } catch (e) {
    appHistory.push('/login');
    return;
  }

  if (appHistory.location.pathname === '/authorize-weebly') return; // redirection will be made into weeblyLogin saga.

  if (appHistory.location.pathname === '/impersonate') {
    removeCredentialsFromLocalStorage();
    appHistory.push('/generate-impersonate');
  }
  const accessToken = getAccessToken();

  const impersonateDataStringified = getParameterByName('impersonateData');
  if (impersonateDataStringified) {
    try {
      window.addEventListener('beforeunload', function () {
        removeCredentialsFromLocalStorage();
      });
      const impersonateData = JSON.parse(impersonateDataStringified);
      addCredentialsToLocalStorage(impersonateData, Types.standalone.Api.GENERATE_IMPERSONATE_TOKEN_REQUEST);

      yield put(generateImpersonateTokenSucceeded(impersonateData));
    } catch (e) {
      appHistory.push('/unsupported-localstorage');
    }
  } else if (!accessToken) {
    return;
  } else if (isRefreshTokenExpired()) {
    handleExpiredRefreshToken(appHistory.location.pathname);
    return;
  } else if (['/delete', '/delete/'].indexOf(appHistory.location.pathname) >= 0) {
    removeCredentialsFromLocalStorage();
    return;
  }
  if (appHistory.location.pathname !== '/analytics/google/views') {
    //TODO: add an array of unneeded routes for refresh token if needed more paths to be added.
    yield spawn(refreshTokenPeriodically);
  }
  // Wait for refresh token to finish.
  yield take([Types.standalone.Api.REFRESH_TOKEN_ENDED]);

  yield all([put(getAccountInformation()), put(Actions.standalone.Api.getWebsites())]);

  // Wait for all initialize required actions to finish successfully.
  yield all([take(GET_ACCOUNT_INFORMATION_SUCCEEDED), take(Types.standalone.Api.GET_WEBSITES_SUCCEEDED)]);

  const websiteResponse = yield take([WEBSITE_SUCCEEDED, WEBSITE_FAILED]);

  const hasFinishedOnboarding = yield select(userHasFinishedOnboarding);
  const lastCompletedOnboardingStep = yield select(getLastOnboardingStep);

  if (websiteResponse.type === WEBSITE_FAILED) {
    if (isRefreshTokenExpired()) {
      removeCredentialsFromLocalStorage();
      appHistory.push('/login');
      return;
    }

    appHistory.push('/error');
    // Invalid website id.
    LocalStorage.removeItem(storageItems.lastWebsiteId);
    return;
  }
  if (getAccountType() === accountType.WEEBLY && !hasFinishedOnboarding) {
    if (isNumber(lastCompletedOnboardingStep)) {
      yield put(setOnboardingStep(lastCompletedOnboardingStep + 1));
    }
    yield put(Actions.standalone.App.initializeSucceeded());
    appHistory.push('/weebly-onboarding');
    return;
  }

  if (!hasFinishedOnboarding && lastCompletedOnboardingStep !== undefined) {
    yield put(setOnboardingStep(lastCompletedOnboardingStep + 1));
    yield put(Actions.standalone.App.initializeSucceeded());
    appHistory.push('/activate');
    return;
  }
  yield spawn(parseT3QueryParams);

  //todo temporary fix
  //mark current website as having first visit
  yield put(setWebsiteProperty('hasFirstVisit', true)); // TODO Check if this is still required

  yield spawn(handleConfirmContributor);
  yield put(Actions.standalone.App.initializeSucceeded());

  yield put(Actions.standalone.Api.getAgencyUi());
  yield put(getAnnouncements());
  if (!isUserLoggedInWithCustomUrl()) {
    // TODO verify if all these calls are needed at the time of initialization
    yield put(Actions.standalone.Api.getAgencyThemes());
    const accountInfo = yield select(getAccountInformationData);
    if (accountInfo?.hasCustomer) {
      yield put(Actions.standalone.Api.getAgencyCustomer());
    }
  }

  const modalMounted = yield take([SET_MODAL_MOUNTED]);

  //TODO Iulia - Recheck if this is still needed
  if (modalMounted.type === SET_MODAL_MOUNTED) {
    if (getParameterByName('showUpgrade')) {
      yield put(openModal(commonModalName.upgrade));
    } else if (getParameterByName('showUpdateInfo')) {
      yield put(openModal(modalName.UPDATE_CARD_DETAILS));
    }
  }
}

function* handleConfirmContributor() {
  let pendingConfirmation = LocalStorage.getItem(storageItems.pendingConfirmation);
  if (pendingConfirmation) {
    const userId = LocalStorage.getItem(storageItems.userId);
    pendingConfirmation = JSON.parse(pendingConfirmation);
    if (pendingConfirmation.name === 'confirmContributor' && pendingConfirmation.userId === userId) {
      yield put(Actions.standalone.App.startConfirmContributorFlow());
      yield put(openModal(modalName.CONFIRM_CONTRIBUTOR_REQUEST));
    }
  }
}

function* parseT3QueryParams() {
  const startDate = getParameterByName(T3_URL_PARAMS.startDate);
  const endDate = getParameterByName(T3_URL_PARAMS.endDate);
  const newFunnelUrlPattern = getParameterByName(T3_URL_PARAMS.newFunnelUrl);
  const newHeatmapUrlPattern = getParameterByName(T3_URL_PARAMS.newHeatmapUrl);
  const mainDashboardPageUrl = getParameterByName(T3_URL_PARAMS.mainDashboardPageUrl);
  const recordingsOverviewPageUrl = getParameterByName(T3_URL_PARAMS.recordingsOverviewPageUrl);

  if (startDate && endDate) {
    const timezone = yield select(getWebsiteTimezone);

    yield put(
      setGlobalFilter({
        from: moment(startDate, 'YYYY-MM-DD').tz(timezone, true).startOf('day'),
        until: moment(endDate, 'YYYY-MM-DD').tz(timezone, true).endOf('day'),
      }),
    );
  }

  if (mainDashboardPageUrl) {
    yield put(setMainDashboardChartPageUrlFilterValue(mainDashboardPageUrl));
  }

  if (recordingsOverviewPageUrl) {
    yield put(setRecordingsOverviewPageUrlFilterValue(recordingsOverviewPageUrl));
  }

  if (newHeatmapUrlPattern) {
    yield put(setNewHeatmapUrlPattern(newHeatmapUrlPattern));
  }

  if (newFunnelUrlPattern) {
    yield put(setNewFunnelUrlPattern(newFunnelUrlPattern));
  }
}
