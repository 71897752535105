import { InfoIcon } from '@va/icons';
import { IconWithBackground } from '../icon-with-background';
import { fontWeights, Paragraph } from '../typography';

type InfoBannerProps = {
  text: string;
};

export const InfoBanner = ({ text }: InfoBannerProps) => {
  return (
    <div className='flex py-1.5 pl-3 pr-18px gap-3 bg-white rounded-12'>
      <IconWithBackground className='self-start bg-green-haze w-9 h-9 my-1.5' icon={() => <InfoIcon color='white' />} />
      <Paragraph className='self-center' weight={fontWeights.medium}>
        {text}
      </Paragraph>
    </div>
  );
};
