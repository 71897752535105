import NoConsentCookieBannerImage from '@va/images/cookie-banners/no-consent-cookie-banner.svg?react';
import { useTranslate } from '@va/localization';
import { getTrackingCode as getTrackingCodeSelector } from '@va/standalone/shared/selectors';
import { fontWeights, Heading5, InfoBanner, Paragraph } from '@va/ui/design-system';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TrackingCodeSection } from './TrackingCodeSection';

export const NoConsentTrackingCode = () => {
  const translate = useTranslate();
  const trackingCode = useSelector(getTrackingCodeSelector);

  const chips = useMemo<{ label: string; tooltip?: string }[]>(
    () => [
      {
        label: translate('trackingCode.installation.noConsent.chips.maxPrivacyMode'),
        tooltip: translate('trackingCode.installation.noConsent.chips.maxPrivacyMode.tooltip'),
      },
    ],
    [translate],
  );

  return (
    <div className='flex flex-col gap-3 sm:mt-3 mt-18px'>
      <div className='rounded-24 bg-primary'>
        <div className='w-full h-full bg-white-96 sm:p-18px md:p-18px lg:p-6 flex gap-3'>
          <NoConsentCookieBannerImage className='sm:hidden md:hidden lg:block shrink-0 -translate-y-6' />
          <div className='overflow-hidden basis-80 grow'>
            <Heading5 weight={fontWeights.semibold}>
              {translate('trackingCode.installation.noConsent.infoCard.title')}
            </Heading5>
            <Paragraph className='mb-1.5' weight={fontWeights.medium}>
              {translate('trackingCode.installation.noConsent.infoCard.subtitle')}
            </Paragraph>
            <InfoBanner text={translate('trackingCode.installation.noConsent.banner')} />
          </div>
        </div>
      </div>
      <TrackingCodeSection
        description={translate('trackingCode.noConsent.description')}
        trackingCode={trackingCode || ''}
        chips={chips}
      />
    </div>
  );
};
