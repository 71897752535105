import { startUpgradeProcess } from '@va/dashboard/actions/app';
import { hasUnlimitedVoucher } from '@va/dashboard/selectors/app';
import { isLimitReachedBannerVisible } from '@va/dashboard/selectors/ui';
import { useTranslate } from '@va/localization';
import { hasPermission } from '@va/standalone/shared/selectors';
import { PreHeaderBadge } from '@va/ui/design-system';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

export function LimitReachedBadge() {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const { pathname } = useLocation();
  const isBannerVisible = useSelector(isLimitReachedBannerVisible);
  const hasChangeSubscriptionPermission = useSelector(hasPermission);
  const hasUnlimitedVoucherApplied = useSelector(hasUnlimitedVoucher);

  if (hasUnlimitedVoucherApplied || !isBannerVisible || pathname.includes('upgrade') || pathname.includes('my-account'))
    return null;

  return (
    <PreHeaderBadge
      color='danger'
      message={translate('plan.limitReachedBadge.info')}
      actionText={translate('plan.limitReachedBadge.cta')}
      isActionEnabled={hasChangeSubscriptionPermission}
      actionTooltip={!hasChangeSubscriptionPermission && translate('tooltips.contributorRole')}
      onActionClick={() => {
        dispatch(startUpgradeProcess());
      }}
    />
  );
}
