import { getInstanceId } from '@va/dashboard/selectors/app';
import { post, put, remove } from '@va/http-client';
import { DeviceTypesEnum } from '@va/types/device';
import { useFetchData } from '@va/util/hooks';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export type ParticipantResponseItem = {
  browser: string;
  completedAt: number;
  completionRate: number;
  completionTime: number;
  deviceType: DeviceTypesEnum;
  platform: string;
  location: string;
  type: number;
  visitorKey: string;
};

type Question = {};

export type Survey = {
  advertising: boolean;
  appearance: {
    mainColor: string;
    backgroundColor: string;
    estimatedTimeToComplete: number;
  };
  backgroundColor: string;
  estimatedTimeToComplete: number;
  mainColor: string;
  createdAt: number;
  id: string;
  language: string | null;
  questions: Array<Question>;
  settings: {
    visualization: number;
    progressBar: boolean;
    redirectOnCompletion: boolean;
  };
  status: number;
  title: string;
};

export type SurveyGeneralData = {
  advertising: boolean;
  createdAt: number;
  createdBy: string;
  generalStats: SurveyGeneralStats;
  id: string;
  language: string | null;
  publishedOn: number;
  status: number;
  title: string;
  websiteId: string;
};

type SurveyGeneralStats = {
  answersPerQuestion: Array<number>;
  avgCompletionTime: number;
  completionRate: number;
  responses: number;
  starts: number;
};

export type Poll = {
  id: string;
  advertising: boolean;
  appearance: {
    mainColor: string;
    backgroundColor: string;
    bubblePosition: number;
    hPadding: number;
    vPadding: number;
  };
  createdAt: number;
  dismissible: number;
  language: string;
  questions: Array<Question>;
  status: number;
  targeting: { showOn: Array<string>; hideOn: Array<string> };
  title: string;
  publishedOn: number;
  generalStats: {
    starts: number;
    responses: number;
    completionRate: number;
    avgCompletionTime: number;
    answersPerQuestion: number[];
    averageCompletionTime: number;
    initiates: number;
    dismissals: number;
  };
};

type PollStats = {
  answersPerQuestion: Array<number>;
  avgCompletionTime: number;
  completionRate: number;
  dismissals: number;
  responses: number;
  starts: number;
};

export type PollParticipantsFilter = {
  location?: { is?: string[]; isNot?: string[] };
  visitor?: { is?: string[]; isNot?: string[] };
};

//Polls//
export const createPoll = (websiteId: string, data: Omit<Poll, 'id'>) => {
  const url = `/websites/${websiteId}/polls`;
  return post(url, {}, data);
};

export const editPoll = (websiteId: string, pollId: string, data: Poll) => {
  return put(`/websites/${websiteId}/polls/${pollId}`, {}, data);
};

export const usePolls = () => {
  const websiteId = useSelector(getInstanceId);
  const url = `/websites/${websiteId}/polls`;

  return useFetchData<Poll[]>(url, {
    revalidateOnMount: true,
    revalidateIfStale: true,
  });
};

export const usePoll = (websiteId: string, pollId: string) => {
  const mapper = useCallback((response: Poll) => {
    if (!response?.language) {
      response.language = 'en';
    }
    return response;
  }, []);

  return useFetchData<Poll, Error>(
    `/websites/${websiteId}/polls/${pollId}?targeting=true&appearance=true`,
    {
      revalidateOnMount: true,
      revalidateIfStale: true,
    },
    mapper,
  );
};

export const usePollGeneralStats = (websiteId: string, pollId: string) => {
  return useFetchData<PollStats, Error>(`/websites/${websiteId}/polls/${pollId}/stats/general`);
};

export const usePollParticipantResponse = (websiteId: string, pollId: string, visitorId: string) => {
  const url = `/websites/${websiteId}/polls/${pollId}/visitors/${visitorId}/responses`;
  return useFetchData(url);
};

export const usePollResults = (websiteId: string, pollId: string) => {
  return useFetchData(`/websites/${websiteId}/polls/${pollId}/stats/results`);
};

export const usePollQuestionResults = (websiteId: string, pollId: string, questionId: string) => {
  return useFetchData(`/websites/${websiteId}/polls/${pollId}/stats/questions/${questionId}/results`);
};

export const updatePollStatus = (websiteId: string, pollId: string, status: number) => {
  const url = `/websites/${websiteId}/polls/${pollId}/status`;
  return put(url, {}, { status });
};

export const deletePoll = (websiteId: string, pollId: string) => {
  return remove(`/websites/${websiteId}/polls/${pollId}`);
};

//Surveys//

export const createSurvey = (websiteId: string, data: Omit<Survey, 'id'>) => {
  const url = `/websites/${websiteId}/surveys`;
  return post(url, {}, data);
};

export const editSurvey = (websiteId: string, surveyId: string, data: Survey) => {
  return put(`/websites/${websiteId}/surveys/${surveyId}`, {}, data);
};

export const useSurveys = () => {
  const websiteId = useSelector(getInstanceId);
  const url = `/websites/${websiteId}/surveys`;
  return useFetchData<SurveyGeneralData[]>(url, {
    revalidateOnMount: true,
    revalidateIfStale: true,
  });
};

export const useSurvey = (websiteId: string, surveyId: string) => {
  const mapper = useCallback((response: Survey) => {
    if (!response?.language) {
      response.language = 'en';
    }
    return response;
  }, []);

  return useFetchData(
    `/websites/${websiteId}/surveys/${surveyId}?appearance=true&settings=true`,
    {
      revalidateOnMount: true,
      revalidateIfStale: true,
    },
    mapper,
  );
};

export const useSurveyGeneralStats = (websiteId: string, surveyId: string) => {
  return useFetchData(`/websites/${websiteId}/surveys/${surveyId}/stats/general`, {
    revalidateOnMount: true,
  });
};

export const useSurveyResults = (websiteId: string, surveyId: string) => {
  return useFetchData(`/websites/${websiteId}/surveys/${surveyId}/stats/results`, {
    revalidateOnMount: true,
  });
};

export const useSurveyQuestionResults = (websiteId: string, surveyId: string, questionId: string) => {
  return useFetchData(`/websites/${websiteId}/surveys/${surveyId}/stats/questions/${questionId}/results`);
};

export const updateSurveyStatus = (websiteId: string, surveyId: string, status: unknown) => {
  const url = `/websites/${websiteId}/surveys/${surveyId}/status`;
  return put(url, {}, { status });
};

export const deleteSurvey = (websiteId: string, surveyId: string) => {
  return remove(`/websites/${websiteId}/surveys/${surveyId}`);
};
