import { startUpgradeProcess } from '@va/dashboard/actions/app';
import { getIntpResetDate } from '@va/dashboard/selectors/app';
import { useLocalizationContext } from '@va/localization';
import { hasPermission } from '@va/standalone/shared/selectors';
import { paragraphSizes, ParagraphWithTooltip } from '@va/ui/design-system';
import { getLocalizedDate, is3asApp, isWixMiniApp } from '@va/util/helpers';
import { useIsHovering } from '@va/util/hooks';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export function NextResetDate() {
  const dispatch = useDispatch();
  const { translate, locale } = useLocalizationContext();
  const nextResetDateRef = useRef<HTMLParagraphElement>(null);
  const isHovering = useIsHovering(nextResetDateRef);
  const intpResetDate = useSelector(getIntpResetDate);
  const hasUpgradePermission = useSelector(hasPermission);
  const nextResetDate = is3asApp() || isWixMiniApp() ? dayjs(intpResetDate) : dayjs().add(1, 'month').startOf('month');
  const formattedNextResetDate = getLocalizedDate(nextResetDate.unix(), locale);

  return (
    <div ref={nextResetDateRef} className='min-w-180px'>
      <ParagraphWithTooltip
        size={paragraphSizes.tiny}
        className='cursor-pointer font-525 xxs:w-160px'
        colorClassName={classNames({
          'text-primary-bright-50 underline': isHovering,
          'text-white-75': !isHovering,
        })}
        onClick={() => {
          if (!hasUpgradePermission) return;
          dispatch(startUpgradeProcess());
        }}
      >
        {isHovering && hasUpgradePermission
          ? translate('featureCounter.increaseLimits')
          : translate('featureCounter.nextResetDate', { nextResetDate: formattedNextResetDate })}
      </ParagraphWithTooltip>
    </div>
  );
}
