export function LogoApp(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg width='180' height='24' viewBox='0 0 180 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M62.3585 8.864V6.8H70.6785V8.864H67.6545V18H65.3665V8.864H62.3585ZM94.2549 13.76L96.6549 6.8H99.3429L94.6229 18.624L91.1029 11.264L87.5669 18.624L82.8629 6.8H85.5509L87.9829 13.76L91.1029 6.24L94.2549 13.76ZM112.159 6.8H114.479V18H112.159V6.8ZM128.413 6.8H130.701V18H128.413V6.8ZM129.805 8.736V6.8H132.029C132.915 6.8 133.667 6.944 134.285 7.232C134.915 7.52 135.395 7.93067 135.725 8.464C136.056 8.99733 136.221 9.632 136.221 10.368C136.221 11.0933 136.056 11.728 135.725 12.272C135.395 12.8053 134.915 13.216 134.285 13.504C133.667 13.792 132.915 13.936 132.029 13.936H129.805V12H132.029C132.637 12 133.117 11.8613 133.469 11.584C133.821 11.3067 133.997 10.9013 133.997 10.368C133.997 9.824 133.821 9.41867 133.469 9.152C133.117 8.87467 132.637 8.736 132.029 8.736H129.805ZM149.512 6.8H151.8V16H156.264V18H149.512V6.8ZM171.131 15.456L171.403 13.648H176.779L177.051 15.456H171.131ZM174.059 10.432L172.379 14.288L172.427 14.8L170.955 18H168.379L174.059 6.144L179.739 18H177.147L175.707 14.896L175.739 14.32L174.059 10.432Z'
        fill='black'
        fillOpacity='0.84'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.2857 24H17.1427L6.857 0H0L10.2857 24ZM30.857 24H37.7143L34.2857 16L29.7143 5.333L32 0H20.5713L16 10.667L19.4287 18.667L24 8L26.2857 13.333L30.857 24Z'
        fill='url(#paint0_linear_148_178654)'
      />
      <path d='M48 0L40 18.667L36.5713 10.667L41.143 0H48Z' fill='url(#paint1_linear_148_178654)' />
      <defs>
        <linearGradient id='paint0_linear_148_178654' x1='0' y1='12' x2='48' y2='12' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#F56600' />
          <stop offset='1' stopColor='#5C04B4' />
        </linearGradient>
        <linearGradient id='paint1_linear_148_178654' x1='0' y1='12' x2='48' y2='12' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#F56600' />
          <stop offset='1' stopColor='#5C04B4' />
        </linearGradient>
      </defs>
    </svg>
  );
}

export const LogoAppV2 = (props: JSX.IntrinsicElements['svg']) => {
  return (
    <svg width='190' height='36' viewBox='0 0 190 36' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.21425 27H13.357L5.64275 9H0.5L8.21425 27ZM23.6428 27H28.7858L26.2142 21L22.7857 12.9998L24.5 9H15.9285L12.5 17.0003L15.0715 23.0003L18.5 15L20.2143 18.9998L23.6428 27Z'
        fill='url(#paint0_linear_5514_205027)'
      />
      <path d='M36.5 9L30.5 23.0003L27.9285 17.0003L31.3573 9H36.5Z' fill='url(#paint1_linear_5514_205027)' />
      <path
        d='M54.6783 13.722V11.4H64.0383V13.722H60.6363V24H58.0623V13.722H54.6783ZM91.1017 19.23L93.8017 11.4H96.8257L91.5157 24.702L87.5557 16.422L83.5777 24.702L78.2857 11.4H81.3097L84.0457 19.23L87.5557 10.77L91.1017 19.23ZM111.784 11.4H114.394V24H111.784V11.4ZM130.61 11.4H133.184V24H130.61V11.4ZM132.176 13.578V11.4H134.678C135.674 11.4 136.52 11.562 137.216 11.886C137.924 12.21 138.464 12.672 138.836 13.272C139.208 13.872 139.394 14.586 139.394 15.414C139.394 16.23 139.208 16.944 138.836 17.556C138.464 18.156 137.924 18.618 137.216 18.942C136.52 19.266 135.674 19.428 134.678 19.428H132.176V17.25H134.678C135.362 17.25 135.902 17.094 136.298 16.782C136.694 16.47 136.892 16.014 136.892 15.414C136.892 14.802 136.694 14.346 136.298 14.046C135.902 13.734 135.362 13.578 134.678 13.578H132.176ZM154.886 11.4H157.46V21.75H162.482V24H154.886V11.4ZM179.747 21.138L180.053 19.104H186.101L186.407 21.138H179.747ZM183.041 15.486L181.151 19.824L181.205 20.4L179.549 24H176.651L183.041 10.662L189.431 24H186.515L184.895 20.508L184.931 19.86L183.041 15.486Z'
        fill='#6B11C5'
      />
      <defs>
        <linearGradient
          id='paint0_linear_5514_205027'
          x1='0.5'
          y1='18.0566'
          x2='36.5'
          y2='18.0566'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#FF6A00' />
          <stop offset='1' stop-color='#6B11C5' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_5514_205027'
          x1='0.5'
          y1='18.0566'
          x2='36.5'
          y2='18.0566'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#FF6A00' />
          <stop offset='1' stop-color='#6B11C5' />
        </linearGradient>
      </defs>
    </svg>
  );
};
