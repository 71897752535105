import classNames from 'classnames';
import { PropsWithChildren } from 'react';

type OnboardingIconContainerProps = {
  className?: string;
};

export const OnboardingIconContainer = ({ children, className }: PropsWithChildren<OnboardingIconContainerProps>) => {
  return (
    <div
      className={classNames(
        'flex items-center justify-center w-[84px] h-[84px] rounded-full border-[1.5px] border-black-lighter-13',
        className,
      )}
    >
      {children}
    </div>
  );
};
