import { selectIntpWebsite } from '@va/aaas/shared/selectors';
import { PlanNames } from '@va/constants';
import { hasUnlimitedVoucher } from '@va/dashboard/selectors/app';
import {
  hasFreePlan as hasFreePlanSelector,
  isSettingActiveWebsiteInProgress as isSettingActiveWebsiteInProgressSelector,
} from '@va/standalone/shared/selectors';
import { is3asApp, isWixMiniApp } from '@va/util/helpers';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

dayjs.extend(utc);
dayjs.extend(timezone);

export const useFreePlanStatus = () => {
  const freePlanHistoryDaysNo = 40;
  const hasFreePlan = useSelector(hasFreePlanSelector);
  const isSettingActiveWebsiteInProgress = useSelector(isSettingActiveWebsiteInProgressSelector);
  const today = dayjs().tz(window.timezone).endOf('day');
  const freePlanHistoryStartDate = today.subtract(freePlanHistoryDaysNo, 'days');
  const isWixMini = isWixMiniApp();
  const is3As = is3asApp();
  const intpWebsite = useSelector(selectIntpWebsite);
  const isPaidPlanOn3As = is3As && intpWebsite.packageName !== PlanNames.Free;
  const hasUnlimitedVoucherApplied = useSelector(hasUnlimitedVoucher);

  const hasActiveFreePlan = useMemo(() => {
    if (
      !hasFreePlan ||
      isSettingActiveWebsiteInProgress ||
      isWixMini ||
      isPaidPlanOn3As ||
      hasUnlimitedVoucherApplied
    ) {
      return false;
    }
    return true;
  }, [hasFreePlan, isSettingActiveWebsiteInProgress, isWixMini, isPaidPlanOn3As, hasUnlimitedVoucherApplied]);

  return { hasActiveFreePlan, freePlanHistoryStartDate, freePlanHistoryDaysNo };
};
