import { IconProps } from '.';

export const DownwardTrendIcon = ({ className, color = 'var(--color-negative)' }: IconProps) => {
  return (
    <svg className={className} width='9' height='11' viewBox='0 0 9 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.99997 6V0H3.99997V6L0.296875 6.00401L4.49997 10.2071L8.70623 6.00085L4.99997 6Z'
        fill={color}
      />
    </svg>
  );
};
