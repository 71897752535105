import * as Actions from '@va/standalone/shared/actions';
import * as Types from '@va/standalone/shared/actions';
import {
  SET_CHATWOOT_ATTRIBUTES,
  setChatwootAttributesAction,
  setWebsiteCreationStep,
} from '@va/standalone/shared/actions';
import {
  dataViewerHistoryRoutes,
  dataViewerRoutesNoHistory,
  INTERN_ACCOUNT_EMAIL_ADDRESS,
  modalName,
  storageItems,
  SUBSCRIPTION_PROVIDER_TYPE,
} from '@va/standalone/shared/constants';
import {
  addCredentialsToLocalStorage,
  getBaseRouteFromHistoryRoute,
  getLastWebsiteId,
  getRouteWithoutWebsiteIdPrefix,
  getUserId,
  handleExpiredRefreshToken,
  handleLastSelectedWebsite,
  isAccountSettingsPageSelected,
  isUserLoggedInWithCustomUrl,
  mapWebsites,
} from '@va/standalone/shared/helpers';
import * as Selectors from '@va/standalone/shared/selectors';
import {
  getActiveWebsiteV2,
  getApiRequestStatus,
  getLastCreatedSubscription,
  getPlanDetailsById,
  userHasFinishedOnboarding,
} from '@va/standalone/shared/selectors';
import { SubscriptionStatus, UserRole } from '@va/types/website';
import {
  getAnnouncementsToBeDisplayed,
  getFullName,
  isDevelopFeatureEnabled,
  LocalStorage,
  setDefaultTheme,
} from '@va/util/helpers';
import moment from 'moment';

import {
  getModulePermissions,
  HANDLE_ANNOUNCEMENT_MODAL,
  handleAnnouncementModal,
  handleUpdateCompanyDetailsModal,
  set,
  setInstanceId,
  setWebsiteProperty,
} from '@va/dashboard/actions/app';

import {
  ADD_NOTIFICATION_EVENT,
  apiStatus,
  bannerName,
  modalName as commonModalName,
  defaultTrafficFilter,
  defaultWebsiteId,
  flagKeys,
  lastDateModalShown,
  notificationTransKeys,
  pathName,
  Platform,
  platform,
  TRAFFIC_FILTER,
  websiteCreationSteps,
  websiteFlagKeys,
} from '@va/constants';
import { setChatwootAttributes } from '@va/dashboard/components';
import { getWebsites } from '@va/standalone/shared/api-client/Account';
import { EventBus } from '@va/util/misc';
import { all, call, put, select, spawn, take, takeLatest } from 'redux-saga/effects';

import {
  clearNotifications,
  CLOSE_MODAL,
  closeModal,
  hideBanner,
  openCheckLoginModal,
  openModal,
  setActiveTab,
  setAnnouncementData,
  setTrafficFilter,
  showBanner,
} from '@va/dashboard/actions/ui';
import { checkSsrSettings, setLimitNotificationShown, showLimitNotification } from '@va/dashboard/sagas/app';
import {
  getAccountInformation,
  getAnnouncementsSelector,
  getApiRequest,
  getRequestStatus,
} from '@va/dashboard/selectors/api';
import { getActiveTab, getTrafficFilter } from '@va/dashboard/selectors/ui';
import { appHistory } from '@va/shared/router';
import { refreshToken } from '../../apiClient';

import {
  GET_FEATURE_PERMISSIONS_FAILED,
  GET_FEATURE_PERMISSIONS_SUCCEEDED,
  getDynamicPages,
  getFeaturePermissions,
  getPrivacySettings,
  getSsrSettings,
  loadExternalDashboardScript,
  markModalAsViewed,
  requestWebsite,
  resetRequest,
  resetRequestStatus,
  WEBSITE_SUCCEEDED,
} from '@va/dashboard/actions/api';
import { getPageVisitsCount } from '@va/dashboard/api-client/Website';
import {
  getInstanceId,
  hasUnlimitedVoucher as hasUnlimitedVoucherSelector,
  isTrackingCodeInstalled,
} from '@va/dashboard/selectors/app';
import { getWebsite, getWebsitePlatform } from '@va/dashboard/selectors/core';
import { getProviderName } from '@va/dashboard/selectors/customization';
import { WixPermissionPack } from '@va/wix/shared/constants';
import { getPermissionPackage } from '@va/wix/shared/selectors';
import dayjs from 'dayjs';

export function* watchers() {
  yield all([
    takeLatest(Types.standalone.App.START_CANCEL_SUBSCRIPTION_FLOW, cancelSubscriptionFlow),
    takeLatest(Types.standalone.App.START_DELETE_SUBSCRIPTION_FLOW, deleteSubscriptionFlow),
    takeLatest(Types.standalone.App.START_CHANGE_EMAIL_FLOW, changeEmailFlow),
    takeLatest(Types.standalone.App.START_DELETE_ACCOUNT_FLOW, deleteAccountFlow),
    takeLatest(Types.standalone.App.SET_ACTIVE_WEBSITE, setActiveWebsite),
    takeLatest(Types.standalone.App.START_LEAVE_WEBSITE_FLOW, leaveWebsiteFlow),
    takeLatest(Types.standalone.App.START_CONFIRM_CONTRIBUTOR_FLOW, confirmContributorFlow),
    takeLatest(Types.standalone.App.START_CHECK_PASSWORD_FLOW, checkPasswordFlow),
    takeLatest(HANDLE_ANNOUNCEMENT_MODAL, handleAnnouncementModalSaga),
    takeLatest(Types.standalone.App.AUTH_EXTERNAL, handleAuthExternal),
    takeLatest(Types.standalone.App.REFRESH_SUBSCRIPTION_DETAILS, refreshSubscriptionDetails),
    takeLatest(SET_CHATWOOT_ATTRIBUTES, setChatwootAttributesSaga),
  ]);
}

function* cancelSubscriptionFlow() {
  yield put(openCheckLoginModal(modalName.CANCEL_SUBSCRIPTION, 'theme-red'));

  const checkLogin = yield take([Types.standalone.Api.CHECK_LOGIN_SUCCEEDED, CLOSE_MODAL]);
  if (checkLogin.type === Types.standalone.Api.CHECK_LOGIN_SUCCEEDED) {
    yield put(resetRequestStatus('checkLogin'));
    yield put(closeModal(modalName.CHECK_LOGIN));
    yield put(openModal(modalName.CANCEL_SUBSCRIPTION));
  } else if (checkLogin.type === CLOSE_MODAL) {
    yield put(resetRequestStatus('checkLogin'));
  }
}

function* deleteSubscriptionFlow() {
  yield put(openCheckLoginModal(modalName.DELETE_SUBSCRIPTION, 'theme-red'));

  const checkLogin = yield take([Types.standalone.Api.CHECK_LOGIN_SUCCEEDED, CLOSE_MODAL]);
  if (checkLogin.type === Types.standalone.Api.CHECK_LOGIN_SUCCEEDED) {
    yield put(resetRequestStatus('checkLogin'));
    yield put(closeModal(modalName.CHECK_LOGIN));
    yield put(Actions.standalone.Api.cancelSubscriptionImmediate());
    yield put(openModal(modalName.DELETE_SUBSCRIPTION));
    const deleteAction = yield take([
      Types.standalone.Api.CANCEL_SUBSCRIPTION_IMMEDIATE_SUCCEEDED,
      Types.standalone.Api.CANCEL_SUBSCRIPTION_IMMEDIATE_FAILED,
      CLOSE_MODAL,
    ]);
    if (deleteAction.type === Types.standalone.Api.CANCEL_SUBSCRIPTION_IMMEDIATE_SUCCEEDED) {
      yield put(Actions.standalone.App.refreshSubscriptionDetails());
    }
  } else if (checkLogin.type === CLOSE_MODAL) {
    yield put(resetRequestStatus('checkLogin'));
  }
}

export function* changeEmailFlow() {
  yield put(openCheckLoginModal('changeEmail'));

  //No need to wait for check login failed, because error is handled in the modal
  // and the modal need to stay open even if check login fails.
  const checkLogin = yield take([Types.standalone.Api.CHECK_LOGIN_SUCCEEDED, CLOSE_MODAL]);
  if (checkLogin.type === Types.standalone.Api.CHECK_LOGIN_SUCCEEDED) {
    yield put(resetRequestStatus('checkLogin'));
    yield put(closeModal('checkLogin'));
    yield put(openModal('changeEmail'));
  } else if (checkLogin.type === CLOSE_MODAL) {
    yield put(resetRequestStatus('checkLogin'));
  }
}

export function* deleteAccountFlow() {
  const userHasWebsites = yield select(Selectors.standalone.Api.userHasWebsites);
  const providerName = yield select(getProviderName);
  if (userHasWebsites && !isUserLoggedInWithCustomUrl()) {
    yield put(openModal('deleteAccount'));
  } else {
    yield put(openCheckLoginModal('deleteAccount', 'theme-red'));

    const checkLogin = yield take([Types.standalone.Api.CHECK_LOGIN_SUCCEEDED, CLOSE_MODAL]);
    if (checkLogin.type === Types.standalone.Api.CHECK_LOGIN_SUCCEEDED) {
      yield put(resetRequestStatus('checkLogin'));
      yield put(closeModal('checkLogin'));
      yield put(Actions.standalone.Api.deleteAccount());

      EventBus.dispatch(ADD_NOTIFICATION_EVENT, {
        translationKey: 'modal.deleteAccount.emailRequest.succeeded',
        translationKeyParams: { providerName },
        type: 'success',
      });
    } else if (checkLogin.type === CLOSE_MODAL) {
      yield put(resetRequestStatus('checkLogin'));
    }
  }
}

function* resetPreviousRequestsData() {
  const requests = ['createPaymentMethod', 'getDynamicPages', 'getConversionTypes', 'getFeaturePermissions'];
  for (let i = 0; i < requests.length; i++) {
    yield put(resetRequest(requests[i]));
  }
}

function* setActiveWebsite(action) {
  const oldWebsiteId = yield select(getInstanceId);
  //reset siteurl
  yield put(
    set({
      siteUrl: '#',
    }),
  );

  //clear notification
  yield put(clearNotifications());
  yield put(hideBanner(bannerName.LIMIT_REACHED));
  yield spawn(resetPreviousRequestsData);
  yield put(resetRequest('getModulePermissions'));

  // TODO Dumi Recheck those redirects
  if (action.handleRoute) {
    const processedRoute = getRouteWithoutWebsiteIdPrefix(appHistory.location.pathname);
    if (dataViewerRoutesNoHistory.includes(processedRoute)) {
      // do nothing - should be rechecked
    } else if (dataViewerHistoryRoutes.includes(processedRoute)) {
      //Redirect to previous tab on history routes.
      const baseTabFromHistory = getBaseRouteFromHistoryRoute(processedRoute);

      if (baseTabFromHistory) {
        appHistory.push(baseTabFromHistory);
      }
    } else {
      //Redirect to overview tab for non data-viewer tabs.
      appHistory.push('/');
    }
  }

  const websiteId = action.websiteId;

  if (appHistory.location.pathname.includes('/website/')) {
    const pathNameArr = appHistory.location.pathname.split('/');
    pathNameArr[2] = websiteId; // pathNameArr[2] represents oldWebsiteId from url
    const queryParams = appHistory.location.search ?? '';
    const newLocationPathname = pathNameArr.join('/') + queryParams + appHistory.location.hash;
    // ensure a new entry is added to the history only if the website id changes
    if (oldWebsiteId !== defaultWebsiteId && oldWebsiteId !== websiteId) {
      appHistory.push(newLocationPathname, {}, true);
    } else {
      appHistory.replace(newLocationPathname, {}, true);
    }
  }

  handleLastSelectedWebsite(websiteId);

  yield put(setInstanceId(websiteId));
  yield put(requestWebsite());
  yield take(WEBSITE_SUCCEEDED);

  const website = yield select(getWebsite);
  moment.tz.setDefault(website.timezone);
  dayjs.tz.setDefault(website.timezone);
  window.timezone = website.timezone;

  if (website.url) {
    try {
      const response = yield call(
        getWebsites,
        { url: website.url, withSubscription: true, withSharedWebsites: true, length: 1000 },
        getUserId(),
      );
      const filteredWebsites = response?.websites.filter((website) => {
        return website.id === websiteId;
      });
      const mappedWebsite = mapWebsites(filteredWebsites)[0];

      yield put(Actions.standalone.App.setActiveWebsiteSucceeded(mappedWebsite));
      yield put(getModulePermissions());
    } catch (error) {
      console.error('An error has ocurred!', error);
    }
  }

  const hasUserRoleFn = yield select(Selectors.hasUserRole);
  const isDashboardContributor = hasUserRoleFn(UserRole.DASHBOARD);

  if (!isDashboardContributor) {
    yield put(getPrivacySettings());
    yield put(getSsrSettings());
    yield spawn(checkSsrSettings);
  }

  const creationCompletedFlag =
    website.frontendFlags && website.frontendFlags[websiteFlagKeys.CREATION_PROCESS_COMPLETED];

  if (creationCompletedFlag === false && !isAccountSettingsPageSelected()) {
    yield put(setWebsiteCreationStep(websiteCreationSteps.privacyLevels));
    appHistory.push('/my-account/add-website');
  }

  if (!isAccountSettingsPageSelected() || action.fromManageWebsites) {
    if (website.whiteLabel) {
      yield put(Actions.standalone.Api.getAgencyUi());
    } else {
      yield put(resetRequest('getAgencyUi'));
      setDefaultTheme();
    }
  }

  yield put(getFeaturePermissions());
  if (!isDashboardContributor) {
    yield spawn(handleTrackingCodeRedirectSaga);
  }

  if (!isAccountSettingsPageSelected()) {
    const getPermissionsAction = yield take([GET_FEATURE_PERMISSIONS_SUCCEEDED, GET_FEATURE_PERMISSIONS_FAILED]);
    if (getPermissionsAction.type === GET_FEATURE_PERMISSIONS_SUCCEEDED) {
      yield spawn(handleLimitNotifications);
    }
  }
  //Force re-requesting data on currently data-viewer tab.
  if (action.fromManageWebsites) {
    appHistory.push('/');
  } else {
    if (defaultWebsiteId !== oldWebsiteId) {
      const activeTab = yield select(getActiveTab);
      if (activeTab) {
        yield put(setActiveTab(activeTab));
      }
    }
  }

  if (!isDashboardContributor) {
    if (website.platform !== platform.WIX) {
      yield spawn(handleSubscriptionDetails);
    }
    yield put(getDynamicPages());
    yield put(Actions.standalone.Api.getPaymentPlans());
    yield put(handleAnnouncementModal());
  }
  //todo temporary fix
  //mark next selected website as having first visit
  yield put(setWebsiteProperty('hasFirstVisit', true));

  const activeWebsite = yield select(Selectors.standalone.App.getActiveWebsite);

  if (appHistory.location.pathname === `/website/${websiteId}/upgrade` && !activeWebsite.get('isWebsiteOwner')) {
    appHistory.push('/');
  }

  if (activeWebsite.size > 0) {
    // Set siteUrl in core
    yield put(
      set({
        isPublish: 'true',
        siteUrl: activeWebsite.get('url'),
      }),
    );
  }
  yield put(setChatwootAttributesAction());
  yield spawn(handleTrafficFilter);
  yield put(loadExternalDashboardScript());
  if (!isAccountSettingsPageSelected() && !isDashboardContributor) {
    const hasFeaturePermission = yield select(Selectors.standalone.App.hasPermission);
    if (hasFeaturePermission) {
      const hasTrackingCodeError = yield select(Selectors.standalone.App.hasTrackingCodeError);
      if (hasTrackingCodeError && website.platform !== platform.CUSTOM) {
        yield put(handleUpdateCompanyDetailsModal());
      }
    }
  }
}

function* handleTrackingCodeRedirectSaga() {
  const websiteId = yield select(getInstanceId);
  const trackingCodeInstalled = yield select(isTrackingCodeInstalled);
  const data = yield call(getPageVisitsCount, websiteId);
  const count = data?.payload?.count;
  const hasFinishedOnboarding = yield select(userHasFinishedOnboarding);
  const websitePlatform = yield select(getWebsitePlatform);

  // We rely on WiX for adding the tracking code. WiX users can add the SSR code, but it is not mandatory
  if (websitePlatform === Platform.WIX) return;

  if (count !== 0) return;
  if (trackingCodeInstalled) return;
  if (isAccountSettingsPageSelected()) return;
  if (window.location.pathname.includes('/invoice')) return;
  if (window.location.pathname.includes('/upgrade')) return;
  if (!hasFinishedOnboarding) return;
  if (window.skipTrackingCodeRedirect) {
    window.skipTrackingCodeRedirect = false;
    return;
  }

  appHistory.push(`/website/${websiteId}/settings/tracking-code`);
}

function* setChatwootAttributesSaga() {
  const accountInformation = yield select(getAccountInformation);
  const websiteInfo = yield select(getWebsite);
  const activeWebsite = yield select(getActiveWebsiteV2);
  yield take([Types.standalone.Api.GET_ALL_SUBSCRIPTIONS_SUCCEEDED, Types.standalone.Api.GET_ALL_SUBSCRIPTIONS_FAILED]);
  const subscription = yield select(getLastCreatedSubscription);

  const chatwootAttributes = {
    website_url: websiteInfo?.url,
    website_id: websiteInfo?.id,
    email: accountInformation?.email,
    name: getFullName(accountInformation),
    website_platform: websiteInfo?.platform,
    website_subscription: subscription?.planName ?? 'none',
    website_role: activeWebsite?.userRole,
    website_created_at: moment(websiteInfo?.installDate * 1000).format('MMM Do YYYY, h:mm:ss'),
    user_created_at: moment(accountInformation?.createdDate * 1000).format('MMM Do YYYY, h:mm:ss'),
  };

  setChatwootAttributes(chatwootAttributes);
}

function* handleSubscriptionDetails() {
  yield put(resetRequest('getAllSubscriptions'));
  yield put(resetRequest('getPaymentMethod'));
  yield put(resetRequest('getUpdatePaymentMethodUrl'));
  yield put(resetRequest('updateSubscription'));
  yield put(Actions.standalone.Api.getAllSubscriptions());
  yield take([Types.standalone.Api.GET_ALL_SUBSCRIPTIONS_SUCCEEDED, Types.standalone.Api.GET_ALL_SUBSCRIPTIONS_FAILED]);
  const lastCreatedSubscription = yield select(Selectors.standalone.App.getLastCreatedSubscription);
  const website = yield select(getWebsite);
  if (website?.hasCustomer) {
    yield put(Actions.standalone.Api.getCustomer());
  } else {
    yield put(Actions.standalone.Api.getCustomerFailed());
  }
  const getCustomerAction = yield take([
    Types.standalone.Api.GET_CUSTOMER_SUCCEEDED,
    Types.standalone.Api.GET_CUSTOMER_FAILED,
  ]);
  if (lastCreatedSubscription) {
    if (lastCreatedSubscription.type === SUBSCRIPTION_PROVIDER_TYPE.BRAINTREE) {
      if (getCustomerAction.type === Types.standalone.Api.GET_CUSTOMER_SUCCEEDED) {
        yield put(Actions.standalone.Api.getPaymentMethod());
      }
      yield put(Actions.standalone.Api.getPaymentAuthToken());
    } else {
      yield put(Actions.standalone.Api.getPaymentMethod());
    }
  }
}

function* handleTrafficFilter() {
  const website = yield select(getWebsite);
  const trafficFilter = yield select(getTrafficFilter);
  if (trafficFilter.period === TRAFFIC_FILTER.SPECIFIC_YEAR) {
    if (trafficFilter.specificYear < moment(website.installDate * 1000).year()) {
      yield put(setTrafficFilter(defaultTrafficFilter));
    }
  }
}

function* handleLimitNotifications() {
  const website = yield select(getWebsite);
  const isSumoUser = yield select(Selectors.standalone.App.isSumoUser);
  const websiteId = yield select(getInstanceId);
  const lastSubscription = yield select(Selectors.standalone.App.getLastCreatedSubscription);
  const numberOfMonthlyVisits = website.numberOfMonthlyVisits;
  const featurePermissions = yield select(getApiRequest, 'getFeaturePermissions');
  const limitFromPermissions = featurePermissions && featurePermissions.visits && featurePermissions.visits.limit;
  const limitFromSubscription = yield select((state) => getPlanDetailsById(state, lastSubscription.planId)?.visits);
  const sumoLimit = isSumoUser ? website.sumoLimit : null;
  const limit = limitFromPermissions || limitFromSubscription || sumoLimit;

  const markVisitorCurrentDate = moment().format('YYYY-MM');
  const modalShowed =
    website.frontendFlags &&
    website.frontendFlags[commonModalName.visitsLimitReached + `-${lastDateModalShown}`] === markVisitorCurrentDate;

  if (limit && numberOfMonthlyVisits >= (50 * limit) / 100) {
    if (numberOfMonthlyVisits >= limit) {
      yield put(showBanner(bannerName.LIMIT_REACHED));
      modalShowed
        ? yield spawn(showLimitNotification, 100, 'warning', true, notificationTransKeys.visitsLimitReachedUpgrade)
        : yield put(openModal(commonModalName.visitsLimitReached));
    } else if (numberOfMonthlyVisits >= (90 * limit) / 100) {
      yield spawn(showLimitNotification, 90, 'warning', true);
      setLimitNotificationShown(90, websiteId);
    } else if (numberOfMonthlyVisits >= (75 * limit) / 100) {
      yield spawn(showLimitNotification, 75, 'warning', true);
      setLimitNotificationShown(75, websiteId);
    } else if (numberOfMonthlyVisits >= (50 * limit) / 100) {
      yield spawn(showLimitNotification, 50, 'warning', true);
      setLimitNotificationShown(50, websiteId);
    }
  }
}

export function* resetVisitsLimitNotificationMonthlyFlag() {
  try {
    const website = yield select(getWebsite);
    const markVisitorCurrentDate = moment().format('YYYY-MM');
    const visitsLimitReachedModalShowedInCurrentMonth =
      website.frontendFlags &&
      website.frontendFlags[modalName.visitsLimitReached + `-${lastDateModalShown}`] === markVisitorCurrentDate;
    if (visitsLimitReachedModalShowedInCurrentMonth) {
      yield put(markModalAsViewed(modalName.visitsLimitReached + `-${lastDateModalShown}`, false));
    }
  } catch (e) {
    //
  }
}

function* leaveWebsiteFlow(action) {
  yield put(Actions.standalone.App.startCheckPasswordFlow(modalName.LEAVE_WEBSITE, 'theme-red'));

  const checkLogin = yield take(Types.standalone.App.END_CHECK_PASSWORD_FLOW);

  if (checkLogin.succeeded) {
    yield put(openModal(modalName.LEAVE_WEBSITE));

    yield put(Actions.standalone.Api.leaveWebsite(action.websiteId));
  }
}

function* confirmContributorFlow() {
  const confirmContributor = yield take([Types.standalone.Api.ACCEPT_CONTRIBUTOR_ROLE_REQUEST, CLOSE_MODAL]);

  if (
    confirmContributor.type === Types.standalone.Api.ACCEPT_CONTRIBUTOR_ROLE_REQUEST ||
    confirmContributor.type === CLOSE_MODAL
  ) {
    //Remove pending confirmation info form localStorage.
    LocalStorage.removeItem(storageItems.pendingConfirmation);
    //Remove query string params
    appHistory.replace({ pathname: '/', search: '' });
  }
}

function* checkPasswordFlow(action) {
  yield put(openCheckLoginModal(action.nextModal, action.theme));

  const checkLogin = yield take([Types.standalone.Api.CHECK_LOGIN_SUCCEEDED, CLOSE_MODAL]);

  const succeeded = checkLogin.type === Types.standalone.Api.CHECK_LOGIN_SUCCEEDED;
  if (succeeded) {
    yield put(closeModal(modalName.CHECK_LOGIN));
  }

  yield put(resetRequestStatus(modalName.CHECK_LOGIN));
  yield put(Actions.standalone.App.endCheckPasswordFlow(succeeded));
}

function* handleAnnouncementModalSaga() {
  const accountInformation = yield select(getAccountInformation);
  const activeWebsite = yield select(getActiveWebsiteV2);
  const activeWebsiteInfo = yield select(getWebsite);
  const hasUnlimitedVoucher = yield select(hasUnlimitedVoucherSelector);
  const isWixClaimed = yield select(Selectors.isWixClaimed);
  const wixPermissionPack = yield select(getPermissionPackage);
  const isOnCustomDomain = isUserLoggedInWithCustomUrl();

  const requestStatus = yield select((state) => getRequestStatus(state, 'getAnnouncements'));

  if (requestStatus === apiStatus.IN_PROGRESS) {
    yield take('GET_ANNOUNCEMENTS_SUCCEEDED');
  }

  const announcements = yield select(getAnnouncementsSelector) ?? [];

  // const announcements = dummyAnnouncements; // For manual testing, can be left here for now

  const subscriptionStatus = yield select(getApiRequestStatus, 'getAllSubscriptions');
  let hasPremiumSubscription;

  if (isWixClaimed) {
    hasPremiumSubscription = wixPermissionPack > WixPermissionPack.FREE;
  } else {
    if (subscriptionStatus !== apiStatus.SUCCEEDED && subscriptionStatus !== apiStatus.FAILED) {
      yield take([
        Types.standalone.Api.GET_ALL_SUBSCRIPTIONS_SUCCEEDED,
        Types.standalone.Api.GET_ALL_SUBSCRIPTIONS_FAILED,
      ]);

      const subscription = yield select(getLastCreatedSubscription);
      hasPremiumSubscription = subscription && subscription.status === SubscriptionStatus.ACTIVE;
    }
  }

  if (!accountInformation || !activeWebsite || !activeWebsiteInfo || !announcements) return;

  const { isAgencyOwner, frontendFlags, email } = accountInformation ?? {};
  const { isWebsiteOwner } = activeWebsite ?? {};
  const { numberOfMonthlyVisits, ipCountryISO, installDate, platform } = activeWebsiteInfo ?? {};

  const lastAccountBasedModalViewedAt =
    frontendFlags && frontendFlags[flagKeys.LAST_ACCOUNT_BASED_ANNOUNCEMENT_VIEWED_AT];

  const accountData = {
    isOnCustomDomain,
    isAgencyOwner,
    frontendFlags: frontendFlags ?? {},
    lastAccountBasedModalViewedAt,
    isInternalUser: email === INTERN_ACCOUNT_EMAIL_ADDRESS || isDevelopFeatureEnabled(),
  };

  const websiteData =
    Object.keys(activeWebsite).length === 0
      ? undefined
      : {
          numberOfMonthlyVisits,
          ipCountryISO,
          installDate,
          isWebsiteOwner,
          platform,
          hasPremiumSubscription,
          hasUnlimitedVoucher: hasUnlimitedVoucher || activeWebsite?.unlimitedPlanActive,
        };

  const announcementsToBeDisplayed = getAnnouncementsToBeDisplayed(announcements, accountData, websiteData);

  yield put(setAnnouncementData(announcementsToBeDisplayed));
  if (announcementsToBeDisplayed.length !== 0) {
    yield put(openModal(commonModalName.announcement));
  }
}

function* handleAuthExternal(action) {
  try {
    yield put(Actions.standalone.Api.refreshToken());
    const data = yield call(refreshToken, { refreshToken: action.token });
    addCredentialsToLocalStorage(data);
    window.refreshTokenPromise = null;
    yield put(Actions.standalone.Api.loginSucceeded(data));
    yield put(Actions.standalone.Api.refreshTokenEnded());

    yield put(Actions.standalone.App.initialize());
    //Wait for Initialize process to end in order to know where to redirect.
    yield take(Types.standalone.App.INITIALIZE_SUCCEEDED);
    const defaultRedirectRoute = getLastWebsiteId() ? '/' : pathName.manageWebsites;

    appHistory.push(defaultRedirectRoute);
  } catch (error) {
    window.refreshTokenPromise = null;
    handleExpiredRefreshToken(appHistory.location.pathname);
    yield put(Actions.standalone.Api.refreshTokenEnded());
    appHistory.push('/login');
  }
}

function* refreshSubscriptionDetails() {
  try {
    yield put(getFeaturePermissions());
    yield put(Actions.standalone.Api.getAllSubscriptions());
    const getSubscriptionRequest = yield take([
      Types.standalone.Api.GET_ALL_SUBSCRIPTIONS_SUCCEEDED,
      Types.standalone.Api.GET_ALL_SUBSCRIPTIONS_FAILED,
    ]);
    if (getSubscriptionRequest.type === Types.standalone.Api.GET_ALL_SUBSCRIPTIONS_SUCCEEDED) {
      yield put(Actions.standalone.Api.getPaymentMethod());
    }
  } catch (error) {
    console.error(error);
  }
}
