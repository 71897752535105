import { TEST_IDS } from '@va/constants';
import PropTypes from 'prop-types';
import React from 'react';

const Week = ({ weeks }) => {
  return (
    <div
      className='grid grid-cols-7 py-2 mt-0.5 border-b border-black/10 dark:border-primary-700'
      data-testid={TEST_IDS.generic.datePicker.weekDaysList}
    >
      {weeks.map((day, index) => {
        return (
          <div
            className='text-primary-500 text-xs 2xl:text-sm tracking-wide font-medium text-center cursor-default dark:text-primary-400'
            key={index}
          >
            <span>{day?.replace(/<[^>]+>/g, '')}</span>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(Week);

Week.propTypes = {
  weeks: PropTypes.array,
};
