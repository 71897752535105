import { TEST_IDS } from '@va/constants';
import { BrowserIcon, Device, OS } from '@va/icons';
import { DateSinceCell, Paragraph, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { Flag } from '@va/util/components';
import { addNumberSeparator, getTypeOfVisitor, isWixApp } from '@va/util/helpers';
import React from 'react';

type VisitorInfoProps = {
  isPageHistoryTrackingEnabled?: boolean;
  sessionKey: string;
  pagesVisited: number;
  visitorType: number;
  countryCode: string;
  deviceType: string;
  timestamp: number;
  platform: string;
  browserName: string;
  translate: any;
  locale: string;
  websiteId: string;
};

export const VisitorInfo = ({
  isPageHistoryTrackingEnabled,
  sessionKey,
  pagesVisited,
  visitorType,
  countryCode,
  deviceType,
  timestamp,
  platform,
  browserName,
  translate,
  locale,
  websiteId,
}: VisitorInfoProps) => {
  const pageCountContent = (sessionKey: string, pageCount: number) => {
    const pageCountElement = (
      <Paragraph weight={fontWeights.medium} size={paragraphSizes.tiny} colorClassName='text-white-84'>
        {translate('visited.pagesCount', {
          count: addNumberSeparator(pageCount, locale),
        })}
      </Paragraph>
    );

    if (!isPageHistoryTrackingEnabled) {
      return pageCountElement;
    }

    return (
      <a
        data-testid={TEST_IDS.generic.visitorsMap.liveMapTooltip}
        href={
          isWixApp()
            ? `/visitors/history/${sessionKey}${window.location.search}`
            : `/website/${websiteId}/visitors/history/${sessionKey}`
        }
        className='cursor-pointer flex items-center'
      >
        {pageCountElement}
      </a>
    );
  };

  return (
    <div>
      <div className='flex mb-1 items-center'>
        <Paragraph weight={fontWeights.semibold} size={paragraphSizes.normal} className='text-white'>
          {translate(`card.latestVisits.${getTypeOfVisitor(visitorType)}`)}
        </Paragraph>
        <span className='text-white-84 mx-1'>·</span>
        {pageCountContent(sessionKey, pagesVisited)}
      </div>
      <div className='rounded-md overflow-hidden'>
        <CountryInfo countryCode={countryCode} lastVisitTs={timestamp} translate={translate} />
        <div className='flex justify-end flex-wrap w-full gap-1.5px mt-0.5'>
          <ButtonInfo
            btnText={translate(`panels.latestVisitors.deviceType.${deviceType.toLocaleLowerCase()}`)}
            icon={<Device type={deviceType} className='flex items-center h-15px w-15px' color='#FFFFFF80' />}
          />
          <ButtonInfo btnText={platform} icon={<OS type={platform} className='flex items-center h-15px w-15px' />} />
          <ButtonInfo
            btnText={browserName}
            icon={<BrowserIcon browserName={browserName} className='flex items-center h-15px w-15px' />}
          />
        </div>
      </div>
    </div>
  );
};

type CountryInfoProps = {
  countryCode: string;
  lastVisitTs: number;
  translate: any;
};

const CountryInfo = ({ countryCode, lastVisitTs, translate }: CountryInfoProps) => {
  return (
    <div className='flex gap-1.5px flex-wrap'>
      <div className='flex gap-4.5px bg-black-lighter-25 p-4.5px flex-auto'>
        {countryCode && (
          <Flag
            className='rounded-full h-15px w-15px grow-0 border-1 border-black-dark'
            countryCode={countryCode}
            size='1x1'
          />
        )}
        <Paragraph weight={fontWeights.normal} size={paragraphSizes.tiny} className='text-white !leading-15'>
          {translate(`country.name.${countryCode?.toUpperCase()}`)}
        </Paragraph>
      </div>
      <div className='bg-black-lighter-25 p-4.5px flex-auto'>
        <Paragraph weight={fontWeights.thin} size={paragraphSizes.tiny} className='text-white !leading-15'>
          <DateSinceCell value={lastVisitTs} />
        </Paragraph>
      </div>
    </div>
  );
};

type ButtonInfoProps = { btnText: string; icon: React.ReactNode };

const ButtonInfo = ({ btnText, icon }: ButtonInfoProps) => {
  return (
    <div className='flex items-center bg-black-lighter-25 p-4.5px gap-4.5px flex-auto'>
      <div>{icon}</div>
      <Paragraph size={paragraphSizes.tiny} className='whitespace-nowrap !leading-15'>
        {btnText}
      </Paragraph>
    </div>
  );
};
