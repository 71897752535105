import { TEST_IDS } from '@va/constants';
import PropTypes from 'prop-types';
import React from 'react';

const Header = ({ panel, calendar }) => {
  return (
    <div
      data-testid={TEST_IDS.generic.datePicker.header}
      className='flex justify-between items-center px-2 py-1.5 rounded-md border border-black/10 dark:border-primary-700'
    >
      <div className='shrink-0'>
        <span
          style={{
            display: panel.calendar || panel.year ? 'block' : 'none',
          }}
          className='inline-flex rounded-full'
        >
          <button
            type='button'
            className='btn-class relative p-1.5 rounded-full bg-white transition-colors border border-transparent hover:bg-white-snow focus:bg-primary-50 focus:border-primary focus:ring focus:ring-1 focus:ring-primary focus:ring-opacity-10 focus:outline-none '
            onClick={() => (panel.calendar ? calendar.onPrevious() : calendar.onPreviousYear())}
            data-testid={TEST_IDS.generic.buttons.back}
          >
            <svg
              className='w-5 h-5'
              fill='none'
              stroke='currentColor'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.5'
                d={panel.calendar ? `M15 19l-7-7 7-7` : `M11 19l-7-7 7-7m8 14l-7-7 7-7`}
              ></path>
            </svg>
          </button>
        </span>
      </div>
      <div className='px-1.5 space-x-1.5 flex flex-1'>
        <span className='flex-1 flex rounded-md'>
          <button
            type='button'
            className='btn-class header-btn relative px-3 py-1.5 block w-full leading-relaxed rounded-md bg-white text-xs 2xl:text-sm tracking-wide font-semibold transition-colors border border-transparent uppercase hover:bg-white-snow focus:bg-primary-50 focus:border-primary focus:ring focus:ring-1 focus:ring-primary focus:ring-opacity-10 focus:outline-none btn-class '
            data-testid={TEST_IDS.generic.buttons.select}
            onClick={() => calendar.openMonth()}
          >
            {calendar.month}
          </button>
        </span>
        <span className='flex-1 flex rounded-md'>
          <button
            type='button'
            className='btn-class header-btn relative px-3 py-1.5 block w-full leading-relaxed rounded-md bg-white text-xs 2xl:text-sm tracking-wide font-semibold transition-colors border border-transparent uppercase hover:bg-white-show focus:bg-primary-50 focus:border-primary focus:ring focus:ring-1 focus:ring-primary focus:ring-opacity-10 focus:outline-none '
            onClick={() => calendar.openYear()}
            data-testid={TEST_IDS.generic.buttons.select}
          >
            {calendar.year}
          </button>
        </span>
      </div>
      <div className='shrink-0'>
        <span
          style={{
            display: panel.calendar || panel.year ? 'block' : 'none',
          }}
          className='inline-flex rounded-full'
        >
          <button
            type='button'
            className='btn-class relative p-1.5 rounded-full bg-white transition-colors border border-transparent hover:bg-white-snow focus:bg-primary-50 focus:border-primary focus:ring focus:ring-1 focus:ring-primary focus:ring-opacity-10 focus:outline-none '
            onClick={() => (panel.calendar ? calendar.onNext() : calendar.onNextYear())}
            data-testid={TEST_IDS.generic.buttons.next}
          >
            <svg
              className='w-5 h-5'
              fill='none'
              stroke='currentColor'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='1.5'
                d={panel.calendar ? `M9 5l7 7-7 7` : `M13 5l7 7-7 7M5 5l7 7-7 7`}
              ></path>
            </svg>
          </button>
        </span>
      </div>
    </div>
  );
};
export default React.memo(Header);

Header.propTypes = {
  calendar: PropTypes.object,
  panel: PropTypes.object,
};
