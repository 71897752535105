import { SupportButtons } from '@va/dashboard/components';
import { isTrackingCodeInstalled as isTrackingCodeInstalledSelector } from '@va/dashboard/selectors/app';
import { BackButtonIcon, CheckMarkWithFilledBackground, CollectingDataIcon, GradientCogIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button, Heading5, Paragraph, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { Portal } from '@va/util/components';
import { isMobileDevice } from '@va/util/helpers';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { BACK_BUTTON_CONTAINER_ID } from '../constants';

const TrackingCodeInstalationResultView = ({
  onClickRetryInstallation,
  onOpenDashboardClick,
  onBackButtonClick,
}: {
  onClickRetryInstallation: () => void;
  onOpenDashboardClick?: () => void;
  onBackButtonClick?: () => void;
}) => {
  const isTrackingCodeInstalled = useSelector(isTrackingCodeInstalledSelector);
  const translate = useTranslate();
  const isMobile = isMobileDevice();
  const history = useHistory();

  const title = useMemo(() => {
    if (isTrackingCodeInstalled) return translate('onboarding.trackingCode.installationComplete');
    return translate('onboarding.trackingCode.installationIncomplete');
  }, [isTrackingCodeInstalled, translate]);

  const statusText = useMemo(() => {
    if (isTrackingCodeInstalled) return translate('onboarding.trackingCode.status.collectingData');
    return translate('onboarding.trackingCode.status.notCollectingData');
  }, [isTrackingCodeInstalled, translate]);

  const icon = useMemo(() => {
    if (isTrackingCodeInstalled) return <CheckMarkWithFilledBackground />;
    return <GradientCogIcon color='#FA3B19' />;
  }, [isTrackingCodeInstalled]);

  const infoText = useMemo(() => {
    if (isTrackingCodeInstalled) return translate('onboarding.trackingCode.success.note');
    return translate('onaboarding.trackingCode.error.note');
  }, [isTrackingCodeInstalled, translate]);

  const openDashboard = useCallback(() => {
    history.push('/');
    onOpenDashboardClick?.();
  }, [history, onOpenDashboardClick]);

  return (
    <div className='flex justify-center relative mt-6 px-18px'>
      {!isMobile && (
        <Button
          className='!absolute left-18px top-18px -translate-y-1/2'
          color='tertiary'
          icon={(_, color) => <BackButtonIcon color={color} />}
          onClick={onBackButtonClick}
        />
      )}
      {isMobile && (
        <Portal elementSelector={`#${BACK_BUTTON_CONTAINER_ID}`}>
          <Button color='tertiary' icon={(_, color) => <BackButtonIcon color={color} />} onClick={onBackButtonClick} />
        </Portal>
      )}
      <div className='text-center max-w-[540px] mt-[21px]'>
        <div
          className={classNames(
            'flex items-center justify-center w-[84px] h-[84px] rounded-full border-[1.5px] border-black-lighter-13 mb-6 mx-auto',
            {
              'bg-red-pomegranate/10': !isTrackingCodeInstalled,
              'bg-[#06A25A]/10': isTrackingCodeInstalled,
            },
          )}
        >
          {icon}
        </div>
        <Heading5
          className='text-center mb-1.5'
          colorClassName={classNames({
            'text-red-pomegranate': !isTrackingCodeInstalled,
            'text-positive': isTrackingCodeInstalled,
          })}
          weight={fontWeights.semibold}
        >
          {title}
        </Heading5>
        <Paragraph colorClassName='text-black-lighter-84' weight={fontWeights.medium}>
          {infoText}
        </Paragraph>
        <div className='flex items-center justify-center gap-2 my-3'>
          <CollectingDataIcon
            className={classNames({
              'animate-[spin_3s_linear_infinite]': isTrackingCodeInstalled,
            })}
            color={isTrackingCodeInstalled ? 'var(--color-positive)' : 'var(--color-negative)'}
          />
          <Paragraph
            size={paragraphSizes.large}
            colorClassName={classNames({
              'text-positive': isTrackingCodeInstalled,
              'text-negative': !isTrackingCodeInstalled,
            })}
            weight={fontWeights.medium}
          >
            {statusText}
          </Paragraph>
        </div>
        <Button
          onClick={isTrackingCodeInstalled ? openDashboard : onClickRetryInstallation}
          className='mt-18px mb-3 w-full'
          text={
            isTrackingCodeInstalled
              ? translate('onboarding.trackingCode.openDashboard')
              : translate('onboarding.trackingCode.retryTrackingCodeInstallation')
          }
        />
        {!isTrackingCodeInstalled && (
          <Button
            onClick={openDashboard}
            className='w-full'
            color='tertiary'
            text={translate('onboarding.trackingCode.installLater')}
          />
        )}
        <Paragraph className='mb-3 mt-12'>{translate('onboarding.trackingCode.message.support')}</Paragraph>
        <SupportButtons />
      </div>
    </div>
  );
};

export default TrackingCodeInstalationResultView;
