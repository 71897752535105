import { reportType } from '@va/constants';
import { ReportTypeEnum } from '@va/dashboard/feature/general-settings';
import { getNotificationsCardOptions } from '@va/dashboard/selectors/core';
import { useTranslate } from '@va/localization';
import { isWixApp } from '@va/util/helpers';
import { DashboardAppWorker } from '@va/util/misc';
import { useFormik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateNewsletterSettings } from './useUpdateNewsletterSettings';

export type NewsletterSettingsFormFieldsType = {
  reportType: ReportTypeEnum;
  newsletter: boolean;
};

export enum NewsletterSettingsFormFields {
  reportType = 'reportType',
  newsletter = 'newsletter',
}

type UseNewsletterSettingsParams = {
  onSuccess?: () => void;
  initialFormValues?: NewsletterSettingsFormFieldsType;
};

export const useNewsletterSettings = ({ initialFormValues, onSuccess }: UseNewsletterSettingsParams = {}) => {
  const translate = useTranslate();
  const frequencyOptions = useSelector(getNotificationsCardOptions).data;
  const { currentSubscription, isSubscriptionLoading, isReportsLoading, reportsSettings, updateNewsletterSettings } =
    useUpdateNewsletterSettings();

  const isNLSubscribed = useMemo(
    () =>
      isWixApp()
        ? !!(currentSubscription?.wixNewsletter && currentSubscription?.wixPromotions)
        : !!(currentSubscription?.standaloneNewsletter && currentSubscription?.standalonePromotions),
    [
      currentSubscription?.standaloneNewsletter,
      currentSubscription?.standalonePromotions,
      currentSubscription?.wixNewsletter,
      currentSubscription?.wixPromotions,
    ],
  );

  const formValues: NewsletterSettingsFormFieldsType = useMemo(
    () => ({
      reportType: initialFormValues?.reportType ?? reportsSettings?.reportType ?? ReportTypeEnum.never,
      newsletter: initialFormValues?.newsletter ?? isNLSubscribed,
    }),
    [initialFormValues?.newsletter, initialFormValues?.reportType, isNLSubscribed, reportsSettings?.reportType],
  );

  const { values, setFieldValue, handleSubmit, setValues } = useFormik({
    onSubmit: async (values) => {
      updateNewsletterSettings(values, onSuccess);
    },
    initialValues: formValues,
    enableReinitialize: true,
  });

  const onSubscriptionChange = useCallback(
    async (val: { name: string; value: boolean }) => {
      await setFieldValue(val.name, val.value);
      DashboardAppWorker.trackEvent({
        action: 'onchange',
        category: 'toggle',
        label: 'Newsletter',
        value: val.value.toString(),
      });
    },
    [setFieldValue],
  );

  const onReportsChange = useCallback(
    (val: { name: string; value: string | number | boolean }) => {
      const { name, value } = val;
      setValues((prev) => ({ ...prev, [name]: value }));
    },
    [setValues],
  );

  const getFrequency = useCallback(() => {
    const { DAILY, WEEKLY, MONTHLY } = reportType;
    const opt = [DAILY, WEEKLY, MONTHLY];
    return frequencyOptions
      .filter((option) => opt.includes(option.value))
      .map((option) => ({
        label: translate(`form.emailReports.${option.type}`),
        value: option.value,
        disabled: values.reportType === ReportTypeEnum.never,
        className: 'h-[30px] rounded-md grow',
      }));
  }, [values.reportType, frequencyOptions, translate]);

  const selectedFrequency = useMemo(() => {
    const frequency = getFrequency();
    const selected = frequency.find((val) => val.value === values.reportType);
    return selected?.value ?? ReportTypeEnum.never;
  }, [getFrequency, values.reportType]);

  return {
    onSubscriptionChange,
    isNLSubscribed,
    getFrequency,
    selectedFrequency,
    onReportsChange,
    isSubscriptionLoading,
    isReportsLoading,
    handleSubmit,
    values,
  };
};
