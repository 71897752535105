import { TestAttributes } from '@va/types/component';
import { TooltipOptions } from '@va/types/tooltip';
import { TooltipWrapper } from '@va/ui/tooltips';
import { useCheckOverflow } from '@va/util/hooks';
import classNames from 'classnames';
import { CSSProperties, FC, PropsWithChildren, ReactNode, forwardRef, useRef } from 'react';
import { fontWeightClassName, fontWeights, paragraphSizeClassNames, paragraphSizes } from '.';

export type ParagraphProps = TestAttributes & {
  id?: string;
  weight?: fontWeights;
  colorClassName?: string;
  size?: paragraphSizes;
  className?: string;
  children: ReactNode;
  style?: CSSProperties;
  onClick?: () => void;
  onMouseOver?: () => void;
};

export const Paragraph = forwardRef<HTMLParagraphElement, PropsWithChildren<ParagraphProps>>(
  (
    {
      id,
      weight = fontWeights.normal,
      colorClassName,
      size = paragraphSizes.normal,
      className,
      children,
      style,
      onClick,
      onMouseOver,
      ...rest
    },
    ref,
  ) => {
    const classes = classNames(
      'font-primary',
      fontWeightClassName(weight),
      paragraphSizeClassNames(size),
      colorClassName,
      className,
    );

    return (
      <p {...rest} id={id} ref={ref} style={style} className={classes} onClick={onClick} onMouseOver={onMouseOver}>
        {children}
      </p>
    );
  },
);

export const ParagraphWithTooltip: FC<
  ParagraphProps & {
    tooltipProps?: TooltipOptions;
  }
> = ({
  id,
  weight = fontWeights.normal,
  colorClassName,
  size = paragraphSizes.normal,
  className,
  children,
  style,
  onClick,
  tooltipProps,
  'data-testid': dataTestId,
}) => {
  const ref = useRef<HTMLParagraphElement>(null);
  const isOverflowing = useCheckOverflow(ref);

  const classes = classNames(
    'font-primary truncate',
    fontWeightClassName(weight),
    paragraphSizeClassNames(size),
    colorClassName,
    className,
  );

  return (
    <TooltipWrapper content={children} disabled={!isOverflowing} ref={ref} {...tooltipProps}>
      <p id={id} style={style} className={classes} onClick={onClick} data-testid={dataTestId}>
        {children}
      </p>
    </TooltipWrapper>
  );
};
