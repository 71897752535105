import { CodeEditorWithCopyBtn } from '@va/dashboard/ui/components';
import { CheckMarkWithFilledBackground } from '@va/svg-visa-icons';
import { Chip, fontWeights, Paragraph } from '@va/ui/design-system';

type TrackingCodeSectionProps = {
  description: string;
  trackingCode: string;
  chips?: { label: string; tooltip?: string }[];
};

export const TrackingCodeSection = ({ description, trackingCode, chips }: TrackingCodeSectionProps) => {
  return (
    <div className='flex flex-col gap-2 bg-black-lighter-05 rounded-t-24 rounded-b-36px p-18px'>
      <div className='px-1.5'>
        <Paragraph weight={fontWeights.medium} colorClassName={'text-gray-charcoal'} className={'leading-24'}>
          {description}
        </Paragraph>
      </div>
      {chips && (
        <div className='flex flex-wrap md:flex-nowrap gap-1.5 w-full overflow-hidden'>
          {chips.map(({ label, tooltip }, index) => (
            <Chip
              key={index}
              className='!bg-white !rounded-15 !p-1.5 !pr-3 md:overflow-hidden'
              icon={<CheckMarkWithFilledBackground className='w-18px h-18px' bgColor='#09AF5C' />}
              tooltip={tooltip}
            >
              {label}
            </Chip>
          ))}
        </div>
      )}
      <CodeEditorWithCopyBtn editorClassName='!bg-white' code={trackingCode} />
    </div>
  );
};
