import classNames from 'classnames';

export const GradientBuildingIcon = ({
  className,
  animated = false,
  ...props
}: { animated?: boolean } & JSX.IntrinsicElements['svg']) => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classNames('rounded-full', { 'animated-building-icon': animated }, className)}
      {...props}
    >
      <path
        className={classNames({ 'animated-building-icon__line': animated })}
        d='M5.59961 8.83867C4.6055 8.83867 3.79961 9.64456 3.79961 10.6387C3.79961 11.6328 4.6055 12.4387 5.59961 12.4387V8.83867ZM5.59961 12.4387H34.3996V8.83867H5.59961V12.4387Z'
        fill='url(#paint0_linear_4958_65260)'
      />
      <circle cx='20.0002' cy='19.9992' r='18' stroke='url(#paint1_linear_4958_65260)' strokeWidth='3.6' />
      <mask id='path-3-inside-1_4958_65260' fill='white'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M29.0002 37.6422C35.4112 34.3649 39.8001 27.6956 39.8001 20.0012C39.8001 19.3945 39.7729 18.7943 39.7195 18.2016H29.0002V37.6422ZM27.2002 38.4514V14.6016H12.8002V38.4514C15.0317 39.3229 17.4601 39.8012 20.0001 39.8012C22.5403 39.8012 24.9687 39.3229 27.2002 38.4514ZM0.200195 20.0456C0.217049 27.7213 4.60149 34.3713 11.0002 37.6422V18.2016H0.280829C0.228731 18.7798 0.201494 19.3652 0.200195 19.9568V20.0456Z'
        />
      </mask>
      <path
        d='M29.0002 37.6422H25.4002V43.5256L30.6388 40.8476L29.0002 37.6422ZM39.7195 18.2016L43.3049 17.8785L43.0097 14.6016H39.7195V18.2016ZM29.0002 18.2016V14.6016H25.4002V18.2016H29.0002ZM27.2002 14.6016H30.8002V11.0016H27.2002V14.6016ZM27.2002 38.4514L28.5098 41.8047L30.8002 40.9102V38.4514H27.2002ZM12.8002 14.6016V11.0016H9.2002V14.6016H12.8002ZM12.8002 38.4514H9.2002V40.9103L11.4906 41.8048L12.8002 38.4514ZM11.0002 37.6422L9.36159 40.8477L14.6002 43.5256V37.6422H11.0002ZM0.200195 20.0456H-3.39981L-3.3998 20.0535L0.200195 20.0456ZM11.0002 18.2016H14.6002V14.6016H11.0002V18.2016ZM0.280829 18.2016V14.6016H-3.00941L-3.30465 17.8785L0.280829 18.2016ZM0.200195 19.9568L-3.3998 19.9489V19.9568H0.200195ZM36.2001 20.0012C36.2001 26.2914 32.6158 31.7508 27.3616 34.4367L30.6388 40.8476C38.2065 36.979 43.4001 29.0997 43.4001 20.0012H36.2001ZM36.134 18.5246C36.1777 19.0101 36.2001 19.5025 36.2001 20.0012H43.4001C43.4001 19.2866 43.368 18.5785 43.3049 17.8785L36.134 18.5246ZM39.7195 14.6016H29.0002V21.8016H39.7195V14.6016ZM25.4002 18.2016V37.6422H32.6002V18.2016H25.4002ZM23.6002 14.6016V38.4514H30.8002V14.6016H23.6002ZM12.8002 18.2016H27.2002V11.0016H12.8002V18.2016ZM16.4002 38.4514V14.6016H9.2002V38.4514H16.4002ZM20.0001 36.2012C17.9153 36.2012 15.9306 35.8091 14.1098 35.098L11.4906 41.8048C14.1328 42.8366 17.0048 43.4012 20.0001 43.4012V36.2012ZM25.8906 35.098C24.0697 35.8091 22.085 36.2012 20.0001 36.2012V43.4012C22.9955 43.4012 25.8676 42.8366 28.5098 41.8047L25.8906 35.098ZM12.6388 34.4368C7.39459 31.756 3.81396 26.3124 3.80019 20.0377L-3.3998 20.0535C-3.37987 29.1302 1.80839 36.9866 9.36159 40.8477L12.6388 34.4368ZM7.4002 18.2016V37.6422H14.6002V18.2016H7.4002ZM0.280829 21.8016H11.0002V14.6016H0.280829V21.8016ZM3.80019 19.9647C3.80125 19.4785 3.82364 18.9982 3.86631 18.5246L-3.30465 17.8785C-3.36617 18.5614 -3.39827 19.252 -3.3998 19.9489L3.80019 19.9647ZM3.8002 20.0456V19.9568H-3.3998V20.0456H3.8002Z'
        fill='url(#paint2_linear_4958_65260)'
        mask='url(#path-3-inside-1_4958_65260)'
      />
      <path
        opacity='0.25'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.0002 37.6414C35.4112 34.3641 39.8001 27.6948 39.8001 20.0004C39.8001 19.3939 39.7729 18.7938 39.7195 18.2012H29.0002V37.6414ZM11.0002 37.6415V18.2012H0.280794C0.228719 18.7793 0.201494 19.3646 0.200195 19.956V20.0448C0.217049 27.7205 4.60149 34.3705 11.0002 37.6415Z'
        fill='url(#paint3_linear_4958_65260)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_4958_65260'
          x1='5.59961'
          y1='11.1418'
          x2='34.3996'
          y2='11.1418'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF6A00' stopOpacity='0' />
          <stop offset='1' stopColor='#6B11C5' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_4958_65260'
          x1='0.200195'
          y1='20.1238'
          x2='39.8002'
          y2='20.1238'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF6A00' />
          <stop offset='1' stopColor='#6B11C5' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_4958_65260'
          x1='0.200195'
          y1='27.2806'
          x2='39.8001'
          y2='27.2806'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF6A00' />
          <stop offset='1' stopColor='#6B11C5' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_4958_65260'
          x1='0.200195'
          y1='27.9825'
          x2='39.8001'
          y2='27.9825'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF6A00' />
          <stop offset='1' stopColor='#6B11C5' />
        </linearGradient>
      </defs>
    </svg>
  );
};
