export const GradientUserIcon = (props: JSX.IntrinsicElements['svg']) => {
  return (
    <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M30.6004 26.2C28.8004 24.4 26.8004 23.2 24.4004 22.2C27.8004 20.2 30.0004 16.4 30.0004 12C30.0004 10.8 29.8004 9.4 29.4004 8.2C27.8004 3.4 23.4004 0 18.0004 0C12.6004 0 8.20039 3.4 6.60039 8.2C6.20039 9.4 6.00039 10.8 6.00039 12C6.00039 16.2 8.20039 20 11.6004 22.2C9.20039 23 7.20039 24.4 5.40039 26.2C4.60039 27 4.00039 27.8 3.40039 28.6C4.00039 29.4 4.60039 30.2 5.40039 31C8.60039 34 13.2004 36 18.0004 36C22.8004 36 27.4004 34 30.6004 30.8C31.4004 30 32.0004 29.2 32.6004 28.4C32.0004 27.6 31.4004 26.8 30.6004 26.2ZM9.00039 12C9.00039 7.4 12.6004 3.6 17.0004 3C17.4004 3 17.6004 3 18.0004 3C18.4004 3 18.6004 3 19.0004 3C23.4004 3.4 27.0004 7.4 27.0004 12C27.0004 17 23.0004 21 18.0004 21C13.0004 21 9.00039 17 9.00039 12ZM18.0004 33C13.8004 33 10.0004 31.2 7.20039 28.6C10.0004 25.8 13.8004 24 18.0004 24C22.2004 24 26.0004 25.8 28.6004 28.4C26.0004 31.2 22.2004 33 18.0004 33Z'
        fill='url(#paint0_linear_4958_79821)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_4958_79821'
          x1='3.40039'
          y1='18.1132'
          x2='32.6004'
          y2='18.1132'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF6A00' />
          <stop offset='1' stopColor='#6B11C5' />
        </linearGradient>
      </defs>
    </svg>
  );
};
