import { apiRequestResponseMsg, validationTranslationKeys } from '@va/constants';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { SelectDropdownOption } from '@va/ui/design-system';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import {
  useAddAlarmingBehaviorSettingsEmail,
  useGetAlarmingBehaviorSettings,
  useRemoveAlarmingBehaviorSettingsEmail,
  useResendAlarmingBehaviorSettingsEmail,
  useUpdateAlarmingBehaviorSettings,
} from '../apiClient';

const emailSchema = yup.string().email();

export const numberOfErrorsAndEventsOptions: SelectDropdownOption<number>[] = [
  { label: '1', value: 1 },
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '30', value: 30 },
  { label: '60', value: 60 },
  { label: '100', value: 100 },
  { label: '1000', value: 1000 },
  { label: '10000', value: 10000 },
  { label: '100000', value: 100000 },
];

// Weeks in hours
const ONE_WEEK = 168;
const TWO_WEEKS = 336;
const FOUR_WEEKS = 672;

export type ErrorAlertsThresholdObjectType = {
  limit: number;
  interval: number;
};

const defaultFormValues: FormValuesType = {
  abeActivated: false,
  abeWebsiteErrorsThreshold: {
    limit: undefined,
    interval: undefined,
  },
  abeNegativeEventsThreshold: {
    limit: undefined,
    interval: undefined,
  },
};

type FormValuesType = {
  abeActivated: boolean;
  abeWebsiteErrorsThreshold: Partial<ErrorAlertsThresholdObjectType>;
  abeNegativeEventsThreshold: Partial<ErrorAlertsThresholdObjectType>;
};

const useErrorAlertsPreferences = () => {
  const translate = useTranslate();
  const [emailAddress, setEmailAddress] = useState('');
  const [formError, setFormError] = useState<string>('');
  const [formValues, setFormValues] = useState<FormValuesType>(defaultFormValues);
  const { showSuccessNotification, showErrorNotification } = useAddNotification();

  const {
    data: alarmingBehaviorSettings,
    isLoading: isAlarmingBehaviorSettingsLoading,
    mutate: mutateAlarmingBehaviorSettings,
  } = useGetAlarmingBehaviorSettings();

  const allEmails = useMemo(() => alarmingBehaviorSettings?.payload.abeEmailList ?? [], [alarmingBehaviorSettings]);

  const { execute: addEmail } = useAddAlarmingBehaviorSettingsEmail();
  const { execute: removeEmail } = useRemoveAlarmingBehaviorSettingsEmail();
  const { execute: resendEmailConfirmation } = useResendAlarmingBehaviorSettingsEmail();
  const { execute: updateAlarmingBehaviorSettings, isSucceeded: isAlarmingBehaviorSettingsUpdateSucceeded } =
    useUpdateAlarmingBehaviorSettings();

  const trackingIntervalOptions: SelectDropdownOption<number>[] = useMemo(
    () => [
      { label: translate('all.oneHour'), value: 1 },
      { label: translate('all.hoursCount', { hours: 2 }), value: 2 },
      { label: translate('all.hoursCount', { hours: 5 }), value: 5 },
      { label: translate('all.hoursCount', { hours: 12 }), value: 12 },
      { label: translate('all.hoursCount', { hours: 24 }), value: 24 },
      { label: translate('all.hoursCount', { hours: 48 }), value: 48 },
      { label: translate('all.oneWeek'), value: ONE_WEEK },
      { label: translate('all.weeksCount', { weeks: 2 }), value: TWO_WEEKS },
      { label: translate('all.weeksCount', { weeks: 4 }), value: FOUR_WEEKS },
    ],
    [translate],
  );

  // Populate initial state for the form values
  useEffect(() => {
    if (!alarmingBehaviorSettings) return;
    setFormValues({
      abeActivated: alarmingBehaviorSettings.payload.abeActivated,
      abeWebsiteErrorsThreshold:
        alarmingBehaviorSettings.payload.abeWebsiteErrorsThreshold ?? defaultFormValues.abeWebsiteErrorsThreshold,
      abeNegativeEventsThreshold:
        alarmingBehaviorSettings.payload.abeNegativeEventsThreshold ?? defaultFormValues.abeNegativeEventsThreshold,
    });
  }, [alarmingBehaviorSettings]);

  const thresholdDropdownValues = useMemo(() => {
    const abeWebsiteErrorsThreshold = {
      limit: numberOfErrorsAndEventsOptions.find(({ value }) => value === formValues.abeWebsiteErrorsThreshold.limit),
      interval: trackingIntervalOptions.find(({ value }) => value === formValues.abeWebsiteErrorsThreshold.interval),
    };

    const abeNegativeEventsThreshold = {
      limit: numberOfErrorsAndEventsOptions.find(({ value }) => value === formValues.abeNegativeEventsThreshold.limit),
      interval: trackingIntervalOptions.find(({ value }) => value === formValues.abeNegativeEventsThreshold.interval),
    };

    return {
      abeWebsiteErrorsThreshold,
      abeNegativeEventsThreshold,
    };
  }, [
    formValues.abeNegativeEventsThreshold.interval,
    formValues.abeNegativeEventsThreshold.limit,
    formValues.abeWebsiteErrorsThreshold.interval,
    formValues.abeWebsiteErrorsThreshold.limit,
    trackingIntervalOptions,
  ]);

  const isSaveEmailButtonDisabled = useMemo(
    () => isAlarmingBehaviorSettingsLoading || isAlarmingBehaviorSettingsUpdateSucceeded,
    [isAlarmingBehaviorSettingsLoading, isAlarmingBehaviorSettingsUpdateSucceeded],
  );

  const clearEmailField = useCallback(() => {
    setEmailAddress('');
  }, []);

  const emailError = useCallback(
    (email: string) => {
      const isValid = emailSchema.isValidSync(email);
      if (!isValid) return translate(validationTranslationKeys.invalidEmail);
      return null;
    },
    [translate],
  );

  const onChangeEmail = useCallback(
    (value: string) => {
      const error = emailError(value);
      if (error) {
        setFormError(error);
      } else {
        setFormError('');
      }
      setEmailAddress(value);
    },
    [emailError],
  );

  const handleSaveEmailError = useCallback(
    (error: string | undefined) => {
      if (error === apiRequestResponseMsg.ALREADY_EXISTING_EMAIL) {
        showErrorNotification(translate('all.generalSettings.errorAlertsSettings.notification.emailAlreadyExists'));
        return;
      }
      showErrorNotification();
    },
    [showErrorNotification, translate],
  );

  const onSaveEmail = useCallback(async () => {
    try {
      if (!emailAddress) return;
      const error = emailError(emailAddress);
      if (error) {
        setFormError(error);
        return;
      } else {
        setFormError('');
      }
      await addEmail({ email: emailAddress });
      clearEmailField();
      showSuccessNotification(3000, translate('all.generalSettings.errorAlertsSettings.notification.addEmail'));
      mutateAlarmingBehaviorSettings();
    } catch (err: any) {
      handleSaveEmailError(err?.message);
    }
  }, [
    addEmail,
    clearEmailField,
    emailAddress,
    emailError,
    handleSaveEmailError,
    mutateAlarmingBehaviorSettings,
    showSuccessNotification,
    translate,
  ]);

  const onRemoveEmail = useCallback(
    async (email: string) => {
      try {
        await removeEmail({ email });
        mutateAlarmingBehaviorSettings();
        showSuccessNotification();
      } catch (err) {
        showErrorNotification();
      }
    },
    [mutateAlarmingBehaviorSettings, removeEmail, showErrorNotification, showSuccessNotification],
  );

  const onResendEmailConfirmation = useCallback(
    async (email: string) => {
      try {
        await resendEmailConfirmation({ email });
        mutateAlarmingBehaviorSettings();
        showSuccessNotification(3000, translate('all.generalSettings.errorAlertsSettings.notification.resendEmail'));
      } catch (err) {
        showErrorNotification();
      }
    },
    [
      mutateAlarmingBehaviorSettings,
      resendEmailConfirmation,
      showErrorNotification,
      showSuccessNotification,
      translate,
    ],
  );

  const handleActivateToggleChange = useCallback(
    async (value: boolean) => {
      try {
        setFormValues((prev) => ({ ...prev, abeActivated: value }));
        await updateAlarmingBehaviorSettings({ abeActivated: value });
        mutateAlarmingBehaviorSettings();
        showSuccessNotification();
      } catch (error) {
        showErrorNotification();
      }
    },
    [mutateAlarmingBehaviorSettings, showErrorNotification, showSuccessNotification, updateAlarmingBehaviorSettings],
  );

  const handleThresholdChange = useCallback(
    async (
      key: 'abeWebsiteErrorsThreshold' | 'abeNegativeEventsThreshold',
      value: Partial<ErrorAlertsThresholdObjectType>,
    ) => {
      try {
        const updatedValue = {
          [key]: {
            ...formValues[key],
            ...value,
          },
        };

        setFormValues((prev) => ({
          ...prev,
          ...updatedValue,
        }));
        await updateAlarmingBehaviorSettings(updatedValue);
        mutateAlarmingBehaviorSettings();
        showSuccessNotification();
      } catch (error) {
        showErrorNotification();
      }
    },
    [
      formValues,
      mutateAlarmingBehaviorSettings,
      showErrorNotification,
      showSuccessNotification,
      updateAlarmingBehaviorSettings,
    ],
  );

  return {
    allEmails,
    emailAddress,
    isAlarmingBehaviorSettingsUpdateSucceeded,
    isAlarmingBehaviorSettingsLoading,
    isSaveEmailButtonDisabled,
    formValues,
    thresholdDropdownValues,
    formError,
    onSaveEmail,
    onChangeEmail,
    onRemoveEmail,
    onResendEmailConfirmation,
    trackingIntervalOptions,
    numberOfErrorsAndEventsOptions,
    handleActivateToggleChange,
    handleThresholdChange,
  };
};

export default useErrorAlertsPreferences;
