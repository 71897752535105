import { TooltipOptions } from '@va/types/tooltip';
import { fontWeights, Paragraph, paragraphSizes, ParagraphWithTooltip } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';

type ChipProps = PropsWithChildren<{
  icon: ReactNode;
  className?: string;
  tooltip?: string;
  tooltipProps?: TooltipOptions;
  onClick?: () => void;
  size?: 'small' | 'medium';
}>;

export const Chip = ({ icon, children, className, onClick, size = 'medium', tooltip, tooltipProps }: ChipProps) => {
  return (
    <div
      onClick={onClick}
      className={classNames('flex rounded-md items-center gap-2 bg-white-snow w-auto', className, {
        'py-1 px-1.5': size === 'small',
        'p-2': size === 'medium',
      })}
    >
      {icon}
      {tooltip ? (
        <TooltipWrapper content={tooltip} trigger='click' {...tooltipProps}>
          <Paragraph
            weight={fontWeights.medium}
            size={paragraphSizes.tiny}
            className='underline underline-offset-2 decoration-dotted decoration-black-lighter-84'
          >
            {children}
          </Paragraph>
        </TooltipWrapper>
      ) : (
        <ParagraphWithTooltip weight={fontWeights.medium} size={paragraphSizes.tiny}>
          {children}
        </ParagraphWithTooltip>
      )}
    </div>
  );
};
