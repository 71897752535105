import { CloseIconWithCircle, FilledCheckIcon } from '@va/icons';
import classNames from 'classnames';

export const StepStatusIcon = ({
  hasError,
  isActive,
  isCompleted,
  index,
  className,
  onClick,
}: {
  index: number;
  hasError: boolean;
  isActive: boolean;
  isCompleted: boolean;
  className?: string;
  onClick?: () => void;
}) => {
  if (hasError)
    return (
      <CloseIconWithCircle
        className={classNames('shrink-0 w-6 h-6', className)}
        color='var(--color-negative)'
        onClick={onClick}
      />
    );
  if (isCompleted)
    return (
      <FilledCheckIcon
        className={classNames('shrink-0 w-6 h-6', className)}
        color='var(--color-primary)'
        onClick={onClick}
      />
    );

  return (
    <div
      className={classNames(
        'font-bold flex items-center justify-center shrink-0 rounded-full text-white w-6 h-6 text-xs leading-3',
        className,
        {
          'bg-gray-geyser !w-18px !h-18px my-[3px]': !isActive,
          'bg-primary': isActive,
        },
      )}
      onClick={onClick}
    >
      {index + 1}
    </div>
  );
};
