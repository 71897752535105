import AccountSettingsRequiredModal from '@va/dashboard/shared/modals/AccountSettingsRequiredModal';
import Modals from '@va/dashboard/shared/modals/index';
import SsrTechnicalLimitation from '@va/dashboard/shared/modals/SsrTechnicalLimitation/SsrTechnicalLimitation';
import UpdateCompanyDetailsModal from '@va/dashboard/shared/modals/UpdateCompanyDetailsModal';
import { emailAction, modalName } from '@va/standalone/shared/constants';
import { getParameterByName } from '@va/util/helpers';
import ChangeEmailConfirmationContainer from 'standalone/components/AccountSettings/ConfirmUpdateEmail/ConfirmUpdateEmailContainer';

import { useTranslate } from '@va/localization';
import DeleteAccountModal from '../AccountSettings/account-details/DeleteAccountModal';
import ChangeEmailContainer from '../AccountSettings/ChangeEmailCard';
import CheckPasswordModal from '../AccountSettings/check-password/CheckPasswordModal';
import FeedbackModal from '../cards/FeedbackModal';
import AddCreditCard from './AddCreditCard';
import BusinessInfo from './business-info';
import CancelSubscription from './CancelSubscription';
import CommissionPayouts from './commission-payouts';
import CreateEventTag from './create-events-tag/CreateEventTag';
import DeleteSubscriptionModal from './DeleteSubscription';
import GoogleImport from './GoogleImport';
import ReactivateSubscription from './ReactivateSubscription';
import UpdateCardDetails from './UpdateCardDetails';
import UpgradeModal from './UpgradeModal';
import WhiteLabelWebsites from './white-label-websites';

const StandaloneModals = () => {
  const actionParam = getParameterByName('emailAction');
  const translate = useTranslate();
  return (
    <>
      <CancelSubscription />
      <DeleteSubscriptionModal />
      <ReactivateSubscription />
      <AddCreditCard />
      <UpdateCardDetails />
      <UpgradeModal />
      <Modals.SignDPA dpaLink={translate('all.privacyCenter.legalDocuments.dpaLink')} />
      <GoogleImport />
      <WhiteLabelWebsites />
      <AccountSettingsRequiredModal />
      <Modals.AnnouncementModal />

      <Modals.ManualScreenshotContainer />
      <UpdateCompanyDetailsModal />
      <Modals.VideoOverlay />
      <SsrTechnicalLimitation />
      <Modals.FeatureLimitReached />
      <Modals.SsrReachedLimit />
      <Modals.VisitsLimitReached />
      <Modals.TabInformation />
      <Modals.FreeTrialEndingModal />
      <CheckPasswordModal />
      <ChangeEmailContainer />
      <DeleteAccountModal />
      <CommissionPayouts />
      <BusinessInfo />
      {/* <CancelAgencySubscription /> */}
      <FeedbackModal name={modalName.ACCEPT_CONTRIBUTOR_REQUEST} />
      <FeedbackModal name={modalName.REJECT_CONTRIBUTOR_REQUEST} />
      <FeedbackModal name={modalName.LEAVE_WEBSITE} colorTheme='theme-red' />
      <CreateEventTag />
      {actionParam && (
        <ChangeEmailConfirmationContainer
          isConfirmUpdateEmail={actionParam === emailAction.CONFIRM}
          isResetEmail={actionParam === emailAction.RESET}
        />
      )}
    </>
  );
};

export default StandaloneModals;
