export const GradientEnvelopeIcon = (props: JSX.IntrinsicElements['svg']) => {
  return (
    <svg width='40' height='36' viewBox='0 0 40 36' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect
        x='2.0002'
        y='5.39961'
        width='36'
        height='25.2'
        rx='5.4'
        stroke='url(#paint0_linear_4958_9776)'
        strokeWidth='3.6'
      />
      <path
        opacity='0.5'
        d='M25.4004 16.8008L36.2004 27.6008'
        stroke='url(#paint1_linear_4958_9776)'
        strokeWidth='3.6'
      />
      <path opacity='0.5' d='M3.7998 28.8L14.5998 18' stroke='url(#paint2_linear_4958_9776)' strokeWidth='3.6' />
      <path
        d='M2 10.8008L16.2956 19.3782C18.5757 20.7462 21.4243 20.7462 23.7044 19.3782L38 10.8008'
        stroke='url(#paint3_linear_4958_9776)'
        strokeWidth='3.6'
      />
      <defs>
        <linearGradient
          id='paint0_linear_4958_9776'
          x1='0.200195'
          y1='18.0902'
          x2='39.8002'
          y2='18.0902'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF6A00' />
          <stop offset='1' stopColor='#6B11C5' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_4958_9776'
          x1='25.4004'
          y1='22.2347'
          x2='36.2004'
          y2='22.2347'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF6A00' />
          <stop offset='1' stopColor='#6B11C5' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_4958_9776'
          x1='3.7998'
          y1='23.434'
          x2='14.5998'
          y2='23.434'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF6A00' />
          <stop offset='1' stopColor='#6B11C5' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_4958_9776'
          x1='2'
          y1='16.2347'
          x2='38'
          y2='16.2347'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF6A00' />
          <stop offset='1' stopColor='#6B11C5' />
        </linearGradient>
      </defs>
    </svg>
  );
};
